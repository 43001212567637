import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;



export const fetchStatisticsSalesBookTopPrime = async (data = {}) => {
    try {
      const defaultData = {};
    
      const requestData = { ...defaultData, ...data };


      const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/topprime`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  // Fonction pour récupérer les années fiscales du graphe Top Prime
export const fetchFiscalYearsTopPrimeGraph = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/topprime/annees_fiscales`, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      }
    });
    
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: [],
        message: "Erreur lors de la récupération des années fiscales."
      };
    }
    
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.error("Erreur lors de la récupération des années fiscales:", error);
    return { 
      status: 400, 
      data: [], 
      message: "Erreur lors de la communication vers l'api." 
    };
  }
};

// Fonction pour récupérer les exercices fiscaux disponibles
export const fetchFiscalYears = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/benchmark/exercices_fiscaux`, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      }
    });
    
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: [],
        message: "Erreur lors de la récupération des exercices fiscaux."
      };
    }
    
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.error("Erreur lors de la récupération des exercices fiscaux:", error);
    return { 
      status: 400, 
      data: [], 
      message: "Erreur lors de la communication vers l'API." 
    };
  }
};

// Fonction pour récupérer les périodes disponibles pour un exercice fiscal et une vue spécifiques
export const fetchPeriodes = async (fiscalYear, view) => {
  try {
      const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/benchmark/periodes/${fiscalYear}/${view}`, {
          method: "GET",
          withCredentials: true,
          headers: {
              "Content-Type": "application/json",
          }
      });

      if (!resApi || resApi.status !== 200) {
          return {
              status: 400,
              data: [],
              message: "Erreur lors de la récupération des périodes."
          };
      }

      const resData = await resApi.data;
      return resData;
  } catch (error) {
      console.error("Erreur dans fetchPeriodes:", error);
      return { 
          status: 400, 
          data: [], 
          message: "Erreur lors de la communication vers l'API." 
      };
  }
};

export const fetchStatisticsSalesBenchMark = async (data = {}) => {
  try {
    const defaultData = {
      typeView: "ic",
      view: "trimestre",
      fiscalYear: null,
      period: null,
    };
    const requestData = { ...defaultData, ...data };

    // Validation des paramètres
    const validViews = ["trimestre", "semestre", "quadrimestre", "annee"];
    const validPeriods = {
      trimestre: ["T1", "T2", "T3", "T4"],
      semestre: ["S1", "S2"],
      quadrimestre: ["Q1", "Q2", "Q3"],
      annee: ["Y1"],
    };
    if (!validViews.includes(requestData.view)) {
      return { status: 400, data: "Vue invalide." };
    }
    if (requestData.period && !validPeriods[requestData.view].includes(requestData.period)) {
      return { status: 400, data: `Période invalide pour la vue ${requestData.view}.` };
    }

    // Nettoyage des paramètres null
    const cleanedRequestData = Object.fromEntries(
      Object.entries(requestData).filter(([_, value]) => value !== null)
    );

    const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/benchmark`, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      params: cleanedRequestData,
    });

    if (resApi.status !== 200) {
      return {
        status: resApi.status,
        data: resApi.data.message || "Erreur lors de la communication vers l'api.",
      };
    }

    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.error("Erreur lors de la récupération des données de benchmark:", error);
    return {
      status: error.response?.status || 400,
      data: error.response?.data?.data || "Erreur lors de la communication vers l'api.",
    };
  }
};

  export const fetchStatisticsSalesBenchMarkDetails = async ({userId, data = {}}, signal) => {
    try {
      const defaultData = {};

      const requestData = { ...defaultData, ...data };



      const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/benchmark/details/${userId}`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
        signal
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      if (axios.isCancel(error)) {
        return { status: "canceled", data: "Requête annulée" };
      }
      return { status: 400, data: "Erreur lors de la communication vers l'API." };
    }
  };


  export const fetchStatisticsSalesBenchMarkManager = async (data = {}) => {
    try {
      const defaultData = {
        view: "trimestre",
        fiscalYear: null,
        period: null,
      };
    
      const requestData = { ...defaultData, ...data };

      // Validation des paramètres
      const validViews = ["trimestre", "semestre", "quadrimestre", "annee"];
      const validPeriods = {
        trimestre: ["T1", "T2", "T3", "T4"],
        semestre: ["S1", "S2"],
        quadrimestre: ["Q1", "Q2", "Q3"],
        annee: ["Y1"],
      };
      if (!validViews.includes(requestData.view)) {
        return { status: 400, data: "Vue invalide." };
      }
      if (requestData.period && !validPeriods[requestData.view].includes(requestData.period)) {
        return { status: 400, data: `Période invalide pour la vue ${requestData.view}.` };
      }

      // Nettoyage des paramètres null
      const cleanedRequestData = Object.fromEntries(
        Object.entries(requestData).filter(([_, value]) => value !== null)
      );

      const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/benchmark/manager`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: cleanedRequestData,
      });

      if (resApi.status !== 200) {
        return {
          status: resApi.status,
          data: resApi.data.message || "Erreur lors de la communication vers l'api.",
        };
      }

      const resData = await resApi.data;
      return resData;
  } catch (error) {
    console.error("Erreur lors de la récupération des données de benchmark:", error);
    return {
      status: error.response?.status || 400,
      data: error.response?.data?.data || "Erreur lors de la communication vers l'api.",
    };
  }
};



  export const fetchStatisticsSalesBenchMarkGlobal = async (data = {}) => {
    try {
      const defaultData = {
        typeView: "ic",
        view: "trimestre",
        fiscalYear: null,
        period: null,
      };
    
      const requestData = { ...defaultData, ...data };

      // Validation des paramètres
      const validViews = ["trimestre", "semestre", "quadrimestre", "annee"];
      const validPeriods = {
        trimestre: ["T1", "T2", "T3", "T4"],
        semestre: ["S1", "S2"],
        quadrimestre: ["Q1", "Q2", "Q3"],
        annee: ["Y1"],
      };
      if (!validViews.includes(requestData.view)) {
        return { status: 400, data: "Vue invalide." };
      }
      if (requestData.period && !validPeriods[requestData.view].includes(requestData.period)) {
        return { status: 400, data: `Période invalide pour la vue ${requestData.view}.` };
      }

      // Nettoyage des paramètres null
      const cleanedRequestData = Object.fromEntries(
        Object.entries(requestData).filter(([_, value]) => value !== null)
      );

      const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/benchmark/global`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: cleanedRequestData,
      });
  
      if (resApi.status !== 200) {
        return {
          status: resApi.status,
          data: resApi.data.message || "Erreur lors de la communication vers l'api.",
        };
      }
  
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.error("Erreur lors de la récupération des données de benchmark:", error);
      return {
        status: error.response?.status || 400,
        data: error.response?.data?.data || "Erreur lors de la communication vers l'api.",
      };
    }
  };

  export const fetchStatisticsSalesBenchMarkGlobalManager = async (data = {}) => {
    try {
      const defaultData = {
        typeView: "ic",
        view: "trimestre",
        fiscalYear: null,
        period: null,
      };
    
      const requestData = { ...defaultData, ...data };

      // Validation des paramètres
      const validViews = ["trimestre", "semestre", "quadrimestre", "annee"];
      const validPeriods = {
        trimestre: ["T1", "T2", "T3", "T4"],
        semestre: ["S1", "S2"],
        quadrimestre: ["Q1", "Q2", "Q3"],
        annee: ["Y1"],
      };
      if (!validViews.includes(requestData.view)) {
        return { status: 400, data: "Vue invalide." };
      }
      if (requestData.period && !validPeriods[requestData.view].includes(requestData.period)) {
        return { status: 400, data: `Période invalide pour la vue ${requestData.view}.` };
      }

      // Nettoyage des paramètres null
      const cleanedRequestData = Object.fromEntries(
        Object.entries(requestData).filter(([_, value]) => value !== null)
      );

      const resApi = await axios(`${apiBaseUrl}/api/dashboard/salesbook/benchmark/global/manager`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: cleanedRequestData,
      });
  
      if (resApi.status !== 200) {
        return {
          status: resApi.status,
          data: resApi.data.message || "Erreur lors de la communication vers l'api.",
        };
      }
  
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.error("Erreur lors de la récupération des données de benchmark:", error);
      return {
        status: error.response?.status || 400,
        data: error.response?.data?.data || "Erreur lors de la communication vers l'api.",
      };
    }
  };