import React, { useMemo, useState } from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../utils/hooks/useGlobalState';
import { useAuth } from '../../utils/hooks/useAuth';
import { authLogout } from '../../utils/atna';
import moment from 'moment-timezone';
import { upperFirst } from 'lodash';
import { BiArrowToLeft, BiLoaderAlt } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa6';
const Home = () => {

    const navigate = useNavigate()
    const { user, setUser, userPhoto, hasUserPhoto } = useAuth()
    const [logoutLoading, setLogoutLoading] = useState(false)
   
    async function handleLogout() {
        setLogoutLoading(() => true)
        const userRes = await authLogout();
        setUser({ isAuth: false });
        navigate("/")
        setLogoutLoading(() => false)
      }



      const nomComplet = useMemo(() =>{
        return `${user.personnel.prenom ? upperFirst(user.personnel.prenom.toLowerCase()) : '...'} ${user.personnel.nom ? upperFirst(user.personnel.nom.toLowerCase()) : '...'}`
      }, [user])

      const letters = useMemo(() => {
        if(user.personnel.nom) return `${user.personnel.prenom.charAt(0).toUpperCase()}${user.personnel.nom.charAt(0).toUpperCase()}`
        else return `${user.personnel.prenom.charAt(0).toUpperCase()}`
      }, [user])

    return (
        <div className='w-full h-full flex items-center justify-center relative'>

            <div className="absolute left-12 top-12">
                <img src={require('../../cdn/images/atna-icone-noir.png')} alt="aTna" className='h-8' />
            </div>
   

            <div className="flex flex-col gap-y-4 w-max px-8">
                <div className="flex gap-x-[20px] items-center">
                    <div className="size-[50px] rounded-full bg-black/[0.02] shadow-2xl overflow-hidden bg-gradient-to-br from-primary to-primary/30">
                    {
                        hasUserPhoto ? (
                            <img src={userPhoto} alt="" className='w-full h-full' />
                        )
                        :
                        (
                            <div className='w-full h-full flex items-center justify-center font-sans font-medium text-black'>
                                {letters}
                            </div>
                        )
                    }
                    </div>
                <div className='flex flex-col'>
                <h1 className='text-[16px] font-semibold font-sans text-black/40'>
                    <span className='text-black'>{nomComplet}</span>
                </h1>
                </div>
                </div>

                <div className="flex gap-x-8 gap-y-4 flex-wrap items-center max-smjustify-center relative z-30 mt-[20px]">

                    <Link to="/crm" className="group flex flex-col justify-center h-32 w-56 rounded-xl px-6 py-4 ring-1 ring-grey/5 hover:ring-grey hover:ring-2 cursor-pointer transition-all duration-75 relative">
                        <HiOutlineArrowRight className='absolute right-4 top-6 -translate-x-4 opacity-0 group-hover:-translate-x-0 group-hover:opacity-100 transition-all duration-200'/>
                        <h1 className='text-lg  text-grey font-sans font-bold'>C.R.M.</h1>
                        <h2 className='text-xs text-grey font-display font-normal'>Accéder au CRM pour gérer les tiers, l'activité...</h2>
                    </Link>

                 {   
                 user.estAdmin === 1 && <Link to="/admin" className="group flex flex-col justify-center h-32 w-56 rounded-xl px-6 py-4 ring-1 ring-grey/5 hover:ring-grey hover:ring-2 cursor-pointer transition-all duration-75 relative">
                        <HiOutlineArrowRight className='absolute right-4 top-6 -translate-x-4 opacity-0 group-hover:-translate-x-0 group-hover:opacity-100 transition-all duration-200'/>
                        <h1 className='text-lg  text-grey font-sans font-bold'>Configuration</h1>
                        <h2 className='text-xs text-grey font-display font-normal'>Accéder aux menus de configuration de l'application.</h2>
                    </Link>
                }

                </div>

                <div className='flex items-center gap-x-[10px]'>
                    <div className='h-[15px] w-[3px] rounded-full bg-black/[0.04]'></div>
                   <span className="text-black/30 text-[12px] font-semibold flex items-center gap-x-[5px]">Dernière connexion le 
                   <span className=''>{moment.utc(user.lastLoginDate).tz('Europe/Paris').format('DD/MM/YYYY à HH:mm')}</span>
                   </span>
                   </div>

                <div 
                onClick={() => handleLogout()}
                className="h-[30px] rounded-[12px] atna-border !bg-white text-black font-medium text-[11px] px-[20px] flex items-center cursor-pointer w-max mt-[10px]">
                    {
                        logoutLoading ? 
                        (
                            <BiLoaderAlt className='animate-spin'/>
                        )
                        :
                        "Se déconnecter"
                    }
                </div>

                
            </div>
            
      

        </div>
    );
}

export default Home;