// -- IMPORTATIONS DE BASE REACT ET ROUTER -- //
// Importation des hooks de React et des fonctionnalités de React Router pour la navigation et la gestion des URL
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// -- HOOKS PERSONNALISÉS -- //
// Gestion des alertes globales
import { useAlerts } from "../../../../../../../utils/hooks/useAlert";

// Utilitaires pour les interactions avec l'API et manipulations des opportunités
import {
  createUserAcces,
  editUserAcces,
  fetchTeams,
  fetchUserAcces,
} from "../../../../../../../utils/atna";

// Champs de formulaire réutilisables dans plusieurs parties de l'application
import InputSelect from "../../../../../../../components/inputs/inputSelect";
import InputBoolean from "../../../../../../../components/inputs/inputBoolean";
import Input from "../../../../../../../components/inputs/input";
import InputReadOnly from "../../../../../../../components/inputs/inputReadOnly";

// -- UTILITAIRES -- //
// Construction dynamique des classes CSS
import { cn } from "../../../../../../../utils/cn";

// Importation des icônes
import { BiLoaderAlt } from "react-icons/bi";

// -- IMPORTATIONS DE COMPOSANTS -- //
import DeleteMenuAcces from "../components/deleteAccesMenu";

// Constantes pour les classes réutilisées
const h1ClassName = `text-[11px] text-[rgba(55,53,47,0.5)] font-medium leading-[18px] tracking-wide`;
const fieldClassName = `flex flex-col min-w-[200px] w-full max-w-[250px]`;

const FicheAtnaUser = ({ userData, setUserData }) => {
  const [userAcces, setUserAcces] = useState({
    exist: false,
    loading: true,
    niveau: 0,
  });
  const [baseUserAcces, setBaseUserAcces] = useState({
    exist: false,
    loading: true,
    niveau: 0,
  });
  const { pageState } = useParams();
  const [teams, setTeams] = useState([]);
  const { addAlert } = useAlerts();
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [deleteMenuVisible, setDeleteMenuVisible] = useState(false);
  const isNew = !userAcces.exist;

  const isDataEdited = useMemo(() => {
    const baseData = JSON.stringify(
      baseUserAcces ?? {},
      (key, value) => value ?? ""
    );
    const editedData = JSON.stringify(
      userAcces ?? {},
      (key, value) => value ?? ""
    );
    return baseData !== editedData;
  }, [userAcces, baseUserAcces]);

  useEffect(() => {
    setIsEdited(isDataEdited);
  }, [isDataEdited]);

  const teamsOptions = useMemo(
    () =>
      teams.map((vl) => ({
        value: vl.id,
        label: vl.nom,
      })),
    [teams]
  );

  const fetchAccesData = async () => {
    const resApi = await fetchUserAcces({ id: pageState });
    if (resApi.status === 200) {
      setUserAcces(() => {
        return { exist: true, ...resApi.data };
      });
      setBaseUserAcces(() => {
        return { exist: true, ...resApi.data };
      });
    } else if (resApi.status === 300) {
      setUserAcces((prev) => {
        return { ...prev, loading: false, exist: false };
      });
      setBaseUserAcces((prev) => {
        return { ...prev, loading: false, exist: false };
      });
    } else {
      return addAlert(resApi.data);
    }
  };

  const fetchTeamsData = async () => {
    const resApi = await fetchTeams();
    if (resApi.status === 200) {
      setTeams(() => {
        return [...resApi.data];
      });
    } else {
      addAlert(resApi.data);
    }
  };

  useEffect(() => {
    fetchTeamsData();
    fetchAccesData();
  }, []);

  const handleEditData = useCallback(async () => {
    setIsSaving(true);
    const resEditData = await editUserAcces({
      id: pageState,
      data: { ...userAcces },
    });
    setIsSaving(false);
    if (resEditData.status !== 200) return addAlert(resEditData.data);
    fetchAccesData();
    setIsEdited(false);
  }, [userAcces]);

  const handleCreateData = useCallback(async () => {
    setIsSaving(true);
    const resEditData = await createUserAcces({
      id: pageState,
      data: { ...userAcces },
    });
    setIsSaving(false);
    if (resEditData.status !== 200) return addAlert(resEditData.data);
    fetchAccesData();
    setIsEdited(false);
  }, [userAcces]);

  async function handleResetData() {
    setUserAcces(() => {
      return { ...baseUserAcces };
    });
  }

  async function handleDeleteData() {
    setDeleteMenuVisible(() => true);
  }

  if (userAcces.loading)
    return (
      <div className="absolute w-full h-full z-[105] bg-white">
        <div className="w-full mt-[20px] flex justify-center items-center h-2/3">
          <BiLoaderAlt className="animate-spin text-[15px]" />
        </div>
      </div>
    );
  else
    return (
      <div className="flex flex-col">
        {deleteMenuVisible && (
          <DeleteMenuAcces
            menuVisible={deleteMenuVisible}
            setMenuVisible={setDeleteMenuVisible}
            fetchData={fetchAccesData}
          />
        )}

        <div className="flex gap-x-[10px] w-full flex-wrap">
          <div className={fieldClassName}>
            <h1 className={h1ClassName}>Id</h1>
            <InputReadOnly
              value={userData?.id || ""}
              dataTitle={null}
              placeholder={""}
              className={"w-full"}
              inpClassName={"!opacity-80"}
              type="text"
              maxLength={5}
              required={true}
              validation={(str) =>
                str.length > 5
                  ? {
                      valid: false,
                      data: `L'identifiant ne peut dépasser 5 caractères`,
                    }
                  : { valid: true, data: null }
              }
            />
          </div>
          <div className={fieldClassName}>
            <h1 className={h1ClassName}>Mot de passe</h1>
            <Input
              object={userAcces}
              setObject={setUserAcces}
              value={"password"}
              dataTitle={null}
              placeholder={""}
              className={"w-full"}
              type="text"
              required={false}
              validation={(str) =>
                str.length > 255
                  ? {
                      valid: false,
                      data: `Le prénom ne peut dépasser 255 caractères`,
                    }
                  : { valid: true, data: null }
              }
            />
          </div>
          <div className={fieldClassName}>
            <h1 className={h1ClassName}>Id Microsoft</h1>
            <Input
              object={userAcces}
              setObject={setUserAcces}
              value={"email"}
              dataTitle={null}
              placeholder={""}
              className={"w-full"}
              type="text"
              required={false}
              validation={(str) => {
                if (str && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str))
                  return { valid: false, data: `L'adresse mail est invalide` };
                return { valid: true, data: null };
              }}
            />
          </div>
        </div>

        <div className="flex gap-x-[10px] w-full flex-wrap">
          <div className={fieldClassName}>
            <h1 className={h1ClassName}>Equipe</h1>
            <InputSelect
              object={userAcces}
              inpClassName="atna-search-inp-listener"
              setObject={setUserAcces}
              value="equipe"
              placeholder={"Equipe"}
              options={teamsOptions}
            />
          </div>

          <div className={fieldClassName}>
            <h1 className={h1ClassName}>Niveau dans la hiérarchie</h1>
            <Input
              object={userAcces}
              setObject={setUserAcces}
              value={"niveau"}
              dataTitle={null}
              placeholder={""}
              className={"w-full"}
              type="number"
              required={true}
              validation={(str) => ({ valid: true, data: null })}
            />
          </div>
        </div>

        {/* -------------------------------------  Séparateur --------------------------------------------- */}
        <div className="h-[1px] w-2/3 max-w-[800px] rounded-full my-[30px] bg-black/5"></div>

        <table className="atna-search-table w-full max-w-[400px]">
          <tbody>
            <tr>
              <th className="align-middle">
                <h1 className="text-[12px] font-medium">Administrateur</h1>
              </th>
              <th className="align-middle">
                <InputBoolean
                  object={userAcces}
                  setObject={setUserAcces}
                  inpClassName={"text-[10px]"}
                  dataTitle={null}
                  value="estAdmin"
                />
              </th>
            </tr>
            <tr>
              <th className="align-middle">
                <h1 className="text-[12px] font-medium">
                  Peut modifier un tiers
                </h1>
              </th>
              <th className="align-middle">
                <InputBoolean
                  object={userAcces}
                  setObject={setUserAcces}
                  inpClassName={"text-[10px]"}
                  dataTitle={null}
                  value="peutModifierTiers"
                />
              </th>
            </tr>
            <tr>
              <th className="align-middle">
                <h1 className="text-[12px] font-medium">
                  Peut créer de l'activité
                </h1>
              </th>
              <th className="align-middle">
                <InputBoolean
                  object={userAcces}
                  setObject={setUserAcces}
                  inpClassName={"text-[10px]"}
                  dataTitle={null}
                  value="peutModifierActiviteTiers"
                />
              </th>
            </tr>
            <tr>
              <th className="align-middle">
                <h1 className="text-[12px] font-medium">
                  Peut modifier les contacts
                </h1>
              </th>
              <th className="align-middle">
                <InputBoolean
                  object={userAcces}
                  setObject={setUserAcces}
                  inpClassName={"text-[10px]"}
                  dataTitle={null}
                  value="peutModifierContactsTiers"
                />
              </th>
            </tr>

            <tr>
              <th className="align-middle">
                <h1 className="text-[12px] font-medium">Accès bloqué</h1>
              </th>
              <th className="align-middle">
                <InputBoolean
                  object={userAcces}
                  setObject={setUserAcces}
                  inpClassName={"text-[10px]"}
                  dataTitle={null}
                  value="blocked"
                />
              </th>
            </tr>
          </tbody>
        </table>

        <div className={cn("flex mt-[20px] gap-x-[20px]")}>
          <div
            className={cn(
              "h-[28px] rounded-[10px] atna-border !bg-black text-wh font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer",
              !isEdited && !isSaving && "opacity-75 pointer-events-none"
            )}
            onClick={() => {
              !isNew ? handleEditData() : handleCreateData();
            }}
          >
            {isSaving ? (
              <BiLoaderAlt className="text-[13px] animate-spin" />
            ) : isNew ? (
              "Créer"
            ) : (
              "Enregistrer"
            )}
          </div>

          {!isNew && (
            <div
              className={cn(
                "h-[28px] rounded-[10px] atna-border !bg-white text-black font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer hover:underline",
                isSaving && "opacity-75 pointer-events-none"
              )}
              onClick={() => {
                handleDeleteData();
              }}
            >
              Supprimer
            </div>
          )}

          {!isNew && (
            <div
              onClick={() => {
                handleResetData();
              }}
              className="h-[28px] rounded-[10px] font-medium text-[11px] px-[10px] flex items-center hover:underline cursor-pointer"
            >
              Annuler
            </div>
          )}
        </div>
      </div>
    );
};

export default FicheAtnaUser;
