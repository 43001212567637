import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Resiliations -> fiche tiers


// Récupérer la liste des résiliations d'un tiers
export const fetchTiersResiliations = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/resiliations/`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers ${id} resiliations ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des résiliations du tiers.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

// Récupérer une résiliation d'un tiers
export const fetchTiersResiliationDetails = async ({ id, idResil }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/resiliations/${idResil}/details`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers ${id} resiliation details ${idResil} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération de la résiliation.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

// Créer une résiliation pour un tiers 
export const createTiersResiliation = async ({ id, data }) => {
  try {
    console.log("Appel API createTiersResiliation:", { id, data });
    
    const response = await axios({
      url: `${apiBaseUrl}/api/tiers/${id}/resiliations/create`,
      method: "POST",
      withCredentials: true,
      data: data
    });
    
    console.log("Réponse brute API:", response);
    // Si on arrive ici, le code HTTP est forcément 2xx → succès
    return response;

  } catch (error) {
    console.error("Erreur dans createTiersResiliation:", error);

    // Si le serveur renvoie un statut HTTP 409 (doublon), Axios passera par ici
    if (error.response && error.response.status === 409) {
      // On peut renvoyer directement cette réponse pour la traiter côté front
      console.log("CODE OK => On renvoie l'objet 409 sans throw")
      return error.response;
    }

    // Sinon, on renvoie/trow pour d'autres cas (erreur 500, 404, etc.)
    throw error;
  }
};

// Modifier une résiliation d'un tiers
export const updateTiersResiliation = async ({ id, idResil, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/resiliations/${idResil}/edit`,
      {
        method: "PUT",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Editing resiliation ${idResil} for tiers ${id} ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la modification de la résil.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Motifs de résiliation -> Résiliation

// Récupérer les données de motifResil
export const fetchMotifResil = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/motifResil/`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching motifs resil ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des motifs de résiliation.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Créer un motif de résiliation
export const createMotifResil = async (data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${apiBaseUrl}/api/motifResil/create`,
      withCredentials: true,
      data: data // Envoyer directement les données
    });

    return {
      status: 200,
      data: response.data
    };
  } catch (error) {
    console.error('[API ERROR] : Creating motif resil', error);
    console.error('Detailed error:', error.response?.data);
    
    // Retourner le message d'erreur du serveur s'il existe
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.data || "Erreur lors de la création du motif de résiliation."
      };
    }
    return {
      status: 500,
      data: "Erreur de communication avec le serveur."
    };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Lignes de résiliation -> Résiliation

// Récupérer les lignes de résiliations d'un tiers et d'une résiliation
export const fetchTiersResiliationsLignes = async ({ id, idResil }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/resiliations/${idResil}/lignes/`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching lines for tiers ${id} resiliations ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des lignes de cette résiliation pour ce tiers.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Créer une ligne de résiliation pour un tiers 
export const createTiersResiliationLignes = async ({ id, idResil, data }) => {
try {  
  const response = await axios({
    url: `${apiBaseUrl}/api/tiers/${id}/resiliations/${idResil}/lignes/create`,
    method: "POST",
    withCredentials: true,
    data: data
  });
  
  return response.data;
} catch (error) {
  console.error('[API ERROR] : Creating lignes résiliations', error);
  console.error('Detailed error:', error.response?.data); // Ajout pour le débogage
  
  // Retourner le message d'erreur du serveur s'il existe
  if (error.response) {
    return {
      status: error.response.status,
      data: error.response.data.data || "Erreur lors de la création de la ligne de résil."
    };
  }
  return {
    status: 500,
    data: "Erreur de communication avec le serveur."
  };
}
};

// Mettre à jour une ligne de résiliation
export const updateTiersResiliationLigne = async ({ id, idResil, idLigneResil, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/resiliations/${idResil}/lignes/${idLigneResil}/update`,
      {
      method: "PUT",
      withCredentials: true,
      data,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Editing ligne resil ${idLigneResil} for resil ${idResil} ${error}`);
      if (error.response && error.response.status === 404) {
        return { status: 404, data: "Ligne de résiliation non trouvée." };
      }
      return { status: 400, data: "Erreur lors de la modification de la ligne de résiliation." };
    });

    const resData = resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Supprimer une ligne de résiliation
export const deleteTiersResiliationLigne = async ({ id, idResil, idLigne }) => {
  try {
    const response = await axios({
      url: `${apiBaseUrl}/api/tiers/${id}/resiliations/${idResil}/lignes/${idLigne}/delete`,
      method: "DELETE",
      withCredentials: true
    });
    
    return response.data;
  } catch (error) {
    console.error('[API ERROR] : Deleting ligne résiliation', error);
    console.error('Detailed error:', error.response?.data);
    
    // Retourner le message d'erreur du serveur s'il existe
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.data || "Erreur lors de la suppression de la ligne de résiliation."
      };
    }
    return {
      status: 500,
      data: "Erreur de communication avec le serveur."
    };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Contrats -> Résiliation

// Récupérer la liste des contrats d'un tiers et d'une résiliation en fonction d'un code client
export const fetchTiersContratsClients = async ({ id, idResil }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/resiliations/${idResil}/contrats`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching contrats for tiers ${id} resiliation ${idResil} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des contrats du tiers pour ce code clientc.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};