// -- IMPORTS -- //
// Importation des bibliothèques nécessaires
import React, { useEffect, useState, useCallback } from 'react';

// Importation de la fonction utilitaire pour gérer les classes dynamiques
import { cn } from '../../../utils/cn';

// Importation des icônes depuis React Icons
import { 
  IoBookmarkOutline, 
  IoClose, 
  IoGlobeOutline, 
  IoList, 
  IoPerson, 
  IoPersonOutline, 
  IoSearch, 
  IoShareSocial 
} from "react-icons/io5";
import { RiMapPin2Line } from "react-icons/ri";
import { GrCloud, GrCloudDownload, GrGroup } from "react-icons/gr";

// Importation d'une icône supplémentaire spécifique au pack "io"
import { IoMdPin } from "react-icons/io";

// Importation d'une icône d'information depuis le pack "fi"
import { FiInfo } from "react-icons/fi";

// Importation d'une icône d'affichage en grille depuis le pack "hi"
import { HiOutlineViewGrid } from "react-icons/hi";

// Importation des outils de navigation et de gestion des routes via React Router
import { Link, useNavigate, useParams } from 'react-router-dom';

// -- HOOKS PERSONNALISÉS -- //
// Importation du hook personnalisé pour gérer les onglets
import { useTabs } from '../../../utils/hooks/useTabs';

// Importation du hook personnalisé pour gérer l'authentification
import { useAuth } from '../../../utils/hooks/useAuth';

// Importation des icônes utilisateur et groupe depuis Font Awesome 6
import { 
  FaRegUser, 
  FaUserGroup 
} from "react-icons/fa6";

// -- UTILITAIRES -- //
// Importation de la fonction Lodash pour mettre en majuscule la première lettre d'une chaîne
import { upperFirst } from 'lodash';


// Composant Sidebar, optimisé avec React.memo pour éviter des rendus inutiles
const Sidebar = React.memo(({
  windowWidthSize, // Largeur actuelle de la fenêtre
  isResizing, // État indiquant si l'utilisateur est en train de redimensionner la barre latérale
  setCursorTop, // Fonction pour mettre à jour la position verticale du curseur
  cursorTop, // Position actuelle du curseur en haut
  sidebarWidth, // Largeur actuelle de la barre latérale
  setSidebarWidth, // Fonction pour mettre à jour la largeur de la barre latérale
  navLocked, // État indiquant si la navigation est verrouillée
  setNavLocked, // Fonction pour verrouiller/déverrouiller la navigation
  setIsResizing, // Fonction pour activer/désactiver l'état de redimensionnement
  pageType // Type de page affichée
}) => {
  // Extraction des paramètres de l'URL
  const { idTiers, pageView } = useParams();

  // État pour la visibilité des menus déroulants
  const [dropVisible, setDropVisible] = useState(false);
  const [userDropVisible, setUserDropVisible] = useState(false);

  // Hooks personnalisés pour la gestion des onglets et de l'authentification
  const { getViews, deleteView } = useTabs();
  const views = getViews();
  const { user, userPhoto, handleLogout } = useAuth();

  // Utilisation du hook pour la navigation
  const navigate = useNavigate();

  // Gestion du clic pour démarrer le redimensionnement de la barre latérale
  const handleMouseDown = useCallback((e) => {
    setIsResizing(true);
  }, [setIsResizing]);

  // Effet pour gérer les événements globaux de la souris et des clics
  useEffect(() => {
    // Gestion du déplacement de la souris pour redimensionner la barre latérale
    const handleMouseMove = (e) => {
      const newHeight = e.clientY - 10; // Calcul de la nouvelle hauteur du curseur
      setCursorTop(newHeight);

      if (!isResizing) return;

      // Ajout de classes pour indiquer l'état de redimensionnement
      document.querySelector('.atna-sidebar')?.classList.add('atna-sidebar-resize-hover');
      document.querySelector('.sidebar-resize-message')?.classList.remove('showed');

      // Calcul de la nouvelle largeur de la barre latérale
      const newWidth = e.clientX;
      if (newWidth >= 248 && newWidth <= 400) {
        setSidebarWidth(newWidth);
      }
    };

    // Gestion du relâchement de la souris pour terminer le redimensionnement
    const handleMouseUp = () => {
      if (isResizing) {
        if (!document.querySelector('.hover-bar-detection:hover')) {
          document.querySelector('.atna-sidebar')?.classList.remove('atna-sidebar-resize-hover');
        }
        document.body.style.cursor = 'default';
      }
      setIsResizing(false);
    };

    // Gestion des clics extérieurs pour fermer les menus déroulants
    const handleClickIsOutDrop = (e) => {
      if (!e.target?.closest('.atna-drop-menu') && !e.target?.closest('.atna-drop-inner')) {
        setDropVisible(() => false);
      }
      if (!e.target?.closest('.atna-user-menu')) {
        setUserDropVisible(() => false);
      }
      e.stopPropagation();
    };

    // Ajout des gestionnaires d'événements globaux
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('click', handleClickIsOutDrop);

    // Nettoyage des gestionnaires d'événements lors du démontage
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('click', handleClickIsOutDrop);
    };
  }, [isResizing, setCursorTop, setIsResizing, setSidebarWidth]);

  // Fonction pour naviguer vers la recherche et gérer l'état de la navigation sur des écrans étroits
  const handleSearchNavigation = useCallback(() => {
    navigate("/crm/search?q="); // Navigation vers la page de recherche
    if (windowWidthSize < 800) setNavLocked(() => true); // Verrouillage de la navigation sur les petits écrans
    setTimeout(() => {
      // Placeholder pour des opérations lourdes post-navigation
    }, 0);
  }, [navigate, windowWidthSize, setNavLocked]);


  return (
    <nav className={cn("atna-sidebar select-none", navLocked && "atna-sidebar-locked min-w-0 w-0")} style={{ width: sidebarWidth, transform: `translateX(-${navLocked ? sidebarWidth + 30 : 0}px)` }}>
      <div className="flex flex-col h-full">
        <div className="block shrink-0 grow-0 cursor-pointer pointer-events-auto">
          <div className="flex items-center justify-between px-[16px] py-[10px] mb-[10px]">
          <Link to="/" role='button' tabIndex={0} data-title="Accéder au menu" className=" h-[25px] px-[5px] shrink-0 grow-0 hover:bg-[rgba(0,0,0,0.04)] rounded-[4px] flex items-center justify-center gap-x-[10px]">
              <img src={require('../../../cdn/images/atna-icone-noir.png')} alt="aTna" className='w-[20px] h-[20px]' />
              <h1 className='text-[13px] font-black text-black/90'>aTna Cloud</h1>
            </Link>
            <div
              onClick={() => {
                setNavLocked((prev) => !prev);
              }}
              role="button" tabIndex={0}
              data-title='Fermer' className="flex hover:bg-[rgba(0,0,0,0.04)] h-[25px] w-[25px] rounded-[4px] items-center justify-center">
              <div className="w-[10px] h-[10px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.45)]">
                <svg role="graphics-symbol" viewBox="0 0 16 16" width={"100%"} height={'100%'} className="doubleChevronLeft"><path d="M7.07031 13.8887C7.2207 14.0391 7.40527 14.1211 7.62402 14.1211C8.06836 14.1211 8.41699 13.7725 8.41699 13.3281C8.41699 13.1094 8.32812 12.9043 8.17773 12.7539L3.37207 8.05762L8.17773 3.375C8.32812 3.21777 8.41699 3.0127 8.41699 2.80078C8.41699 2.35645 8.06836 2.00781 7.62402 2.00781C7.40527 2.00781 7.2207 2.08984 7.07031 2.24023L1.73828 7.44922C1.56055 7.62012 1.46484 7.8252 1.46484 8.06445C1.46484 8.29688 1.55371 8.49512 1.73828 8.67969L7.07031 13.8887ZM13.1748 13.8887C13.3252 14.0391 13.5098 14.1211 13.7354 14.1211C14.1797 14.1211 14.5283 13.7725 14.5283 13.3281C14.5283 13.1094 14.4395 12.9043 14.2891 12.7539L9.4834 8.05762L14.2891 3.375C14.4395 3.21777 14.5283 3.0127 14.5283 2.80078C14.5283 2.35645 14.1797 2.00781 13.7354 2.00781C13.5098 2.00781 13.3252 2.08984 13.1748 2.24023L7.84961 7.44922C7.66504 7.62012 7.57617 7.8252 7.56934 8.06445C7.56934 8.29688 7.66504 8.49512 7.84961 8.67969L13.1748 13.8887Z"></path></svg>
              </div>
            </div>
          </div>
        </div>
        <div className="block shrink-0 grow-0 cursor-pointer pointer-events-auto ">
          
          <div className="flex flex-col grow-0 shrink-0 gap-y-[2px] mx-[8px] mt-[15px]">
            <Link
              to="/admin"
              role='button' tabIndex={0} onClick={() => {
                if (windowWidthSize < 800) setNavLocked(() => true);
              }} data-title="Revenir au menu" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "menu" && "bg-[rgba(0,0,0,0.04)]")}>
              <div className='text-[13px] text-[rgb(95,94,91)]'>
                <HiOutlineViewGrid />
              </div>
              <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                Menu
              </div>
            </Link>
            <Link
              to="/admin/utilisateurs"
              role='button' tabIndex={0} onClick={() => {
                if (windowWidthSize < 800) setNavLocked(() => true);
              }} data-title="Configurer les accès" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageView === "utilisateurs" && "bg-[rgba(0,0,0,0.04)]")}>
              <div className='text-[13px] text-[rgb(95,94,91)]'>
                <FaRegUser />
              </div>
              <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                Utilisateurs
              </div>
            </Link>

            <Link
              to="/admin/equipes"
              role='button' tabIndex={0} onClick={() => {
                if (windowWidthSize < 800) setNavLocked(() => true);
              }} data-title="Configurer les accès" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageView === "equipes" && "bg-[rgba(0,0,0,0.04)]")}>
              <div className='text-[13px] text-[rgb(95,94,91)]'>
                <GrGroup />
              </div>
              <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                Equipes
              </div>
            </Link>

            <Link
              to="/admin/organigramme"
              role='button' tabIndex={0} onClick={() => {
                if (windowWidthSize < 800) setNavLocked(() => true);
              }} data-title="Voir l'organigramme" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageView === "organigramme" && "bg-[rgba(0,0,0,0.04)]")}>
              <div className='text-[13px] text-[rgb(95,94,91)]'>
                <GrGroup />
              </div>
              <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                 Organigramme
              </div>
            </Link>

            <Link
              to="/admin/imports"
              role='button' tabIndex={0} onClick={() => {
                if (windowWidthSize < 800) setNavLocked(() => true);
              }} data-title="Imports ODBC" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageView === "imports" && "bg-[rgba(0,0,0,0.04)]")}>
              <div className='text-[13px] text-[rgb(95,94,91)]'>
                <GrCloudDownload />
              </div>
              <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                 Imports
              </div>
            </Link>
           
          </div>
        </div>

        <div className="flex flex-1 items-end py-[10px] max-sm:py-[20px] px-[10px] pointer-events-auto">
          <div className="relative z-50 w-full group atna-user-menu">
            <div
              onClick={() => {
                setUserDropVisible((prev) => !prev);
              }}
              className="h-[50px] w-full rounded-[6px] max-sm:rounded-[20px] hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)]  px-[10px] py-[0px] max-sm:h-[60px] flex items-center gap-x-[15px] cursor-pointer">
              <div className="size-[30px] rounded-[40%] bg-black/[0.04] overflow-hidden">
                <img src={userPhoto} alt="" className='w-full h-full' />
              </div>
              <h1 className='text-[13px] font-medium tracking-wide max-w-[200px] text-nowrap whitespace-nowrap overflow-hidden text-ellipsis text-[rgb(95,94,91)]'>{upperFirst((user.personnel||user)?.prenom.toLowerCase() || "")} {upperFirst((user.personnel||user)?.nom.toLowerCase() || "")}</h1>
            </div>

            <div className={cn("absolute z-40 w-full max-[200px] bg-white atna-drop-menu box-border bottom-[calc(100%_+_10px)] px-[5px] py-[5px] opacity-0 pointer-events-none transition-all duration-75",
              userDropVisible && "opacity-100 pointer-events-auto"
            )}>
              <div className="flex flex-col gap-y-[2px] ">
                <div key={"logOutButton"} role="button" tabIndex={0}
                  onClick={handleLogout}
                  className={cn("group h-[25px] max-h-[25px] grow-0 shrink-0 px-[10px] rounded-[6px] hover:bg-black/[0.02] active:bg-black/[0.04] flex w-full items-center justify-between")}>
                  <div key={"btn_logOut"} className="flex items-center gap-x-[8px] grow-0 shrink-0 px-[5px]">
                    <h1 className='text-[12px] font-medium text-[rgba(55,53,47,0.9)]'>Se déconnecter</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="atna-tooltip sidebar-resize-message max-lg:hidden" style={{ top: cursorTop + "px" }}>
        Glisser <span>pour redimensionner</span> <br></br>
      </div>
      <div className='absolute top-0 right-0 grow-0 w-0 h-full z-1 pointer-events-auto'>
        <div className='h-full w-[12px] cursor-col-resize -ml-[6px] hover-bar-detection'
          onMouseEnter={(e) => {
            document.querySelector('.atna-sidebar')?.classList.add('atna-sidebar-resize-hover');
            document.querySelector('.sidebar-resize-message')?.classList.add('showed');
          }}
          onMouseLeave={(e) => {
            if (!isResizing) document.querySelector('.atna-sidebar')?.classList.remove('atna-sidebar-resize-hover');
            document.querySelector('.sidebar-resize-message')?.classList.remove('showed');
          }}
          onMouseDown={handleMouseDown}
        >
        </div>
      </div>
    </nav>
  );
});

export default Sidebar;