import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';
import { useAlerts } from '../../../../../utils/hooks/useAlert';
import { deleteSessionAndBlockUser, deleteTiers, deleteUser } from '../../../../../utils/atna';
import { deleteSession } from '../../../../../utils/atna';
import { cn } from '../../../../../utils/cn';

const BlockUserMenu = ({menuVisible, setMenuVisible, session, fetchSessions}) => {
    

    const {pageState} = useParams()
    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = useState(false)
    const {addAlert} = useAlerts()

    const handleDelete = async() => {
        const resApi = await deleteSessionAndBlockUser({id: session.session_id})

        console.log(resApi)

        if(resApi.status !== 200) {
            setMenuVisible(() => false)
            addAlert('Erreur lors de la suppression de la session et du blocage, veuillez réessayer.')
        } else {
            fetchSessions()
            setMenuVisible(() => false)
            addAlert('L\'utilisateur n\'a plus accès à la base.', "success")
        }
     }

     useEffect(() => {
        if(!menuVisible) setIsDeleting(() => false)
     }, [menuVisible])

    return (
        <div className={cn("absolute z-50 flex w-full h-[80vh] opacity-0 pointer-events-none transition-none duration-50", menuVisible && "opacity-100 pointer-events-auto")}>
        <div 
        onClick={(e) => {
            if(menuVisible) {
            //setMenuVisible(() => false)
            e.stopPropagation()
            e.preventDefault()
            }
           }}
        className={cn("fixed w-full h-full flex top-[40px] left-0 backdrop-blur-[4px] ease-in-out transition-opacity duration-75", menuVisible && "opacity-100 pointer-events-auto")}></div>
       
        <div className={cn("absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 atna-popup text-[12px] p-[5px]  max-w-[350px] max-lg:min-w-0 w-[90%] flex flex-col items-center")}>
            <div className="flex h-[20px] justify-end items-center px-[10px] w-full">
                <div className='h-[20px] w-[20px] flex items-center justify-center cursor-pointer' onClick={() => {
                            setMenuVisible(() => false)
                        }}>
                <div className="w-[6px] h-[6px] rounded-full bg-[rgb(191,188,182)] cursor-pointer"></div>
                </div>
            </div>


            <div className={cn("flex flex-col flex-1 w-full px-[15px] pb-[10px] pt-[10px] ")}>

                <h1 className={cn('text-[13px] font-semibold transition-opacity duration-75', isDeleting && "opacity-60 pointer-events-none")}>Êtes-vous sûr de vouloir bloquer l'accès {(session?.data.user?.personnel?.nom || session?.data.user?.personnel?.prenom) && `à ${session?.data.user?.personnel?.nom || ''} ${session?.data.user?.personnel?.prenom || ''}`} ?</h1>
                <div className="flex items-center  gap-x-[10px] justify-end mt-[20px]">
               <div className={cn("flex")}>
                        <div className={cn("h-[26px] rounded-[10px] transition-opacity duration-75 atna-border hover:underline font-medium text-[10px] px-[13px] flex items-center cursor-pointer", isDeleting && "opacity-60 pointer-events-none")} onClick={() => {
                          setMenuVisible(() => false)
                        }}>
                           Annuler
                        </div>
                    </div>
                    <div className={cn("flex gap-x-[0px]")}>
                        <div className={cn("h-[26px] rounded-[10px] atna-border !bg-black text-wh2 font-medium text-[10px] px-[13px] flex items-center cursor-pointer")} onClick={() => {
                            // Supprimer tiers
                         if(isDeleting) return;
                         setIsDeleting(true)
                         handleDelete()
                        }}>
                           {
                            isDeleting ?
                            <BiLoaderAlt className='animate-spin'/>
                           :
                           "Bloquer"
                           }
                        </div>
                    </div>
                </div>
            </div>
         </div>
         </div>
    );
}

export default BlockUserMenu;
