import axios from "axios"
import config from "./config"
const apiBaseUrl = config.dev ? config.devApi : config.api 

// Affichage d'un tiers
export const fetchTiers = async({id}) => {
    try {
        const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}`, {
            method: "GET",
            withCredentials: true,
        }).catch((error) => {
            console.error(`[API ERROR] : Fetching tiers ${id} ${error}`);
            return null;
        })
     //   console.log(resApi)
        if (!resApi || resApi.status !== 200) {
          console.error(`[API ERROR] : Fetching tiers ${id} ${resApi.data}`);
          return null
        }
        const data = await resApi.data
        if(data.status === 400) return null
        if(data.status === 404) return null
        if(data.status !== 200) return null
        return data.data
      } catch (error) {
        console.error(`[API ERROR] : Fetching tiers ${id} ${error}`);
        return null;
      }
}

export const editTiers = async({id, data = {}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/edit`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiers = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/delete`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Editing tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const mergeTiers = async({id,importerId}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/merge/${importerId}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Editing tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la fusion des tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const createTiers = async({data = {}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating tiers ${error}`);
        return {status: 400, data: "Erreur lors de la création du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const searchTiers = async({query, secteur, cellule, codeNAF, cp, id_ic, idTiers, limit=50, page=1, telephone, email }) => {
  try {
      const resApi = await axios(`${apiBaseUrl}/api/tiers/search`, {
          method: "POST",
          withCredentials: true,
          data: {
            query,
            secteur, 
            cellule, 
            cp,
            codeNAF,
            id_ic,
            idTiers,
            limit,
            page,
            telephone,
            email
          }
      }).catch((error) => {
          console.error(`[API ERROR] : Searching tiers ${query} ${error}`);
          return null;
      })
      if (!resApi || resApi.status !== 200) {
        console.error(`[API ERROR] : Fetching tiers ${query} ${resApi.data}`);
        return null
      }
      const data = await resApi.data
      if(data.status === 400) return null
      if(data.status === 404) return null
      if(data.status !== 200) return null
      return data.data
    } catch (error) {
      console.error(`[API ERROR] : Fetching tiers ${query} ${error}`);
      return null;
    }
}

// Activites
export const fetchTiersActivites = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/activites/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} activities ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des activités du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersActivitesLength = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/activites/${id}/length`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} activities length ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des activités du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const createTiersActivitie = async({id, data = {}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/activites/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating activites for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la création de l'activité."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


// Contacts
export const fetchTiersContacts = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} contacts ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des contacts du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const createTiersContact = async({id, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating contacts for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la création du contact."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiersContact = async({id, idContact}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}/delete/${idContact}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du contact."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const editTiersContact = async({id, idContact, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}/edit/${idContact}`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing contact for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du contact."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}




// Contrats concurrents
// Activites
export const fetchTiersContratsConcurrents = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} activities ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des contrats concurrents du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const fetchTiersContratConcurrent = async({id, idContrat}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/infos/${idContrat}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} contract ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du contrat concurrent."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const createTiersContratConcurrent = async({id, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la création du contrat."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const editTiersContratConcurrent = async({id, idContrat, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/edit/${idContrat}`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du contrat."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiersContratConcurrent = async({id, idContrat}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/delete/${idContrat}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du contrat."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const fetchTiersContratConcurrentMateriel = async({id, idContrat}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/materiel/${idContrat}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} concurrent materials ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du matériel concurrent du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

// Mat

// export const fetchTiersContratConcurrentMaterielInfos = async({id,idContrat,idMateriel}) => {
//   try {
//     const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/${idMateriel}`, {
//         method: "POST",
//         withCredentials: true,
//     }).catch((error) => {
//         console.error(`[API ERROR] : Fetching materiel conc for contrat ${idContrat} ${error}`);
//         return {status: 400, data: "Erreur lors de la récupération du matériel."};
//     })
//     if (!resApi || resApi.status !== 200) {
//       return {status: 400, data: "Erreur lors de la communication vers l'api."};
//     }
//     const resData = await resApi.data
//     return resData
//   } catch (error) {
//     return {status: 400, data: "Erreur lors de la communication vers l'api."};
//   }
// }

export const createTiersContratConcurrentMateriel = async({id,idContrat,data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating materiel conc for contrat ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la création du matériel."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const editTiersContratConcurrentMateriel = async({id,idContrat,idMateriel,data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/edit/${idMateriel}`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing materiel conc for contrat ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du matériel."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const deleteTiersContratConcurrentMateriel = async({id,idContrat,idMateriel}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/delete/${idMateriel}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting materiel conc for contrat ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du matériel."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

// Parc client
export const fetchTiersParcClientRepro = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/parc-client/${id}/repro`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} parc client} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du parc client ( repro )."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersParcClientData = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/parc-client/${id}/data`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} parc client} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du parc client ( data )."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersParcClientPhone = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/parc-client/${id}/phone`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} parc client} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du parc client ( phone )."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersParcClientIdent = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/parc-client/${id}/ident`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} parc client} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du parc client ( ident )."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}






// Auth

export const loginUser = async({id, psw}) => {
  try {
    const res = await axios(`${apiBaseUrl}/auth/login`, {
        method: "POST",
        withCredentials: true,
        data: {id, psw}
    })
    return res.data
        
    } catch(err) {
        return {
            statuts: 400,
            err: err
        }
    }
}



export const getUserPhoto = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/auth/user/photo`, {
        method: "GET",
        withCredentials: true,
    })
    return res.data
        
    } catch(err) {
        return {
            status: 400,
            err: err
        }
    }
}



export const fetchCurrentUser = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/auth/user`, { method: "GET", withCredentials: true })
    return res.data
        
    } catch(err) {
        return {
            status: 400,
            err: err
        }
    }
}


export const fetchUserTeams = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/teams/me`, { method: "GET", withCredentials: true })
    return res.data
        
    } catch(err) {
        return {
            status: 400,
            err: err
        }
    }
}

export const fetchEstManager = async() => {
  try {
    const resApi = await axios(`${apiBaseUrl}/auth/user/est-manager`, {
        method: "GET",
        withCredentials: true,
    })
    if(resApi || resApi.status === 200) {
      return resApi.data.data || false
    }
    return false;
  } catch (error) {
    return false
  }
}


export const fetchUserEvents = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/auth/user/events`, {
        method: "GET",
        withCredentials: true,
    })
    return res.data
        
    } catch(err) {
        return {
            status: 400,
            err: err
        }
    }
}


export const fetchUserEventsScheduled = async({startTime, endTime}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/auth/user/events/schedule`, {
        method: "POST",
        withCredentials: true,
        data: {
          startDateTime: startTime, 
          endDateTime: endTime
        }
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching event for ${startTime} ${endTime} ${error}`);
      return {status: 400, data: "Erreur lors de la vérification de la disponibilité."};
  })
  if (!resApi || resApi.status !== 200) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
  const resData = await resApi.data
  return resData
} catch (error) {
  return {status: 400, data: "Erreur lors de la communication vers l'api."};
}
}

export const createUserEvent = async ({ subject, startDateTime, endDateTime, location = null, attendees = [], bodyContent = null }) => {
  // Vérification des paramètres obligatoires
  if (!subject || !startDateTime || !endDateTime) {
    return { status: 400, data: "Les paramètres 'subject', 'startDateTime' et 'endDateTime' sont requis." };
  }

  try {
    const resApi = await axios(`${apiBaseUrl}/auth/user/events/create`, {
      method: "POST",
      withCredentials: true,
      data: {
        subject,
        startDateTime,
        endDateTime,
        location,
        attendees,
        bodyContent
      }
    }).catch((error) => {
      console.error(`[API ERROR] : Création d'événement pour ${subject} ${startDateTime} - ${endDateTime} : ${error}`);
      return { status: 400, data: "Erreur lors de la création de l'événement." };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API pour la création d'événement." };
    }

    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.error(`[API ERROR] : ${error}`);
    return { status: 500, data: "Erreur interne lors de la communication avec l'API." };
  }
};

export const deleteUserEvent = async (eventId) => {
  if (!eventId) {
    console.error("[API ERROR] : L'identifiant de l'événement est requis.");
    return { status: 400, data: "L'identifiant de l'événement est requis." };
  }

  try {
    const resApi = await axios(`${apiBaseUrl}/auth/user/events/delete/${eventId}`, {
      method: "POST",
      withCredentials: true
    }).catch((error) => {
      console.error(`[API ERROR] : Suppression de l'événement ${eventId} : ${error}`);
      return { status: 400, data: "Erreur lors de la suppression de l'événement." };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API pour la suppression de l'événement." };
    }

    return { status: 200, message: "Événement supprimé avec succès" };
  } catch (error) {
    console.error(`[API ERROR] : ${error}`);
    return { status: 500, data: "Erreur interne lors de la communication avec l'API." };
  }
};

export const editUserEvent = async ({ eventId, subject, startDateTime, endDateTime, location = null, attendees = [], bodyContent = null }) => {
  if (!eventId || !subject || !startDateTime || !endDateTime) {
    return { status: 400, data: "Les paramètres 'eventId', 'subject', 'startDateTime', et 'endDateTime' sont requis." };
  }

  try {
    const resApi = await axios(`${apiBaseUrl}/auth/user/events/update/${eventId}`, {
      method: "POST",
      withCredentials: true,
      data: {
        subject,
        startDateTime,
        endDateTime,
        location,
        attendees,
        bodyContent
      }
    }).catch((error) => {
      console.error(`[API ERROR] : Mise à jour de l'événement ${eventId} : ${error}`);
      return { status: 400, data: "Erreur lors de la mise à jour de l'événement." };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API pour la mise à jour de l'événement." };
    }

    return { status: 200, message: "Événement mis à jour avec succès", data: resApi.data };
  } catch (error) {
    console.error(`[API ERROR] : ${error}`);
    return { status: 500, data: "Erreur interne lors de la communication avec l'API." };
  }
};



export const getDataCodesNAF = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/codeNAF`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}



export const getDataCommunes = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/communes`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}
export const getDataSecteurs = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/secteurs`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}

export const getDataAttributions = async({secteur} = {}) => {
  try {
    const res = await axios(secteur ? `${apiBaseUrl}/api/data/attributions/${secteur}` : `${apiBaseUrl}/api/data/attributions`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}

export const getPersonnel = async({id} = {}) => {
  try {
    const res = await axios(id ? `${apiBaseUrl}/api/data/personnel/${id}` : `${apiBaseUrl}/api/data/personnel`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}

export const getSiretData = async({siret=""}) => {
  if(!siret) return;
  try {
    const res = await axios(`${apiBaseUrl}/api/data/siret/${siret}`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}

export const siretExists = async({siret="", currentIdTiers}) => {
  if(!siret) return;
  try {
    const res = await axios(`${apiBaseUrl}/api/tiers/siret/${siret}/exist?currentIdTiers=${currentIdTiers}`, { method: "GET", withCredentials: true })
    return res.data
    } catch(err) {
        return null
    }
}

export const getActiviteActions = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/activite/actions`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}


export const getActivitePriorites = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/activite/priorites`, { method: "GET", withCredentials: true })
    if(res.data.status === 200) return res.data.data
    else return []
    } catch(err) {
        return null
    }
}


export const fetchAdresse = async({query}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/data/adresse?q=${query}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching adresse for ${query} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des adresses."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersAround  = async({id, rayon = 5, infoParc = false} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/around`, {
        method: "GET",
        withCredentials: true,
        params: {
          rayon,
          infoParc
        },
        headers: {
          "Content-Type": "application/json"
        },
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} around ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des tiers alentours"};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const parseAdresse = (adresse = "") => {
  // Utilisation d'une expression régulière pour extraire l'adresse, le code postal et la ville
  const regex = /(.+)\s+(\d{5})\s+(.+)/;
  const match = adresse.match(regex);

  if (match) {
      const adresseDebut = match[1].trim(); // L'adresse avant le code postal
      const codePostal = match[2];
      const ville = match[3].trim();
      return {
          adresseDebut: adresseDebut,
          codePostal: codePostal,
          ville: ville
      };
  } else {
      return null;
  }
}


export const createSecteurAdresse = async({ville = ""}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/data/communes/create`, {
        method: "POST",
        withCredentials: true,
        headers: {'Content-Type': 'application/json'},
        data: {
          ville: ville
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Creating secteur for ${ville} ${error}`);
        return {status: 400, data: "Erreur lors de la création du secteur"};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}




export const authLogout  = async() => {
  try {
    const resApi = await axios(`${apiBaseUrl}/auth/logout`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Logout ${error}`);
        return null
    })
    return null
  } catch (error) {
    return null
  }
}



// Admin

export const fetchUsers = async({query = ""}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/users?q=${query}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching users`);
        return {status: 400, data: "Erreur lors de la récupération des utilisateurs."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchUser = async({id = ""}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching user with id ${id}`);
        return {status: 400, data: "Erreur lors de la récupération de l'utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const editUser = async({id, data = {}} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/${id}/edit`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing user ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification de l'utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteUser = async({id = ""} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/${id}/delete`, {
        method: "POST",
        withCredentials: true
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting user ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression de l'utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const createUser = async({data = {}} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating user  ${error}`);
        return {status: 400, data: "Erreur lors de la création de l'utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

/* User atna acces */
export const fetchUserAcces = async({id = ""}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/${id}/acces`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching user acces with id ${id}`);
        return {status: 400, data: "Erreur lors de la récupération de l'utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const createUserAcces = async({id = "", data = {}} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/acces/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating user acces  ${error}`);
        return {status: 400, data: "Erreur lors de la création de l'accès de cet utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteUserAcces = async({id = ""} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/${id}/acces/delete`, {
        method: "POST",
        withCredentials: true
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting user access ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression de l'accès à cet utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const editUserAcces = async({id, data = {}} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/user/${id}/acces/edit`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing user access ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification de l'accés de cet utilisateur."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const fetchUserPhoto = async({email = "", size=""}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/auth/user/${email}/photo`, {
        method: "GET",
        withCredentials: true,
        params: {size}
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching user picture with id ${email}`);
        return {status: 400, data: "Erreur lors de la récupération de la photo de profil.", err: error};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}



 // Fonction qui retourne une promesse pour charger l'image
 export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;

      // Quand l'image est chargée, on résout la promesse
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err); // Gère l'erreur en cas d'échec du chargement
  });
};

// Fetch equipes
export const fetchTeams = async({query = ""} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/teams?q=${query}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching teams`);
        return {status: 400, data: "Erreur lors de la récupération des équipes."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTeam = async({id = ""} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/teams/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching team ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération de l'équipe."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const createTeam = async({data = {}} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/teams/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating team ${error}`);
        return {status: 400, data: "Erreur lors de la création de l'équipe."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const editTeam = async({id, data = {}} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/teams/${id}/edit`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing team ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification de l'équipe."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTeam = async({id} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/teams/${id}/delete`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting team ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression de l'équipe."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


// Stats des points d'activités des tiers
export const fetchActivitesPointsByWeek = async({perso = true, teamId = "", id_ic = "", year = 2024, week = 1} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/activites/points`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          perso: perso,
          teamId: teamId,
          week: week,
          year: year,
          id_ic
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching points act by week`);
        return {status: 400, data: "Erreur lors de la récupération des points."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}



// Stats des points d'activités des tiers
export const fetchActivitesPointsByWeekDetails = async({perso = true, teamId = "", id_ic = "", year = 2024, week = 1, page = 1, limit = 50} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/activites/points/details`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          perso: perso,
          teamId: teamId,
          week: week,
          year: year,
          id_ic,
          page,
          limit
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching points act by week`);
        return {status: 400, data: "Erreur lors de la récupération des points en détails."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

// Stats des tiers invalides
export const fetchStatsTiersInvalids = async({perso = true, teamId = "", details = false, id_ic = "", page = 1, limit = 50} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/tiers/invalids`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          perso: perso,
          teamId: teamId,
          details,
          id_ic,
          page,
          limit
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching stats tiers invalids`);
        return {status: 400, data: "Erreur lors de la récupération des tiers invalides."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


// Stats des tiers inactifs
export const fetchStatsTiersInactives = async({perso = true, details = false, id_ic = "", teamId = "", maxDays = 90, page = 1, limit = 50} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/tiers/inactives`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          perso: perso,
          teamId: teamId,
          details,
          id_ic,
          maxDays: maxDays,
          page,
          limit
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching stats tiers invalids`);
        return {status: 400, data: "Erreur lors de la récupération des tiers invalides."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

// Stats des tiers sans activités
export const fetchStatsTiersWithoutActivites = async({perso = true, details = false, id_ic = "", teamId = "", maxDays = 90, page = 1, limit = 50} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/tiers/notvisited`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          perso: perso,
          teamId: teamId,
          details,
          id_ic,
          maxDays: maxDays,
          page,
          limit
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching stats tiers invalids`);
        return {status: 400, data: "Erreur lors de la récupération des tiers invalides."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchStatsContratsConcurrents = async({perso = true, details = false, id_ic = "", teamId = "", echeance = null} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/tiers/contrats-concurrents`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          perso: perso,
          teamId: teamId,
          details,
          id_ic,
          echeance,
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching stats tiers invalids`);
        return {status: 400, data: "Erreur lors de la récupération des tiers invalides."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const fetchStatsTiersActivites = async({perso = true, details = false, id_ic = "", teamId = "", actionType = "RDV", type = "week"} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/tiers/activites/${actionType}`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          perso: perso,
          teamId: teamId,
          details,
          id_ic,
          actionType,
          type
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching stats tiers activities`);
        return {status: 400, data: "Erreur lors de la récupération de l'activité de tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const editTeamBareme = async({bareme = null} = {}) => {
  if(!bareme)  return {status: 400, data: "Bareme invalide"}; 
  try {
    const resApi = await axios(`${apiBaseUrl}/api/teams/bareme`, {
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          bareme
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Editing team bareme`);
        return {status: 400, data: "Erreur lors de la modification du barême."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const fetchSessions = async() => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/sessions`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching sessions ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des sessions."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteSession = async({id = ""} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/sessions/${id}/delete`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting session ${error}`);
        return {status: 400, data: "Erreur lors de la suppression de la session."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteSessionAndBlockUser = async({id = ""} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/sessions/${id}/block`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting session and block user ${error}`);
        return {status: 400, data: "Erreur lors du blocage de l'utilisateur'."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}



// Commando
export const addTiersToCommando = async({id,id_ic={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/commando/add`, {
        method: "POST",
        withCredentials: true,
        data: {
          id_ic: id_ic
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Adding tiers to commando ${id} ${id_ic} ${error}`);
        return {status: 400, data: "Erreur lors de l'ajout du tiers au commando."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const removeTiersFromCommando = async({id,id_ic={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/commando/remove`, {
        method: "POST",
        withCredentials: true,
        data: {
          id_ic: id_ic
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Removing tiers to commando ${id} ${id_ic} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du tiers au commando."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}
export const fetchTiersForCommando = async({perso = true, details = false, id_ic = "", teamId = "",} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/tiers/commando`, {
        method: "GET",
        withCredentials: true,
        params: {
          perso: perso,
          teamId: teamId,
          details,
          id_ic,
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers for commando ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}



// Notes
export const createTiersNotes = async({id, data = {}} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/notes/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating tiers notes ${error}`);
        return {status: 400, data: "Erreur lors de la création de la note."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersNotes = async({id}={}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/notes/${id}`, {
        method: "GET",
        withCredentials: true
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers notes ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des notes."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiersNotes = async({id, idNote} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/notes/${id}/delete/${idNote}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting tiers notes ${error}`);
        return {status: 400, data: "Erreur lors de la suppression de la note."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


// Sites liés
export const fetchTiersSites = async({id}={}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/sites`, {
        method: "GET",
        withCredentials: true
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers sites ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des sites liés."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const addTiersSites = async({id, idToAdd}={}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/sites/${idToAdd}/add`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Adding tiers sites ${error}`);
        return {status: 400, data: "Erreur lors de l'ajout du site."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiersSites = async({id, idToDelete}={}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/sites/${idToDelete}/delete`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting tiers sites ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du site."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}





// Client
// Affichage d'un tiers
export const fetchClients = async({siret}={}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/clients/${siret}`, {
        method: "GET",
        withCredentials: true
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching clients ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des codes clients."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const getCodeClientType = (codeClient, codeSociete) => {
  if(codeSociete === 1) return "Repro-IT"
  if(codeSociete === 2) {
    if(codeClient.charAt(0) === "X") return 'Phone-IT'
    if(codeClient.charAt(0) === "Z") return 'Ident-IT'
    else return "Data-IT"
  }

}



export const generatePageArray = (totalPages) => {
  const pageArray = [];
  let i = 1;

  while (i <= 10 && i <= totalPages) {
      pageArray.push(i);
      i++;
  }

  i = 20;
  while (i <= 100 && i <= totalPages) {
      pageArray.push(i);
      i += 20;
  }

  i = 200;
  while (i <= 1000 && i <= totalPages) {
      pageArray.push(i);
      i += 100;
  }

  i = 1400;
  while (i <= totalPages) {
      pageArray.push(i);
      i += 400;
  }

  if (!pageArray.includes(totalPages)) {
      pageArray.push(totalPages);
  }

  return pageArray;
}




export const api = apiBaseUrl


