import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///



// Clients impayés ( factures et avoirs )

export const fetchTiersImpayes = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/compta/${id}/impayes/all`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      console.log(resApi)
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  export const fetchTiersImpayesRepro = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/compta/${id}/impayes/repro`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      console.log(resApi)
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  export const fetchTiersImpayesData = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/compta/${id}/impayes/data`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      console.log(resApi)
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


  export const fetchTiersImpayesIdent = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/compta/${id}/impayes/ident`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      console.log(resApi)
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


  export const fetchTiersImpayesPhone = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/compta/${id}/impayes/phone`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      console.log(resApi)
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


