import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;


export const fetchTiersAlertes = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/alertes`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };