import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from "react";
import { LuCalendar } from "react-icons/lu";
import { cn } from "../../utils/cn";

const DateInput = ({ className, inpClassName, object, setObject, objValue, validation, max, min, title, readOnly, required = false, type }) => {
  const [state, setState] = useState({
    value: '',
    isWrong: false,
    errMsg: ''
  });
  const inputRef = useRef(null);

  useEffect(() => {
    if (object[objValue] !== undefined) {
      setState((prev) => ({
        ...prev,
        value: object[objValue] ? moment(object[objValue]).locale('fr').format('DD/MM/YYYY') : ''
      }));
    }
  }, [object, objValue]);

  const updateObject = useCallback(
    (newVl) => {
      let newValue = newVl.trimStart();

      if (required && !newValue) {
        setState((prevState) => ({
          ...prevState,
          isWrong: true,
          errMsg: 'Ce champ est requis'
        }));
        return;
      }

      if (validation) {
        const resValidation = validation(newValue);
        if (resValidation.err) {
          setState((prevState) => ({
            ...prevState,
            isWrong: true,
            errMsg: resValidation.message
          }));
          return;
        }
      }

      if (newValue && !/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(newValue)) {
        setState((prevState) => ({
          ...prevState,
          isWrong: true,
          errMsg: 'Date non valide, format DD/MM/YYYY requis.'
        }));
        return;
      }

      const dateMoment = moment(newValue, 'DD/MM/YYYY');
      let formattedValue = null;

      if (type === 'datetime') {
        formattedValue = dateMoment.isValid() ? dateMoment.format("YYYY-MM-DD") : null;
      } else {
        formattedValue = dateMoment.isValid() ? dateMoment.valueOf() : null;
      }

      if (min && dateMoment.isBefore(moment(min, 'DD/MM/YYYY'))) {
        setState((prevState) => ({
          ...prevState,
          isWrong: true,
          errMsg: `La date doit être après le ${moment(min, 'DD/MM/YYYY').format('DD/MM/YYYY')}`
        }));
        return;
      }

      if (max && dateMoment.isAfter(moment(max, 'DD/MM/YYYY'))) {
        setState((prevState) => ({
          ...prevState,
          isWrong: true,
          errMsg: `La date doit être avant le ${moment(max, 'DD/MM/YYYY').format('DD/MM/YYYY')}`
        }));
        return;
      }

      setState((prevState) => ({
        ...prevState,
        value: newValue,
        isWrong: false,
        errMsg: ''
      }));

      setObject((prev) => ({
        ...prev,
        [objValue]: formattedValue
      }));
    },
    [validation, objValue, setObject, min, max, required, type]
  );

  return (
    <div className={`relative group atna-select-options-container-${objValue} pointer-events-auto`} >
      <div
        title={title}
        className={cn(
          'flex items-center w-[170px] rounded-[6px] bg-wh h-[26px] shrink-0 grow-0 relative',
          className,
          readOnly && "select-none cursor-default"
        )}
      >
        <input
          value={state.value || ''}
          readOnly={readOnly ? true : false}
          required={required}
          onChange={(e) => {
            if(readOnly) return;
            const value = e.target.value.replaceAll('-', '/');
            setState((prevState) => ({
              ...prevState,
              value: value
            }));
          }}
          onBlur={(e) => {
            updateObject(e.target.value);
          }}
          className={cn(
            'h-full w-full border-none outline-none bg-transparent px-[10px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide',
            inpClassName
          )}
          type="text"
        />
        <div className="p-[3px] relative">
          <div
            onClick={() => {
              if(readOnly) return;
              inputRef.current.showPicker();
            }}
            className="h-full w-[30px] rounded-[10px] flex items-center justify-center text-base cursor-pointer relative z-[40]"
          >
            <LuCalendar className='text-[15px] text-black/80'/>
          </div>
          <input
            value={state.value ? moment(state.value, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''}
            onChange={(e) => {
              if(readOnly) return;
              const date = e.target.value ? moment(e.target.value).locale('fr').format('DD/MM/YYYY') : '';
              updateObject(date);
            }}
            type="date"
            className="absolute w-[100%] h-[100%] opacity-0 z-[90] top-[10px] left-0 pointer-events-auto"
            ref={inputRef}
            max={max ? moment(max, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined}
            min={min ? moment(min, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined}
          />
        </div>
        <div
          className={cn(
            "absolute top-[calc(100%_+_5px)] left-0 min-w-[200px] max-w-[200px] min-h-[25px] z-[99] bg-error atna-error-drop rounded-[8px] px-[10px] font-medium py-[3px] text-wh opacity-0 transition-opacity duration-100 text-xs pointer-events-none", state.isWrong && "opacity-100 pointer-events-auto"
          )}
        >
          {state.errMsg}
        </div>
      </div>
    </div>
  );
};

export default DateInput;
