import React, { useEffect, useState } from 'react';
import { cn } from '../../utils/cn';

const TextArea = ({ object, setObject, value, baseValue, dataTitle, placeholder, inpClassName, type, required, className, maxLength, realTime,
    validation = () => ({valid: true, err: null})
}) => {
    const [isInvalid, setIsInvalid] = useState(null)
    const [invalidMessage, setInvalidMessage] = useState("")
    const [inpValue, setInpValue] = useState("")

    useEffect(() => {
        setInpValue(object[value] ||  "")
    }, [object[value]])

    useEffect(() => {
        if(realTime)  setObject((prev) => {
            prev[value] = inpValue
            return {
                ...prev
            }
            })
    }, [inpValue])

    return (
        <div className={`relative w-full group atna-select-options-container-${value}`} >
                            <div data-title={dataTitle}
                            className={cn("flex items-center min-w-[170px] rounded-[6px]  bg-wh min-h-[26px] shrink-0 grow-0 relative",( object[value] !== baseValue ? baseValue : object[value]) && 'bg-gray-700', className
                            )}>
                        <div className={cn("absolute top-[calc(100%_+_5px)] left-0 min-w-[200px] max-w-[200px] min-h-[25px] z-[99] bg-error atna-error-drop rounded-[8px] px-[10px] font-medium py-[3px] text-wh opacity-0 transition-opacity duration-100 text-xs pointer-events-none", isInvalid && "opacity-100 pointer-events-auto")}>{invalidMessage}</div>
                        <textarea name={value||""}  placeholder={placeholder} value={inpValue || ""} 
                            autoComplete='off'
                            spellCheck="false"
                            maxLength={maxLength}
                            onChange={(e) => {
                                 let newValue = e.target.value
                                 if(maxLength) newValue = e.target.value.slice(0, maxLength)
                                 if(type === "number") newValue = e.target.value.replace(/\D/g, '');
                                setInpValue(newValue)
                            }}
                            onBlur={(e) => {
                                const isValid = validation(e.target.value)
                                if(required && !e.target.value){
                                    setIsInvalid(true)
                                    setInvalidMessage("Champs requis")
                                } else {
                                if(!isValid.valid){
                                    setIsInvalid(true)
                                    setInvalidMessage(isValid.data)
                                } else {
                                    setIsInvalid(false)
                                    setInvalidMessage(null)

                                    let toSetValue = e.target.value.trim() || ""
                                    if(type === "number") {
                                        toSetValue = toSetValue ? parseInt(toSetValue, 10) : null;
                                    }
                                    setObject((prev) => {
                                    prev[value] = toSetValue
                                    return {
                                        ...prev
                                    }
                                    })
                                }
                            }
                                
                            }} type="text" className={cn('h-full resize-y w-full border-none outline-none bg-transparent px-[10px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide', inpClassName)}
                            ></textarea>
                
                            </div>
                        </div>
    );
}

export default TextArea;
