import React, { useEffect, useRef, useState } from 'react';
import {  TiArrowRight, TiVendorMicrosoft } from "react-icons/ti";
import { HiOutlineArrowRight } from "react-icons/hi";
import {BiLoaderAlt} from 'react-icons/bi'
import config from '../../../utils/config';
import TextInput from '../components/textInput'
import { cn } from '../../../utils/cn';
import { loginUser } from '../../../utils/atna';
import { api } from '../../../utils/atna'
import { useSocket } from '../../../utils/hooks/useSocket';

const Login = ({fetchUser}) => {

    const [data, setData] = useState({id: "",psw: ""})
    const [loading, setLoading] = useState(false)
    const {clientId, socket} = useSocket()

    function handleLogin() {
        var left = (window.screen.width/2)-(500/2);
        var top = (window.screen.height/2)-(750/2);
        const authWindow = window.open((api)+`/api/auth/login/azure?clientId=${clientId}`, 'aTnaLogin', `tabbar=no, toolbar=no, location=no, directories=no, status=no, menubar=no, titlebar=no, scrollbars=no, zoom=80 ,resizable=no, width=500, height=700, left=${left}, top=${top}`)
        if (authWindow) {
            const timer = setInterval(() => {
              if (authWindow.closed) {
                clearInterval(timer); // Arrête le timer
                fetchUser();
              }
            }, 200);
          } 
      
        socket.on('loginClosed', () => {
                fetchUser(); 
        });
      }

      async function handleLocalLogin() {
        setLoading(true)
        const loginRes = await loginUser(data)
        if(loginRes.status !== 200) {
            setLoading(false);
            if(!loginRes.data) return;
           if(loginRes.data?.toLowerCase().includes('identifiant')) document.querySelector('.idInp')?.focus()
           else if(loginRes.data?.toLowerCase().includes('passe')) document.querySelector('.pswInp')?.focus()
           window.alert(loginRes.data)
        }
        fetchUser()
        setLoading(false);
      }


    return (
        <div className='w-full h-full flex items-center max-sm:flex-col'>

      

      
        <div className="w-full max-w-[700px] max-sm:max-w-full h-full flex px-12 py-12 max-sm:px-6 bg-white backdrop-blur-sm flex-col justify-between items-end  gap-y-2 max-sm:pb-10 max-sm:h-2/3 max-sm:py-6">
             <div className="h-max w-max">
             </div>

                    <div 
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            if(!(data.id && data.psw)) return;
                            handleLocalLogin()
                        }
                    }}
                    className="flex flex-col justify-center items-end max-sm:w-full max-sm:items-end gap-y-2">
                        <h1 className='text-3xl  text-grey font-sans font-bold'>Connexion à aTna</h1>
                    
                        <div 
                        onClick={() => {
                            handleLogin()
                        }}
                        className="h-[32px] rounded-[12px] atna-border !bg-black text-white font-semibold tracking-wide text-[12px] px-[20px] flex items-center cursor-pointer w-[150px] justify-center gap-x-[15px] mt-[10px]">
                            <TiVendorMicrosoft className='text-[14px]'/> Microsoft
                        </div>
                    </div>


                    <div className="flex h-16 max-sm:hidden">
                    </div>
            </div>

            
            <div className="flex-1 h-full  max-sm:h-1/3 max-sm:w-full relative"
             style={{
                background: "url(/cdn/images/loginBack.png)",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'brightness(70%)'
            }}
            >
            </div>
      

        </div>
    );
}

export default Login;