import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// BEBEDC -> Opportunites -> fiche tiers

  // Récupérer un BEBEDC par son idOpportunite
  export const fetchTiersBEBEDC = async ({ id, idOpportunite }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/BEBEDC/`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching opportunities ${id} BEBEDC ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des BEBEDC pour cet opportunité.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  // Récupérer un BEBEDC par son idBEBEDC
  export const fetchTiersOneBEBEDC = async ({ id, idOpportunite, idBEBEDC }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/BEBEDC/details/${idBEBEDC}`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching opportunities ${id} BEBEDC ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération du BEBEDC pour cet identifiant.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

// Créer un BEBEDC pour une opportunité
export const createTiersBEBEDC = async ({ id, idOpportunite, data }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/BEBEDC/create`,
        {
          method: "POST",
          withCredentials: true,
          data: {...data}
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Creating opportunitie ${id} BEBEDC ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la création du BEBEDC.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };
  


// Modifier un BEBEDC
export const editTiersBEBEDC = async ({ id, idOpportunite, idBEBEDC, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/BEBEDC/update/${idBEBEDC}`,
      {
        method: "PUT",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Editing BEBEDC for opportunitie ${id}/${idOpportunite} ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la modification du BEBEDC .",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};




// Supprimer un BEBEDC
export const deleteTiersBEBEDC = async ({ id, idOpportunite, idBEBEDC }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/BEBEDC/delete/${idBEBEDC}`,
      {
        method: "DELETE",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Deleting BEBEDC for opportunitie ${id}/${idOpportunite} ${error}`);
      return { status: 400, data: "Erreur lors de la suppression du BEBEDC." };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};