// Import des librairies et des hooks
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Import des fonctions utilitaires
import { cn } from "../../../../../../../utils/cn";
import { editTeam, fetchTeams } from "../../../../../../../utils/atna";

// Import des hooks personnalisés
import { useGlobalState } from "../../../../../../../utils/hooks/useGlobalState";
import { useAuth } from "../../../../../../../utils/hooks/useAuth";
import { useAlerts } from "../../../../../../../utils/hooks/useAlert";

// Import des composants
import InputBoolean from "../../../../../../../components/inputs/inputBoolean";
import TextArea from "../../../../../../../components/inputs/textArea";
import DeleteMenu from "../components/deleteMenu";
import Input from "../../../../../../../components/inputs/input";
import InputSelect from "../../../../../../../components/inputs/inputSelect";

// Import d'icônes
import { BiLoaderAlt } from "react-icons/bi";

// Constantes pour les classes réutilisées
const h1ClassName = `text-[11px] text-[rgba(55,53,47,0.5)] font-medium leading-[18px] tracking-wide`;
const fieldClassName = `flex flex-col min-w-[200px] w-full max-w-[250px]`;

const FicheEquipe = ({
  fetchEquipeData,
  setEquipeData,
  equipeData,
  baseEquipeData,
  setBaseEquipeData,
  fetchAllTeams,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const { pageState } = useParams();
  const [deleteMenuVisible, setDeleteMenuVisible] = useState(false);
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const { allUsers } = useGlobalState();
  const { user } = useAuth();
  const [personnelVisible, setPersonnelVisible] = useState([]);

  // Eviter le recalcul du JSON stringifié à chaque render
  const isDataEdited = useMemo(() => {
    const baseData = JSON.stringify(
      baseEquipeData ?? {},
      (key, value) => value ?? ""
    );
    const editedData = JSON.stringify(
      equipeData ?? {},
      (key, value) => value ?? ""
    );
    return baseData !== editedData;
  }, [equipeData, baseEquipeData]);

  // Mettre à jour l'état de l'édition
  useEffect(() => {
    setIsEdited(isDataEdited);
  }, [isDataEdited]);

  // Mettre à jour les données de base
  const handleEditData = useCallback(async () => {
    setIsSaving(true);
    const resEditData = await editTeam({
      id: pageState,
      data: { ...equipeData },
    });
    setIsSaving(false);
    if (resEditData.status !== 200) return addAlert(resEditData.data);

    // Recharger les données de l'équipe
    fetchAllTeams();
    if (resEditData.data.id !== pageState)
      navigate("/admin/equipes/" + resEditData.data.id);
    fetchEquipeData({ id: resEditData.data.id });
    setIsEdited(false);
  }, [
    equipeData,
    addAlert,
    fetchAllTeams,
    fetchEquipeData,
    navigate,
    pageState,
  ]);

  // Fonction pour réinitialiser les données
  async function handleResetData() {
    setEquipeData(() => {
      return { ...baseEquipeData };
    });
  }

  // Fonction pour supprimer les données
  async function handleDeleteData() {
    setDeleteMenuVisible(() => true);
  }

  // Filtrer les utilisateurs non bloqués
  useEffect(() => {
    if (!allUsers) return;

    const filteredUsers = allUsers.filter((user) => {
      return user.blocked === 0;
    });

    setPersonnelVisible(filteredUsers);
  }, [allUsers, user.blocked]);

  // Options pour les commerciaux non bloqués
  const allPersonnelVisibleInpOptions = useMemo(
    () =>
      personnelVisible.map((vl) => ({
        value: vl.id,
        label: vl.nomComplet,
      })),
    [personnelVisible]
  );

  // Récupérer les équipes
  const [equipes, setEquipes] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const resApi = await fetchTeams();
      if (resApi.status !== 200)
        return addAlert("Imossible de récupérer les équipes.");
      setEquipes(() => [...resApi.data]);
    };
    getData();
  }, [addAlert]);

  // Options pour les équipes
  const listEquipesOptions = useMemo(() => {
    return [
      { value: null, label: "Aucune" },
      ...equipes.map((vl) => ({
        value: vl.id,
        label: vl.nom,
      })),
    ];
  }, [equipes]);

  return (
    <div className="flex flex-col ">
      {deleteMenuVisible && (
        <DeleteMenu
          menuVisible={deleteMenuVisible}
          setMenuVisible={setDeleteMenuVisible}
          fetchAllTeams={fetchAllTeams}
        />
      )}

      <div className="flex gap-x-[10px] w-full flex-wrap">
        <div className={fieldClassName}>
          <h1 className={h1ClassName}>Id</h1>
          <Input
            object={equipeData}
            setObject={setEquipeData}
            value={"id"}
            dataTitle={null}
            placeholder={""}
            className={"w-full"}
            type="text"
            maxLength={10}
            required={true}
            validation={(str) =>
              str.length > 10
                ? {
                    valid: false,
                    data: `L'identifiant ne peut dépasser 10 caractères`,
                  }
                : { valid: true, data: null }
            }
          />
        </div>

        <div className={fieldClassName}>
          <h1 className={h1ClassName}>Nom</h1>
          <Input
            object={equipeData}
            setObject={setEquipeData}
            value={"nom"}
            dataTitle={null}
            placeholder={""}
            className={"w-full"}
            type="text"
            required={true}
            validation={(str) =>
              str.length > 255
                ? {
                    valid: false,
                    data: `Le nom de l'équipe ne peut dépasser 255 caractères`,
                  }
                : { valid: true, data: null }
            }
          />
        </div>

        <div className={fieldClassName}>
          <h1 className={h1ClassName}>Manager</h1>
          <InputSelect
            object={equipeData}
            inpClassName="atna-search-inp-listener"
            setObject={setEquipeData}
            value="idManager"
            placeholder={"Personnel actif"}
            options={allPersonnelVisibleInpOptions}
          />
        </div>
      </div>

      <div className="flex gap-x-[10px] w-full flex-wrap">
        <div className={fieldClassName}>
          <h1 className={h1ClassName}>Barême</h1>
          <Input
            object={equipeData}
            setObject={setEquipeData}
            value={"bareme"}
            dataTitle={null}
            placeholder={""}
            className={"w-full"}
            type="number"
            maxLength={10}
            required={true}
            validation={(str) =>
              parseInt(str) < 1
                ? { valid: false, data: `Le barême minimum est de 1.` }
                : { valid: true, data: null }
            }
          />
        </div>

        <div className={fieldClassName}>
          <h1 className={h1ClassName}>Niveau</h1>
          <Input
            object={equipeData}
            setObject={setEquipeData}
            value={"niveau"}
            dataTitle={null}
            placeholder={""}
            className={"w-full"}
            type="number"
            maxLength={10}
            required={true}
            validation={(str) => ({ valid: true, data: null })}
          />
        </div>

        <div className={fieldClassName}>
          <h1 className={h1ClassName}>Equipe parente</h1>
          <InputSelect
            options={listEquipesOptions}
            object={equipeData}
            setObject={setEquipeData}
            value={"idParent"}
            dataTitle={null}
            placeholder={""}
            className={"w-full"}
            type="number"
            maxLength={10}
            required={false}
            validation={(str) => ({ valid: true, data: null })}
          />
        </div>

        <div className={fieldClassName}>
          <h1 className={h1ClassName}>Active ?</h1>
          <InputBoolean
            object={equipeData}
            setObject={setEquipeData}
            inpClassName={"text-[10px]"}
            dataTitle={null}
            value="active"
          />
        </div>
      </div>

      <div className="flex gap-x-[10px] w-full flex-wrap">
        <div className={cn(fieldClassName, " max-w-[510px]")}>
          <h1 className={h1ClassName}>Message</h1>
          <TextArea
            object={equipeData}
            setObject={setEquipeData}
            value={"message"}
            dataTitle={null}
            placeholder={""}
            type="text"
            required={false}
            validation={(str) => {
              return { valid: true, data: null };
            }}
            inpClassName={cn("w-full min-h-[100px] py-[10px]")}
            className={"min-w-0 w-full"}
          />
        </div>
      </div>

      <div className={cn("flex mt-[20px] gap-x-[20px]")}>
        <div
          className={cn(
            "h-[28px] rounded-[10px] atna-border !bg-black text-wh font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer",
            !isEdited && !isSaving && "opacity-75 pointer-events-none"
          )}
          onClick={() => {
            handleEditData();
          }}
        >
          {isSaving ? (
            <BiLoaderAlt className="text-[13px] animate-spin" />
          ) : (
            "Enregistrer"
          )}
        </div>

        <div
          className={cn(
            "h-[28px] rounded-[10px] atna-border !bg-white text-black font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer hover:underline",
            isSaving && "opacity-75 pointer-events-none"
          )}
          onClick={() => {
            handleDeleteData();
          }}
        >
          Supprimer
        </div>

        <div
          onClick={() => {
            handleResetData();
          }}
          className="h-[28px] rounded-[10px] font-medium text-[11px] px-[10px] flex items-center hover:underline cursor-pointer"
        >
          Annuler
        </div>
      </div>
    </div>
  );
};

export default FicheEquipe;
