import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAlerts } from '../../../../../../utils/hooks/useAlert';
import { BiLoaderAlt } from 'react-icons/bi';
import { fetchTeam } from '../../../../../../utils/atna';
import FicheEquipe from './views/fiche';
import NewEquipe from './views/newEquipe';

const AdminTeamView = ({fetchAllTeams}) => {

    const { pageState } = useParams()
    const [viewType, setViewType] = useState("perso")
    const [equipeData, setEquipeData] = useState(null)
    const [baseEquipeData, setBaseEquipeData] = useState({})
    const [loading, setLoading] = useState(true)
    const {addAlert} = useAlerts()

    const fetchData = async({id = pageState} = {}) => {
        if(id === "nouveau") return;
        const resApi = await fetchTeam({id: id })
        setLoading(false)
        if(resApi.status === 200) {
            setBaseEquipeData(() => {
                return {...resApi.data}
            })
            setEquipeData(() => {
                return {...resApi.data}
            })
        } else {
            addAlert('Une erreur s\'est produite lors de la récupération de l\'équipe.')
        }
    }


    useEffect(() => {
        fetchData()
    }, [pageState])



    if(pageState === "nouveau") return (
        <div className='flex flex-1 w-full pt-[20px]'>
        <NewEquipe fetchAllTeams={fetchAllTeams} fetchData={fetchData} />
    </div>
    )
    else return (
        <div className='flex flex-1 w-full flex-col'>
             <div className="flex flex-col pt-[20px]">
              {
                    loading ?
                    <div className="absolute w-full h-full z-[105] bg-white">
                    <div className="w-full mt-[20px] flex justify-center items-center h-2/3">
                        <BiLoaderAlt className='animate-spin text-[15px]' />
                    </div>
                    </div>
                    :
                    !equipeData?.id ?
                    <div className="flex-[1_1_auto]">
                    <div className="w-full mt-[80px] flex justify-center items-center h-1/3">
                        <h1 className="text-[13px] px-[5px] text-[rgba(55,53,47,0.5)] font-medium">
                            Aucune équipe trouvé.
                        </h1>
                    </div>
                </div>
                    :
                    <FicheEquipe equipeData={equipeData} setEquipeData={setEquipeData} baseEquipeData={baseEquipeData} setBaseEquipeData={setBaseEquipeData} fetchEquipeData={fetchData} fetchAllTeams={fetchAllTeams}/>
                }
                </div>
        </div>
    );
}

export default AdminTeamView;
