import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fetchUserEvents, fetchUserEventsScheduled } from '../../../../utils/atna';
import moment from 'moment';
import 'moment/locale/fr';
import { upperFirst } from 'lodash';
import { cn } from '../../../../utils/cn';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useAlerts } from '../../../../utils/hooks/useAlert';
import AgendaMenu from './components/menu';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../utils/hooks/useAuth';

moment.locale('fr');

const CrmAgendaView = () => {
  const [currentWeekNumber, setCurrentWeekNumber] = useState(moment().week());
  const [weekDays, setWeekDays] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [popupYear, setPopupYear] = useState(moment().year());
  const [popupMonth, setPopupMonth] = useState(moment().month());
  const [menuVisible, setMenuVisible] = useState(false)
  const [data, setData] = useState({})
  const [baseData, setBaseData] = useState({})
  const [isNew, setIsNew] = useState(false)

  const { addAlert } = useAlerts();
  const [events, setEvents] = useState([]);
  const scrollRef = useRef(null);
  const horizontalScrollRef = useRef(null);

  const {user} = useAuth()
  const navigate = useNavigate()


  const getWeekDays = useCallback((weekNumber) => {
    const startOfYear = moment().startOf('year');
    const startOfWeek = startOfYear.clone().week(weekNumber).startOf('week');
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, 'days');
      days.push({
        dayName: day.format('dddd'),
        date: day.format('YYYY-MM-DD'),
      });
    }
    return days;
  }, []);

  useEffect(() => {
    setWeekDays(getWeekDays(currentWeekNumber));
  }, [currentWeekNumber, getWeekDays]);

  const handleDaySelect = (day) => {
    const selectedDate = moment(`${popupYear}-${popupMonth + 1}-${day}`, 'YYYY-MM-DD');
    const weekNumber = selectedDate.week();
    setSelectedYear(popupYear);
    setSelectedMonth(popupMonth);
    setCurrentWeekNumber(weekNumber);
    setIsPopupOpen(false);
  };

  const handleMonthChange = (direction) => {
    if (direction === 'next') {
      setPopupMonth((prev) => (prev + 1) % 12);
      if (popupMonth === 11) setPopupYear((prev) => prev + 1);
    } else {
      setPopupMonth((prev) => (prev === 0 ? 11 : prev - 1));
      if (popupMonth === 0) setPopupYear((prev) => prev - 1);
    }
  };

  const getDaysInMonth = () => {
    const daysInMonth = moment(`${popupYear}-${popupMonth + 1}`, 'YYYY-MM').daysInMonth();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };

  async function fetchData() {

   
    const startOfYear = moment().startOf('year');
    const startOfWeek = startOfYear.clone().week(currentWeekNumber).startOf('week').toISOString();
    const endOfWeek = startOfYear.clone().week(currentWeekNumber).endOf('week').toISOString();

    const resApi = await fetchUserEventsScheduled({
      startTime: startOfWeek,
      endTime: endOfWeek
    });
    if (resApi.status !== 200) {
      return addAlert("Impossible de récupérer le calendrier microsoft.");
    } else {
      setEvents(() => {
        return [...resApi.data]
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, [currentWeekNumber]);

  

  const currentHour = moment().hour();
  const currentMinutes = moment().minutes();
  const hourHeight = 100; // Hauteur de chaque heure en pixels
  const currentTimePosition = currentHour * hourHeight + (currentMinutes / 60) * hourHeight;

  useEffect(() => {
    if (scrollRef.current) {
      const containerHeight = scrollRef.current.clientHeight;
      const scrollPosition = currentTimePosition - containerHeight / 2;
      scrollRef.current.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [currentTimePosition]);

  useEffect(() => {
    if(!user.msAccount) return navigate('/crm')
    fetchData();
  }, []);


   // Scroller jusqu'à la position du jour actuel au chargement
   useEffect(() => {
    if (horizontalScrollRef.current) {
      const todayIndex = weekDays.findIndex(day => day.date === moment().format('YYYY-MM-DD'));
      if (todayIndex !== -1) {
        const dayWidth = 120; // Largeur de chaque colonne de jour (min-w-[120px])
        const scrollPosition = todayIndex * dayWidth - (horizontalScrollRef.current.clientWidth / 2) + (dayWidth / 2);
        horizontalScrollRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [weekDays]);

  // Gestion du tri et des chevauchements
  const dateEvents = useMemo(() => {
    const groupedEvents = events.reduce((acc, event) => {
      const eventStart = moment(event.start.dateTime);
      const eventEnd = moment(event.end.dateTime);
      const dateKey = eventStart.format('YYYY-MM-DD');
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push({ ...event, start: eventStart, end: eventEnd, startDate: eventStart.format('YYYY-MM-DD'), endDate: eventEnd.format('YYYY-MM-DD'), startHour: eventStart.format('HH:mm'), endHour: eventEnd.format('HH:mm'), location: event.locations[0]?.displayName || "" });
      return acc;
    }, {});

    const eventGroups = {};
    Object.keys(groupedEvents).forEach((date) => {
      // Trier par date de début, puis par durée croissante si les dates de début sont identiques
      const eventsForDate = groupedEvents[date].sort((a, b) => {
        if (a.start.isSame(b.start)) {
          return a.end.diff(a.start) - b.end.diff(b.start);
        }
        return a.start - b.start;
      });

      const groups = [];
      eventsForDate.forEach((event) => {
        let placed = false;
        for (let group of groups) {
          if (group.some(e => event.start.isBefore(e.end) && event.end.isAfter(e.start))) {
            group.push(event);
            placed = true;
            break;
          }
        }
        if (!placed) {
          groups.push([event]);
        }
      });

      eventGroups[date] = groups;
    });
    return eventGroups;
  }, [events]);

  return (
    <div className="flex h-full flex-col">

            {
                menuVisible && <AgendaMenu fetchData={fetchData} baseData={baseData} setBaseData={setBaseData} data={data} setData={setData} menuVisible={menuVisible} setMenuVisible={setMenuVisible} isNew={isNew} />
            }


      {/* Header - Month and Year */}
      <div className="flex items-center justify-between mb-2 py-[10px] mt-[30px] px-[20px]">
        <div
          className="flex items-center gap-x-[10px] cursor-pointer"
          onClick={() => {
            setPopupYear(selectedYear);
            setPopupMonth(selectedMonth);
            setIsPopupOpen(true);
          }}
        >
          <span className="font-bold text-[20px]">
            {upperFirst(moment().month(selectedMonth).format('MMMM'))}
          </span>
          <span className="text-[20px] font-semibold">{selectedYear}</span>
        </div>
      </div>

      {/* Calendar */}
      <div ref={horizontalScrollRef} className="overflow-x-auto overflow-y-hidden atna-drop-menu-scroll">
        <div className="min-w-[800px] ml-[60px] grid grid-cols-7 gap-[0.5px]">
          {weekDays.map((day, index) => {
            const dayMoment = moment(day.date);
            const isDifferentMonth = dayMoment.month() !== selectedMonth;
            return (
              <div key={index} className="bg-white text-center py-[5px] min-w-[120px] shrink-0">
                <h3 className={cn(
                  'font-semibold text-black/60 text-lg',
                  day.date === moment().format('YYYY-MM-DD') && 'text-black/80',
                  isDifferentMonth && "text-black/30"
                )}>
                  {`${upperFirst(dayMoment.format('ddd'))} ${dayMoment.format('DD')}${
                    isDifferentMonth ? ` ${upperFirst(dayMoment.format('MMM'))}` : ''
                  }`.replaceAll('.', '')}
                </h3>
              </div>
            );
          })}
        </div>

        <div ref={scrollRef} className="min-w-[860px] flex flex-1 w-full h-full overflow-y-scroll atna-drop-menu-scroll relative">
          <div className="flex flex-col w-[60px] bg-transparent shrink-0">
            {Array.from({ length: 24 }).map((_, hourIndex) => (
              <div key={hourIndex} className="h-[100px] flex items-start justify-end text-[13px] font-medium text-black/20 shrink-0 p-[20px]">
                {hourIndex}:00
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 flex-1 shrink-0">
            {weekDays.map((day) => {
              const eventGroups = dateEvents[day.date] || [];
              return (
                <div key={"eventDate_" + day.date} className="relative flex flex-col shrink-0 min-w-[120px]">
                  {Array.from({ length: 24 }).map((_, hourIndex) => (
                    <div
                      key={`${day.date}-${hourIndex}`}
                      onClick={() => {
                        const bsData = {
                          startDate: day.date,
                          endDate: day.date,
                          startHour: `${hourIndex.toString().padStart(2, "0")}:00`,
                          endHour: `${hourIndex.toString().padStart(2, "0")}:30`,
                        }
                        setBaseData(() => {
                          return {...bsData}
                        })
                        setData(() => {
                          return {...bsData}
                        })
                        setIsNew(() => true)
                        setMenuVisible(() => true)
                      }}
                      className={cn("h-[100px] border-t border-gray-100 bg-white hover:bg-gray-50 transition-colors", 
                        day.date === moment().locale('fr').format('YYYY-MM-DD') && "bg-gray-50/20 border-x border-x-gray-50",
                        day.date === moment().locale('fr').format('YYYY-MM-DD') &&  hourIndex.toString().padStart(2, "0") === moment().locale('fr').format('HH') && "bg-gray-50/50")}
                    />
                  ))}

{eventGroups.map((group, groupIndex) => {
  const groupStart = group.reduce((min, event) => moment.min(min, event.start), group[0].start);
  const groupEnd = group.reduce((max, event) => moment.max(max, event.end), group[0].end);
  const groupTop = (groupStart.hours() + groupStart.minutes() / 60) * hourHeight;
  const totalGroupHeight = groupEnd.diff(groupStart, 'minutes') / 60 * hourHeight;
  const groupHeight = totalGroupHeight - 5; // Hauteur effective pour les événements, sans le padding

  // Somme totale des durées pour calculer les proportions
  const totalDuration = group.reduce((sum, event) => sum + event.end.diff(event.start, 'minutes'), 0);

  return (
    <div
      key={groupIndex}
      className="absolute bg-neutral-900/10 border border-neutral-300 rounded-[5px] py-[5px] px-[10px] text-sm font-semibold text-neutral-700"
      style={{
        top: `${groupTop}px`,
        height: `${totalGroupHeight}px`, // Hauteur totale, incluant le padding
        width: 'calc(100% - 40px)',
        marginLeft: "20px",
      }}
    >
      {group.map((event, index) => {
        // Calculer la hauteur proportionnelle dans l'espace effectif (sans padding)
        const eventDuration = event.end.diff(event.start, 'minutes');
        const eventHeightPercentage = (eventDuration / totalDuration) * groupHeight / totalGroupHeight * 100;

        return (
          <div
          onClick={() => {
            setBaseData(() => {
              return {...event}
            })
            setData(() => {
              return {...event}
            })
            setIsNew(() => false)
            setMenuVisible(() => true)
          }}
            key={index}
            className="relative bg-neutral-900 rounded-[6px] my-[2px] px-[10px] py-[5px] text-[13px] cursor-pointer"
            style={{
              height: `${eventHeightPercentage}%`,
              width: '100%',
            }}
          >
            <h1 className=' text-white text-[14px]'>{event.subject}</h1>

            <h2 className="absolute bottom-[5px] right-[10px] text-white/50">
            {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
            </h2>
          </div>
        );
      })}
    </div>
  );
})}

                </div>
              );
            })}
          </div>

          {/* Current time line */}
          <div
            className="absolute left-0 w-full h-[1px] bg-black/20"
            style={{ top: `${currentTimePosition}px` }}
          >
            <div className="absolute left-[0px] top-1/2 -translate-y-1/2 bg-white text-black/60 text-[12px] font-bold px-[10px] py-[5px] rounded-[8px]">
              {moment().format('HH:mm')}
            </div>
          </div>
        </div>
      </div>

      {/* Popup for Date Selection */}
      {isPopupOpen && (
        <div className="fixed inset-0 backdrop-blur-[4px] flex items-center justify-center">
          <div className="atna-drop-menu py-[15px] px-[20px]">
            <div className="flex justify-between items-center mb-4 gap-4">
              <div onClick={() => handleMonthChange('prev')} className="text-[20px] size-[30px] cursor-pointer flex items-center justify-center">
                <BiChevronLeft />
              </div>
              <select
                value={popupMonth}
                onChange={(e) => setPopupMonth(parseInt(e.target.value))}
                className="p-2 border rounded"
              >
                {moment.months().map((month, index) => (
                  <option key={month} value={index}>
                    {upperFirst(month)}
                  </option>
                ))}
              </select>
              <input
                type="number"
                value={popupYear}
                onChange={(e) => setPopupYear(parseInt(e.target.value))}
                className="w-[80px] p-2 border rounded"
                placeholder="Année"
              />
              <div onClick={() => handleMonthChange('next')} className="text-[20px] size-[30px] cursor-pointer flex items-center justify-center">
                <BiChevronRight />
              </div>
            </div>
            <div className="grid grid-cols-7 gap-0">
              {getDaysInMonth().map((day) => {
                const currentDate = moment(`${popupYear}-${popupMonth + 1}-${day}`, 'YYYY-MM-DD');
                const selectedWeekStart = moment(`${selectedYear} ${currentWeekNumber}`, 'YYYY W').startOf('week');
                const selectedWeekEnd = moment(`${selectedYear} ${currentWeekNumber}`, 'YYYY W').endOf('week');
                
                const isToday = currentDate.isSame(moment(), 'day');
                const isSelectedWeek = currentDate.isBetween(selectedWeekStart, selectedWeekEnd, 'day', '[]');
                const isFirstOfWeek = isSelectedWeek && currentDate.weekday() === 0;
                const isLastOfWeek = isSelectedWeek && currentDate.weekday() === 6;
                const isFirstOfMonth = currentDate.date() === 1;
                const isLastOfMonth = currentDate.date() === currentDate.daysInMonth();
                
                return (
                  <div
                    key={day}
                    className={cn("size-[50px] flex items-center justify-center text-center cursor-pointer  hover:bg-black/[0.04] relative", 
                      (isFirstOfWeek || isFirstOfMonth) && "rounded-l-[10px]",
                      (isLastOfWeek || isLastOfMonth) && "rounded-r-[10px]",
                      isSelectedWeek ? "bg-black/[0.02]" : 'rounded-[10px]',
                      isToday && "before:bg-black/5   before:size-[35px] before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-[12px] before:absolute"
                     )}
                    onClick={() => handleDaySelect(day)}
                  >
                    {day}
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end mt-4">
              <div
                onClick={() => setIsPopupOpen(false)}
                className="h-[30px] rounded-[12px] atna-border !bg-black text-wh2 font-medium text-[13px] px-[20px] flex items-center cursor-pointer hover:underline"
              >
                Fermer
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CrmAgendaView;
