import React, { useEffect, useRef, useState, Suspense, useMemo } from 'react';
import { cn } from '../../utils/cn';
import useScreenWidth from '../../utils/hooks/useScreenWidth';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';
import { AlertsProvider } from '../../utils/hooks/useAlert';
import Alerts from './components/alerts';
import { useParams } from 'react-router-dom';
import CrmAgendaView from './views/calendar/page';
import CrmParametresView from './views/parametres/page';
import { useSidebar } from '../../utils/hooks/useSideBar';
import { BlurProvider, useBlur } from '../../utils/hooks/useBlurBackground';

// Lazy load des composants
const CrmMenuView = React.lazy(() => import('./views/menu/page'));
const CrmSearchView = React.lazy(() => import('./views/recherche/page'));
const CrmTiersView = React.lazy(() => import('./views/tiers/page'));

const CrmView = () => {
  const windowWidthSize = useScreenWidth();
  const topBarNameProps = useRef();
  const [currentTiersRaisonSocial, setCurrentTiersRaisonSocial] = useState(null);
  const { idTiers } = useParams();
  const {isBlurred} = useBlur()
  const [viewKey, setViewKey] = useState(() => Date.now())


  const { sidebarWidth, setSidebarWidth, isResizing, setIsResizing, cursorTop, setCursorTop, navLocked, setNavLocked } = useSidebar();

  const pageType = !idTiers || (idTiers === "team") || (idTiers === "equipe") ? "menu" :  idTiers === "stats" ? "stats" : idTiers === "dashboard" ? "stats" : idTiers === "dossiers" ? "dossiersComm" : idTiers === "search" ? "search" : idTiers === "agenda" ? "agenda" : idTiers === "calendrier" ? "calendrier" : idTiers === "parametres" ? "parametres" : "tiers";

  useEffect(() => {
    if (windowWidthSize > 800) setNavLocked(false);
  }, [windowWidthSize, setNavLocked]);


    const typePage = useMemo(() => {
      let returnedType;
      if(!idTiers) returnedType = "menu"
      else if(idTiers === "equipe") returnedType = "menu"
      else if(idTiers === "recherche") returnedType = "search"
      else if(idTiers === "search") returnedType = "search"
      else if(idTiers === "dashboard") returnedType = "dashboard"
      else if(idTiers === "parametres") returnedType = "parametres"
      else if(idTiers === "dossiers") returnedType = "dossiersComm"
      else if(idTiers === "calendrier") returnedType = "calendrier"
      else if(!isNaN(idTiers)) returnedType = "tiers"
      else  returnedType = idTiers
      return returnedType;
    })
         

  return (
    <div className='atna-crm-inner'>
      <div className="flex-[1_1_0%] flex relative flex-row h-full">
        <Sidebar 
          typePage={typePage} 
          windowWidthSize={windowWidthSize} 
          viewKey={viewKey}
          setViewKey={setViewKey}
        />
        <div 
          className={cn("flex flex-col flex-[1_1_0%] h-[100vh] fixed atna-main-content")} 
          style={{
            width: navLocked ? '100vw' : `calc(100vw - ${sidebarWidth}px)`,
            left: navLocked ? '0px' : `${sidebarWidth}px`,
          }}
        >
          <Topbar 
          typePage={typePage} 
            topBarNameProps={topBarNameProps} 
            navLocked={navLocked} 
            setNavLocked={setNavLocked} 
            pageType={pageType} 
          />


        

          <main key={viewKey} className="atna-view-inner">

          {/* <div
              className={`fixed inset-0 bg-white/50 backdrop-blur-[4px] z-[10] transition-opacity duration-300 pointer-events-none ${
              isBlurred ? "opacity-100" : "opacity-0"
           }`}
         /> */}


            <div className="py-[0px] flex flex-1 flex-col h-full">
              <Suspense fallback={<></>}>
                {(pageType === "menu" || pageType === "stats") ? (
                  <AlertsProvider>
                    <Alerts />
                  <CrmMenuView />
                  </AlertsProvider>
                ) : (pageType === "search"||idTiers === "recherche") ? (
                  <AlertsProvider>
                    <Alerts />
                  <CrmSearchView />
                  </AlertsProvider>
                ) : pageType === "parametres" ? (
                  <AlertsProvider>
                    <Alerts />
                  <CrmParametresView />
                  </AlertsProvider>
                ) : (pageType === "agenda"||pageType === "calendrier") ? (
                  <AlertsProvider>
                    <Alerts />
                  <CrmAgendaView />
                  </AlertsProvider>
                ) : (
                  <AlertsProvider>
                    <Alerts />
                    <CrmTiersView
                      topBarNameProps={topBarNameProps}
                      currentTiersRaisonSocial={currentTiersRaisonSocial}
                      setCurrentTiersRaisonSocial={setCurrentTiersRaisonSocial}
                    />
                  </AlertsProvider>
                )}
              </Suspense>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default CrmView;
