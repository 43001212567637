import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from "react";
import { TbClock } from "react-icons/tb";
import { cn } from '../../utils/cn';

const HourInput = ({ className, inpClassName, object, setObject, objValue, validation, max, min, title, readOnly, required = false }) => {
    const [state, setState] = useState({
      value: '',
      isWrong: false,
      errMsg: ''
    });
    const inputRef = useRef(null);
  
    useEffect(() => {
      if (object[objValue] !== undefined) {
        setState((prev) => ({
          ...prev,
          value: object[objValue] ? object[objValue] : ''
        }));
      }
    }, [object, objValue]);
  
    const updateObject = useCallback(
      (newVl) => {
        let newValue = newVl.trim();
        
        // Si l'input n'est pas requis et qu'il n'y a pas de valeur, on le met à null
        if (!required && !newValue) {
          setObject((prev) => ({
            ...prev,
            [objValue]: null
          }));
          setState((prevState) => ({
            ...prevState,
            value: '',
            isWrong: false,
            errMsg: ''
          }));
          return;
        }

        if (validation) {
          const resValidation = validation(newValue);
          if (resValidation.err) {
            setState((prevState) => ({
              ...prevState,
              isWrong: true,
              errMsg: resValidation.message
            }));
            return;
          }
        }
  
        if (newValue && !moment(newValue, 'HH:mm', true).isValid()) {
          setState((prevState) => ({
            ...prevState,
            isWrong: true,
            errMsg: 'Heure non valide, format HH:mm requis.'
          }));
          return;
        }
  
        const newValueMoment = moment(newValue, 'HH:mm');
  
        if (min && newValueMoment.isBefore(moment(min, 'HH:mm'))) {
          setState((prevState) => ({
            ...prevState,
            isWrong: true,
            errMsg: `L'heure doit être après ${moment(min, 'HH:mm').format('HH:mm')}`
          }));
          return;
        }
  
        if (max && newValueMoment.isAfter(moment(max, 'HH:mm'))) {
          setState((prevState) => ({
            ...prevState,
            isWrong: true,
            errMsg: `L'heure doit être avant ${moment(max, 'HH:mm').format('HH:mm')}`
          }));
          return;
        }
  
        setState((prevState) => ({
          ...prevState,
          value: newValue,
          isWrong: false,
          errMsg: ''
        }));
  
        setObject((prev) => ({
          ...prev,
          [objValue]: newValue
        }));
      },
      [validation, objValue, setObject, min, max, required]
    );
  
    return (
        <div className={`relative group atna-select-options-container-${objValue}`} >
          <div
            title={title}
            className={cn(
              'flex items-center w-[170px] rounded-[6px] bg-wh h-[26px] shrink-0 grow-0 relative',
              className,
              readOnly && "select-none cursor-default"
            )}
          >
            <input
              value={state.value || ''}
              readOnly={readOnly ? true : false}
              onChange={(e) => {
                if(readOnly) return;
                const value = e.target.value.replaceAll('-', ':').replaceAll('/', ':');
                setState((prevState) => ({
                  ...prevState,
                  value: value
                }));
              }}
              onBlur={(e) => {
                updateObject(e.target.value);
              }}
              className={cn(
                'h-full w-full border-none outline-none bg-transparent px-[10px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide',
                inpClassName
              )}
              type="text"
            />
            <div className="p-[3px] relative">
              <div
                onClick={() => {
                  if(readOnly) return;
                  inputRef.current.showPicker();
                }}
                className="h-full w-[30px] rounded-[10px] flex items-center justify-center text-base cursor-pointer"
              >
                <TbClock className='text-[15px] text-black/80'/>
              </div>
              <input
                value={state.value ? state.value : ''}
                onChange={(e) => {
                  if(readOnly) return;
                  const time = e.target.value ? moment(e.target.value, 'HH:mm').format('HH:mm') : '';
                  updateObject(time);
                }}
                type="time"
                className="absolute w-[0px] h-[0px] opacity-0 pointer-events-none"
                ref={inputRef}
                max={max ? moment(max, 'HH:mm').format('HH:mm') : undefined}
                min={min ? moment(min, 'HH:mm').format('HH:mm') : undefined}
              />
            </div>
            <div
              className={cn(
                "absolute top-[calc(100%_+_5px)] left-0 min-w-[200px] max-w-[200px] min-h-[25px] z-[99] bg-error atna-error-drop rounded-[8px] px-[10px] font-medium py-[3px] text-wh opacity-0 transition-opacity duration-100 text-xs pointer-events-none", state.isWrong && "opacity-100 pointer-events-auto"
              )}
            >
              {state.errMsg}
            </div>
          </div>
      </div>
    );
  };

export default HourInput;
