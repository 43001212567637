import React, { useEffect, useState } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { fetchSessions } from '../../../../utils/atna';
import moment from "moment"
import "moment/locale/fr"
import {useAlerts} from "../../../../utils/hooks/useAlert"
import LogoutUserMenu from './components/logoutUserMenu';
import BlockUserMenu from './components/blockUserMenu';

const AdminMenuView = () => {

    const [sessions, setSessions] = useState([])
    const [loading, setLoading] = useState(true)
    const { addAlert } = useAlerts()
    const [choosedSession, setChoosedSession] = useState(null)
    const [logoutMenuVisible, setLogoutMenuVisible] = useState(false)
    const [blockMenuVisible, setBlockMenuVisible] = useState(false)

    const fetchData = async() => {
        const resApi = await fetchSessions()
        console.log(resApi)
        if(resApi.status === 200) {
            setSessions(() => {
                return [...resApi.data.map(session => {
                    return {...session, data: JSON.parse(session.data)}
                })]
            })
            setChoosedSession(() => null)
        } else {
            addAlert(resApi.data || 'Une erreur s\'est produite.')
        }
        setLoading(() => false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        console.log(sessions)
    }, [sessions])

    


    return (
        <div className='w-full h-full flex flex-col items-center px-[20px] max-sm:px-[0px]'>

            {
                logoutMenuVisible && <LogoutUserMenu menuVisible={logoutMenuVisible} setMenuVisible={setLogoutMenuVisible} session={choosedSession} fetchSessions={fetchData}/>
            }
             {
                blockMenuVisible && <BlockUserMenu menuVisible={blockMenuVisible} setMenuVisible={setBlockMenuVisible} session={choosedSession} fetchSessions={fetchData}/>
            }

            <div className="flex items-center gap-x-[10px] w-full px-[0px] mt-[0px] py-[10px] rounded-[4px]">
                <div className="w-[4px] h-[25px] rounded-full bg-black/5"></div>
                <h1 className='max-sm:font-semibold font-semibold text-[16px] leading-[15px] text-black/90'>
                    Liste des sessions
                </h1>
            </div>
        

        {
        loading ?
        <div className='w-full flex justify-center text-[13px] text-[rgb(133,132,128)] font-normal mt-[50px]'>
        <BiLoaderAlt className='animate-spin'/>
         </div>
        :
        sessions.length > 0 ? (
            <div className='w-full h-full flex justify-center mt-[20px] mb-[50px]'>
                <div className="atna-details-table-inner">
                <table className='atna-details-table'>
        <thead>
            <tr className='atna-hd-table'>
                <th className='w-[100px]'>Identifiant</th>
                <th className='w-[100px]'>Utilisateur</th>
                <th className='w-[100px]'>Connexion le</th>
                <th className='w-[100px]'>Expire le</th>
                <th className='w-[100px]'>Adresse IP</th>
                <th className='w-[100px]'>Région</th>
                <th className='w-[100px]'>Navigateur</th>
                <th className='w-[100px]'>Appareil</th>
            </tr>
        </thead>
        <tbody>
           
               {
                sessions.map((session, sessionInd) => {
                    return (
                        <SessionUserRow key={"listSessions" + sessionInd} session={session} sessionInd={sessionInd} setChoosedSession={setChoosedSession} setLogoutMenuVisible={setLogoutMenuVisible} setBlockMenuVisible={setBlockMenuVisible}/>
                    )
                })
               }
            
        </tbody>
    </table>
                </div>
            </div>
        ) : (
            <div className='w-full flex justify-center text-[13px] text-[rgb(133,132,128)] font-normal mt-[50px]'>
                Aucune session.
            </div>
        )}
    </div>
    );
}


const SessionUserRow = ({session, sessionInd, setLogoutMenuVisible, setBlockMenuVisible, setChoosedSession, choosedSession}) => {


    async function handleLogout() {
        setChoosedSession(() => {
            return {...session}
        })
        setLogoutMenuVisible(() => true)
    }

    async function handleBlockUser() {
        setChoosedSession(() => {
            return {...session}
        })
        setBlockMenuVisible(() => true)
    }


    return (
        <tr
        key={"listSessions" + sessionInd} className='cursor-default select-text hover:bg-black/[0.01]'
        role='button' tabIndex={0}>
        <th className='select-text'>{session?.data.user?.id || 'Inconnu'}</th>
        <th className='select-text'>{session?.data.user?.personnel?.nom || 'Inconnu'} {session?.data.user?.personnel?.prenom || 'Inconnu'}</th>
        <th  className='select-text'>{moment((session.expires*1000)  - (1 * 1000 * 60 * 90)).locale('fr').format('DD/MM à HH:mm:ss')}</th>
        <th  className='select-text'>{moment((session.expires*1000)).locale('fr').format('DD/MM à HH:mm:ss')}</th>
        <th className='select-text hover:underline'
        onClick={() => {
            if(session.data?.ip) window.open(`https://tools.keycdn.com/geo?host=${session.data?.ip}`, '_blank');
        }}
        >{session.data?.ip || 'Inconnue'}</th>
        <th  className='select-text'>{session.data?.geo?.city || 'Inconnue'}</th>
        <th  className='select-text'>{session.data?.device?.name || 'Inconnue'}</th>
        <th  className='select-text'>{session.data?.device?.product || 'Inconnu ( pc )'}</th>
        <th
        onClick={() => {
            handleLogout()
        }}
        className='w-[60px] text-center hover:bg-black/[0.04] cursor-pointer font-medium'>
            Déconnecter
        </th>
        <th
        onClick={() => {
            handleBlockUser()
        }} className='w-[60px] text-center hover:bg-black/[0.04] cursor-pointer font-medium'>Bloquer</th>
        
    </tr>
    )
}

export default AdminMenuView;
