// AlertContext.js
import React, { createContext, useReducer, useContext, useCallback } from 'react';

// Définir l'état initial
const initialState = {
  alerts: []
};

// Réducteur pour gérer les actions sur les alertes
const alertsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ALERT':
      return {
        ...state,
        alerts: [...state.alerts.filter(e => e.message !== action.payload.message), action.payload]
      };
    case 'DELETE_ALERT':
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert.id !== action.payload.id)
      };
    default:
      return state;
  }
};

// Créer le contexte pour partager l'état global des alertes
const AlertsContext = createContext();

export const AlertsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(alertsReducer, initialState);

  return (
    <AlertsContext.Provider value={{ state, dispatch }}>
      {children}
    </AlertsContext.Provider>
  );
};

// Hook personnalisé pour gérer les alertes
export const useAlerts = () => {
  const { state, dispatch } = useContext(AlertsContext);

  // Ajouter une nouvelle alerte
  const addAlert = useCallback((message, type, deletion) => {
   //if(message === "Non authentifié") window.location.reload()
    const messageExists = state.alerts.some(alert => alert.message === message);
    if(messageExists) return;
    const id = Date.now(); // Utiliser un timestamp pour un identifiant unique
    dispatch({
      type: 'ADD_ALERT',
      payload: { id, message, type }
    });

    if(deletion){
      setTimeout(() => {
      dispatch({
        type: 'DELETE_ALERT',
        payload: { id }
      });
    }, deletion);
  }
  }, [dispatch]);

  // Supprimer une alerte manuellement
  const deleteAlert = useCallback((id) => {
    dispatch({
      type: 'DELETE_ALERT',
      payload: { id }
    });
  }, [dispatch]);

  // Récupérer toutes les alertes
  const getAlerts = useCallback(() => {
    return state.alerts;
  }, [state.alerts]);

  return {
    alerts: state.alerts,
    addAlert,
    deleteAlert,
    getAlerts
  };
};
