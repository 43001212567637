import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///

export const fetchUserToDo = async (data = {}) => {
    try {
      const defaultData = {};
    
      const requestData = { ...defaultData, ...data };


      const resApi = await axios(`${apiBaseUrl}/api/user/todo`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

