import React, { createContext, useContext, useEffect, useState } from 'react';

// Création du contexte
const SidebarContext = createContext();

// Hook personnalisé pour utiliser le contexte
export const useSidebar = () => {
  return useContext(SidebarContext);
};

// Fournisseur du contexte
export const SidebarProvider = ({ children }) => {
  const [sidebarWidth, setSidebarWidth] = useState(248); // Initial width of the sidebar
  const [isResizing, setIsResizing] = useState(false);
  const [cursorTop, setCursorTop] = useState(0);
  const [navLocked, setNavLocked] = useState(() => {
    const storedValue = localStorage.getItem('navLocked');
    return JSON.parse(storedValue||"false")
  });

  useEffect(() => {
    localStorage.setItem('navLocked',JSON.stringify(navLocked));
  }, [navLocked]);


  return (
    <SidebarContext.Provider
      value={{
        sidebarWidth,
        setSidebarWidth,
        isResizing,
        setIsResizing,
        cursorTop,
        setCursorTop,
        navLocked,
        setNavLocked,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};