import React, { useEffect, useState, useMemo, useRef } from 'react';
import { cn } from '../../utils/cn';

const InputSelect = ({ object, setObject, value, dataTitle, placeholder, inpClassName, validation, type, required, options, showValue, opClassName, className, menuClassName, authorizeOther = false }) => {
    const [isInvalid, setIsInvalid] = useState(false);
    const [invalidMessage, setInvalidMessage] = useState("");
    const [inpValue, setInpValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null); // Ref pour le conteneur d'options

    const [baseOptions, setOptions] = useState([])


    useEffect(() => {
        setOptions(() => {
            return [...options.filter(op => op.value && op.label).map((op) => {
                return {...op}
            })]
        })
    }, [options])

    // Met à jour l'input avec la valeur ou le label de l'objet initial
    useEffect(() => {
        const currentOption = baseOptions.find(op => op.value === object[value]);
        const toSet = currentOption ? (showValue ? currentOption?.value : currentOption?.label) : authorizeOther ? inpValue : ""
        setInpValue(toSet)
    }, [object, value, baseOptions, showValue]);

    // Fermer le menu déroulant si l'utilisateur clique en dehors du composant
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // Filtrer les options en fonction de la saisie
    const filteredOptions = useMemo(() => {
        const searchValue = (inpValue||"").toString().toLowerCase().trim();
    
        // Pré-calculer la correspondance exacte une seule fois
        const exactMatch = baseOptions.some(eCn => {
           return  eCn.value.toString().toLowerCase() === searchValue || eCn.label.toString().toLowerCase() === searchValue
         });
    
        return baseOptions
            .filter(cdNf => {
                const valueStr = cdNf.value.toString().toLowerCase();
                const labelStr = cdNf.label.toString().toLowerCase();
    
                // Si une correspondance exacte existe, inclure toutes les options
                if (exactMatch) {
                    return true;
                }
    
                // Sinon, filtrer selon showValue
                return showValue 
                    ? valueStr.includes(searchValue)
                    : labelStr.includes(searchValue);
            })
            .sort((a, b) => {
                const aMatches = a.value.toString().toLowerCase() === searchValue || a.label.toString().toLowerCase() === searchValue;
                const bMatches = b.value.toString().toLowerCase() === searchValue || b.label.toString().toLowerCase() === searchValue;
    
                // Prioriser la correspondance exacte
                if (aMatches) return -1;
                if (bMatches) return 1;
                return 0;
            })
            .slice(0, 20);  // Limiter à 20 résultats
    }, [baseOptions, inpValue, showValue]);
    

    // Fonction appelée lors de la sélection d'une option dans la liste
    const handleOptionClick = (option) => {
        setInpValue(showValue ? option.value : option.label);
        setObject((prev) => ({ ...prev, [value]: option.value }));
        setIsOpen(() => false);
    };

    // Validation et mise à jour de la valeur lors de la sortie du champ (onBlur)
    const handleBlur = () => {
        const trimmedValue = inpValue ? inpValue.toString().trim() : "";
        setIsOpen(() => false);
        if (required && !trimmedValue) {
            // Si le champ est requis et vide
            setIsInvalid(true);
            setInvalidMessage("Champs requis");
            setObject((prev) => ({ ...prev, [value]: null }));
        } else if (!trimmedValue) {
            // Si le champ n'est pas requis et vide, on accepte le champ vide
            setIsInvalid(false);
            setInvalidMessage("");
            setObject((prev) => ({ ...prev, [value]: null }));
        } else {
            const selectedOption = baseOptions.find(option => {
                return showValue ? option.value === trimmedValue : option.label === trimmedValue;
            });

            if (!selectedOption) {
                if(authorizeOther) {
                    setIsInvalid(false);
                    setInvalidMessage("");
                    setObject((prev) => ({ ...prev, [value]: trimmedValue }));
                } else { 
                setIsInvalid(true);
                setInvalidMessage("Option invalide");
                }
            } else {
                setIsInvalid(false);
                setInvalidMessage("");
                setObject((prev) => ({ ...prev, [value]: selectedOption.value }));
            }
        }
    };

    return (
        <div className={`relative group atna-select-options-container-${value}`} ref={containerRef}>
            <div data-title={dataTitle} className={cn("flex items-center rounded-[6px] bg-wh h-[26px] shrink-0 grow-0 relative", className)}>
                <div className={cn("absolute top-[calc(100%_+_5px)] left-0 min-w-[200px] max-w-[200px] min-h-[25px] z-[99] bg-error atna-error-drop rounded-[8px] px-[10px] font-medium py-[3px] text-wh opacity-0 transition-opacity duration-100 text-xs pointer-events-none", isInvalid && "opacity-100 pointer-events-auto")}>
                    {invalidMessage}
                </div>
                <input
                    name={value}
                    placeholder={placeholder}
                    value={inpValue}
                    autoComplete='off'
                    spellCheck="false"
                    onChange={(e) => setInpValue(e.target.value)}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => handleBlur()}
                    type="text"
                    className={cn('h-full w-full border-none outline-none bg-transparent px-[10px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide', inpClassName)}
                />
                <div
                className="ml-[4px] -mb-[3px] w-[8px] h-[8px] mr-[10px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.5)] group-focus-within:rotate-180">
                    <svg role="graphics-symbol" viewBox="0 0 15 9" width={"100%"} height={'100%'} className="chevronDownRounded">
                        <path d="M7.92188 8.65625C8.19531 8.64844 8.44531 8.54688 8.64844 8.32812L14.5859 2.25C14.7578 2.07812 14.8516 1.85938 14.8516 1.60156C14.8516 1.08594 14.4453 0.671875 13.9297 0.671875C13.6797 0.671875 13.4375 0.773438 13.2578 0.953125L7.92969 6.42969L2.58594 0.953125C2.40625 0.78125 2.17188 0.671875 1.91406 0.671875C1.39844 0.671875 0.992188 1.08594 0.992188 1.60156C0.992188 1.85938 1.08594 2.07812 1.25781 2.25L7.20312 8.32812C7.41406 8.54688 7.64844 8.65625 7.92188 8.65625Z"></path>
                    </svg>
                </div>

                {isOpen && (
                    <div className={cn("absolute min-w-[200px] w-full atna-drop-menu top-[calc(100%_+_10px)] p-[5px] opacity-100 pointer-events-auto", menuClassName)}>
                        <div className="flex flex-col gap-y-[1px] w-full overflow-y-auto overflow-x-hidden atna-drop-menu-scroll min-h-[60px] max-h-[120px]">
                            {filteredOptions.map((op, opIndex) => (
                                <div
                                    key={`inpOp_${opIndex}`}
                                    onMouseDown={(e) => {
                                        handleOptionClick(op);
                                    }}
                                    className={cn("hover:bg-[rgba(0,0,0,0.05)] px-[8px] text-[12px] rounded-[5px] cursor-pointer h-[22px] grow-0 shrink-0 flex items-center w-full", opClassName)}
                                >
                                    <span className='overflow-hidden w-full text-ellipsis text-nowrap'>{showValue ? op.value : op.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputSelect;