import React, { useEffect } from 'react';
import { useAlerts } from '../../../utils/hooks/useAlert';
import { IoClose } from 'react-icons/io5';
import { cn } from '../../../utils/cn';
import { useLocation } from 'react-router-dom';

const Alerts = () => {

    const { alerts, deleteAlert } = useAlerts();

    const location = useLocation()

    useEffect(() => {

        alerts.forEach((alert) => {
            deleteAlert(alert.id)
        })

    }, [location.pathname])
    return (
        <div className='fixed bottom-[10px] right-[10px] z-[190] flex flex-col gap-y-[5px] max-w-[calc(100vw_-_20px)]'>
             {
                alerts.map((alrt) => {
                    return (
                        <div 
                        className={cn('atna-drop-menu px-[20px] h-[30px] min-w-[120px] flex justify-between items-center pointer-events-auto   gap-x-[10px] gap-y-[5px]', alrt.type === "success" ? "!bg-green !text-black" : "!bg-error !text-wh")}
                        key={`alrMp_${alrt.id}`}>
                            <h2 className='text-[12px] font-medium  leading-[15px]'>
                                {alrt.message}
                            </h2>
                            <div role='button' 
                            onClick={() => {
                                deleteAlert(alrt.id)
                            }}
                            tabIndex={0} className='flex items-center ring-3 ring-transparent cursor-pointer !pointer-events-auto'>
                                <IoClose className='text-[15px] '/>
                            </div>
                        </div>
                    )
                })
             }
        </div>
    );
}

export default Alerts;
