import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///



  export const fetchDashboardTiersAll = async (data = {}, signal) => {
    try {
      const defaultData = {}
      const requestData = { ...defaultData, ...data };
  
      const resApi = await axios(`${apiBaseUrl}/api/dashboard/tiers/all`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: { ...requestData },
        signal, // Ajout du signal pour l'annulation
      })
  
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'API.",
        };
      }
      return resApi.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return { status: "canceled", data: "Requête annulée" };
      }
      return { status: 400, data: "Erreur lors de la communication vers l'API." };
    }
  };