import React, { useEffect, useState } from 'react';
import { cn } from '../../utils/cn';

const Input = ({ object, setObject, value, baseValue, dataTitle, placeholder, inpClassName, validation, type, required, className, maxLength, realTime}) => {
    const [isInvalid, setIsInvalid] = useState(null);
    const [invalidMessage, setInvalidMessage] = useState("");
    const [inpValue, setInpValue] = useState("");

    useEffect(() => {
        setInpValue(object[value] !== undefined && object[value] !== null ? String(object[value]) : "");
    }, [object[value]]);

    useEffect(() => {
        if(realTime) {
            setObject((prev) => {
                prev[value] = inpValue;
                return {
                    ...prev
                };
            });
        }
    }, [inpValue]);

    const handleInputChange = (e) => {
        let newValue = e.target.value;

        if (maxLength) newValue = newValue.slice(0, maxLength);

        if (type === "number") {
            newValue = newValue.replace(/\D/g, ''); // Only digits
        } else if (type === "float") {
            newValue = newValue.replace(',', '.').replace(/[^0-9.]/g, ''); // Replace commas with dots and remove non-numeric characters except dot
        }

        setInpValue(newValue);
    };

    const handleBlur = async(e) => {
        const isValid = await validation(e.target.value);
        if (required && !e.target.value) {
            setIsInvalid(true);
            setInvalidMessage("Champs requis");
        } else if (!isValid.valid) {
            setIsInvalid(true);
            setInvalidMessage(isValid.data);
        } else {
            setIsInvalid(false);
            setInvalidMessage(null);

            let toSetValue = e.target.value.trim();

            if (type === "number") {
                toSetValue = toSetValue === "" ? null : (isNaN(parseInt(toSetValue, 10)) ? null : parseInt(toSetValue, 10));
            } else if (type === "float") {
                toSetValue = toSetValue === "" ? null : (isNaN(parseFloat(toSetValue)) ? null : parseFloat(toSetValue));
            }

            // Special handling to ensure 0 is kept as a valid value
            if (toSetValue === 0 || toSetValue === 0.0) {
                setObject((prev) => {
                    prev[value] = toSetValue;
                    return {
                        ...prev
                    };
                });
            } else {
                setObject((prev) => {
                    prev[value] = toSetValue;
                    return {
                        ...prev
                    };
                });
            }
        }
    };

    return (
        <div className={`relative group atna-select-options-container-${value}`} >
            <div data-title={dataTitle}
                className={cn("flex items-center w-[170px] rounded-[6px]  bg-wh h-[26px] shrink-0 grow-0 relative", (object[value] !== baseValue ? baseValue : object[value]) && 'bg-gray-700', className
                )}>
                <div className={cn("absolute top-[calc(100%_+_5px)] left-0 min-w-[200px] max-w-[200px] min-h-[25px] z-[99] bg-error atna-error-drop rounded-[8px] px-[10px] font-medium py-[3px] text-wh opacity-0 transition-opacity duration-100 text-xs pointer-events-none", isInvalid && "opacity-100 pointer-events-auto")}>{invalidMessage}</div>
                <input 
                    name={value} 
                    placeholder={placeholder} 
                    value={inpValue !== 0 && !inpValue ? "" : inpValue} 
                    autoComplete='off'
                    spellCheck="false"
                    maxLength={maxLength}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    type="text" 
                    className={cn('h-full w-full border-none outline-none bg-transparent px-[10px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide', inpClassName)}
                />
            </div>
        </div>
    );
}

export default Input;
