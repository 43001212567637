import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Projets -> Opportunites -> fiche tiers


// Récupérer la liste des projets d'une opportunité
export const fetchTiersProjets = async ({ id, idOpportunite }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching opportunities ${id} project ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des projets pour cette opportunité.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  // Récupérer un projet par son idProjet
export const fetchTiersOneProjets = async ({ id, idOpportunite, idProjet }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/details/${idProjet}`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching opportunities ${id} project ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération du projet pour cet identifiant.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

// Créer un projet pour une opportunité
export const createTiersProjets = async ({ id, idOpportunite, data }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/create`,
        {
          method: "POST",
          withCredentials: true,
          data: {...data}
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Creating opportunitie ${id} project ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la création du projet.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };
  


// Modifier un projet
export const editTiersProjets = async ({ id, idOpportunite, idProjet, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/update/${idProjet}`,
      {
        method: "PUT",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Editing project for opportunitie ${id}/${idOpportunite} ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la modification du projet .",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};




// Supprimer un projet
export const deleteTiersProjets = async ({ id, idOpportunite, idProjet }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/delete/${idProjet}`,
      {
        method: "DELETE",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Deleting project for opportunitie ${id}/${idOpportunite} ${error}`);
      return { status: 400, data: "Erreur lors de la suppression du projet." };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// CATALOGUE -> Projets -> Opportunites -> fiche tiers

// Récupérer le catalogue
export const fetchCatalogue = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/catalogue`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching catalogue ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération du catalogue.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// LEASER -> Projets -> Opportunites -> fiche tiers

// Récupérer les données du leaser
export const fetchLeaser = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/leasers`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching leasers ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des leasers.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// BaremeCommercial -> Projets -> Opportunites -> fiche tiers

// Récupérer le bareme commercial
export const fetchBaremeCommercial = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/baremeCommercial`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching leasers ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des baremes commercial.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// BaremeRéel -> Projets -> Opportunites -> fiche tiers

// Récupérer le bareme reel
export const fetchBaremeReel = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/baremeReel`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching leasers ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des baremes réels.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// typeConquete -> Projets -> Opportunites -> fiche tiers

// Récupérer les type de conquete
export const fetchTypeConquete = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/typeConquete`, {
      method: "GET",
      withCredentials: true,
    });

    if (!resApi || resApi.status !== 200) {
      console.error("Statut de la réponse API non 200:", resApi.status);
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 

    // Vérifier si resData est un tableau
    if (Array.isArray(resData)) {
      return { status: 200, data: resData };
    }
    
    // Vérifier si resData est un objet enveloppant avec un champ data
    if (resData && resData.status === 200 && Array.isArray(resData.data)) {
      return { status: 200, data: resData.data };
    }
    
    // Vérifier si resData contient directement les champs type et coeffConquete
    if (resData && typeof resData === "object") {
      const expectedKeys = ["type", "coeffConquete"];
      const hasKeys = expectedKeys.every(key => key in resData);
      if (hasKeys) {
        return { status: 200, data: [resData] };
      }
    }
    
    // Sinon, retourner un tableau vide avec un statut approprié
    console.error("Format de données invalide reçu de l'API.");
    return { status: 400, data: "Données invalides reçues de l'API." };
    
  } catch (error) {
    console.error(`[API ERROR] : Fetching typeConquete ${error}`);
    return { status: 400, data: "Erreur lors de la récupération des types de conquête." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// typeContrat -> Projets -> Opportunites -> fiche tiers

// Récupérer les types de contrats
export const fetchTypeContrat = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/typeContrat`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching types contrats ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des types de contrats.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// typesVentes -> Projets -> Opportunites -> fiche tiers

// Récupérer les types de ventes
export const fetchTypesVentes = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/typesVentes`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching types ventes ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des types de ventes.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// dureeContrats -> Projets -> Opportunites -> fiche tiers

// Récupérer les durées de contrat
export const fetchDureeContrats = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/dureeContrats`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching durée contrats ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des durées de contrat.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};


/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Lignes de projet -> Projets -> Opportunites -> fiche tiers

// Récupérer les lignes d'un projet
export const fetchTiersProjetsLigne = async ({ id, idOpportunite, idProjet }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/projetsLignes`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching opportunities ${idOpportunite} project ${idProjet} ${error} project line.`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des lignes du projet pour cet identifiant.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Créer une ligne pour un projet
export const createTiersProjetsLigne = async ({ id, idOpportunite, idProjet, data }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/projetsLignes/create`,
      {
        method: "POST",
        withCredentials: true,
        data: {...data}
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Creating opportunitie ${idOpportunite} project ${idProjet} line ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la création du projet.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};



// Modifier une ou plusieurs lignes de projet
export const editTiersProjetsLigne = async ({ id, idOpportunite, idProjet, idProjetLigne, data = {} }) => {
try {
  const resApi = await axios(
    `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/projetsLignes/update/${idProjetLigne}`,
    {
      method: "PUT",
      withCredentials: true,
      data,
    }
  ).catch((error) => {
    console.error(`[API ERROR] : Editing opportunitie ${idOpportunite} project ${idProjet} line ${idProjetLigne} ${error}`);
    return {
      status: 400,
      data: "Erreur lors de la modification du projet .",
    };
  });
  if (!resApi || resApi.status !== 200) {
    return {
      status: 400,
      data: "Erreur lors de la communication vers l'api.",
    };
  }
  const resData = await resApi.data;
  return resData;
} catch (error) {
  return { status: 400, data: "Erreur lors de la communication vers l'api." };
}
};




// Supprimer une ligne de projet
export const deleteTiersProjetsLigne = async ({ id, idOpportunite, idProjet, idProjetLigne }) => {
try {
  const resApi = await axios(
    `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/projetsLignes/delete/${idProjetLigne}`,
    {
      method: "DELETE",
      withCredentials: true,
    }
  ).catch((error) => {
    console.error(`[API ERROR] : Deleting opportunitie ${idOpportunite} project ${idProjet} line ${idProjetLigne} ${error}`);
    return { status: 400, data: "Erreur lors de la suppression du projet." };
  });
  if (!resApi || resApi.status !== 200) {
    return {
      status: 400,
      data: "Erreur lors de la communication vers l'api.",
    };
  }
  const resData = await resApi.data;
  return resData;
} catch (error) {
  return { status: 400, data: "Erreur lors de la communication vers l'api." };
}
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Lignes de valeur résiduelle d'un projet -> Projets -> Opportunites -> fiche tiers

// Récupérer les lignes VR d'un projet
export const fetchTiersProjetsVRLigne = async ({ id, idOpportunite, idProjet }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/vrLignes`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching opportunities ${idOpportunite} project ${idProjet} ${error} project VR-line.`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des lignes du projet pour cet identifiant.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Créer une ligne VR pour un projet
export const createTiersProjetsVRLigne = async ({ id, idOpportunite, idProjet, data }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/vrLignes/create`,
      {
        method: "POST",
        withCredentials: true,
        data: {...data}
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Creating opportunitie ${idOpportunite} project ${idProjet} VR-line ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la création du projet.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};



// Modifier une ou plusieurs lignes VR de projet
export const editTiersProjetsVRLigne = async ({ id, idOpportunite, idProjet, idVRLigne, data = {} }) => {
try {
  const resApi = await axios(
    `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/vrLignes/update/${idVRLigne}`,
    {
      method: "PUT",
      withCredentials: true,
      data,
    }
  ).catch((error) => {
    console.error(`[API ERROR] : Editing opportunitie ${idOpportunite} project ${idProjet} VR-line ${idVRLigne} ${error}`);
    return {
      status: 400,
      data: "Erreur lors de la modification du projet .",
    };
  });
  if (!resApi || resApi.status !== 200) {
    return {
      status: 400,
      data: "Erreur lors de la communication vers l'api.",
    };
  }
  const resData = await resApi.data;
  return resData;
} catch (error) {
  return { status: 400, data: "Erreur lors de la communication vers l'api." };
}
};




// Supprimer une ligne VR de projet
export const deleteTiersProjetsVRLigne = async ({ id, idOpportunite, idProjet, idVRLigne }) => {
try {
  const resApi = await axios(
    `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/${idProjet}/vrLignes/delete/${idVRLigne}`,
    {
      method: "DELETE",
      withCredentials: true,
    }
  ).catch((error) => {
    console.error(`[API ERROR] : Deleting opportunitie ${idOpportunite} project ${idProjet} VR-line ${idVRLigne} ${error}`);
    return { status: 400, data: "Erreur lors de la suppression du projet." };
  });
  if (!resApi || resApi.status !== 200) {
    return {
      status: 400,
      data: "Erreur lors de la communication vers l'api.",
    };
  }
  const resData = await resApi.data;
  return resData;
} catch (error) {
  return { status: 400, data: "Erreur lors de la communication vers l'api." };
}
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Segment pour un projet Repro -> Projets -> Opportunites -> fiche tiers

// Récupérer les segments
export const fetchSegment = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/segment`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching segments ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des segments.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Règles Niveau Page pour un projet Repro -> Projets -> Opportunites -> fiche tiers

// Récupérer les Niveaux Règle Page
export const fetchNiveauReglePage = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/niveauReglePage`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching niveauReglePage ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération des Niveaux Règle Page.",
      };
    });

    if (!resApi || resApi.status !== 200) {
      return { status: 400, data: "Erreur lors de la communication avec l'API." };
    }
    
    const resData = resApi.data; 
    // Ici, resData est un objet { status: 200, code: "success", data: [...] }
    if (resData.status === 200 && Array.isArray(resData.data)) {
      return resData.data; // Retourne le tableau
    }
    
    // Sinon on retourne un tableau vide
    return [];
    
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};