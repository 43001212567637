import React, { useEffect, useState } from 'react';
import { cn } from '../../utils/cn';

const Input = ({ object, setObject, value, baseValue, dataTitle, placeholder, inpClassName, type, required, className, maxLength, realTime,
         enterToContinue = false,
        validation = () => ({valid: true, data: null})
    }) => {
    const [isInvalid, setIsInvalid] = useState(null);
    const [invalidMessage, setInvalidMessage] = useState("");
    const [inpValue, setInpValue] = useState(() => {
        return object[value] !== undefined && object[value] !== null ? String(object[value]) : "";
    });

    // useEffect(() => {
    //     setInpValue(object[value] !== undefined && object[value] !== null ? String(object[value]) : "");
    // }, [object[value]]);

    useEffect(() => {
        if(realTime) {
            setObject((prev) => {
                prev[value] = inpValue;
                return {
                    ...prev
                };
            });
        }
    }, [inpValue]);

    const handleInputChange = (e) => {
        let newValue = e.target.value;
    
        if (maxLength) newValue = newValue.slice(0, maxLength);
    
        if (type === "number") {
            // Autoriser uniquement les chiffres et le signe moins au début
            newValue = newValue.replace(/[^\d-]/g, '');
            
            // S'assurer que le signe moins n'apparaît qu'au début
            if (newValue.indexOf('-') !== -1 && newValue.indexOf('-') !== 0) {
                newValue = newValue.replace(/-/g, '');
            }
            
            // Empêcher plusieurs signes moins
            if ((newValue.match(/-/g) || []).length > 1) {
                newValue = '-' + newValue.replace(/-/g, '');
            }
        } else if (type === "float") {
            // Convertir "," en "." et autoriser le signe moins au début
            newValue = newValue.replace(',', '.').replace(/[^0-9.\-]/g, '');
            
            // S'assurer que le signe moins n'apparaît qu'au début
            if (newValue.indexOf('-') !== -1 && newValue.indexOf('-') !== 0) {
                newValue = newValue.replace(/-/g, '');
            }
            
            // Empêcher plusieurs signes moins
            if ((newValue.match(/-/g) || []).length > 1) {
                newValue = '-' + newValue.replace(/-/g, '');
            }
    
            // Empêcher la saisie de plusieurs points
            const dotCount = (newValue.match(/\./g) || []).length;
            if (dotCount > 1) return;
    
            // Séparer la partie entière et la partie décimale
            const [integerPart, decimalPart] = newValue.split('.');
    
            // Tronquer la partie décimale si elle dépasse 5 chiffres
            if (decimalPart && decimalPart.length > 5) {
                newValue = `${integerPart}.${decimalPart.slice(0, 5)}`;
            }
        }
    
        setInpValue(newValue);
    };
    
    const handleBlur = async(e) => {
        const isValid = await validation(e.target.value);
        if (required && !e.target.value) {
            setIsInvalid(true);
            setInvalidMessage("Champs requis");
        } else if (!isValid.valid) {
            setIsInvalid(true);
            setInvalidMessage(isValid.data);
        } else {
            setIsInvalid(false);
            setInvalidMessage(null);

            let toSetValue = e.target.value.trim();

            if (type === "number") {
                toSetValue = toSetValue === "" ? null : (isNaN(parseInt(toSetValue, 10)) ? null : parseInt(toSetValue, 10));
            } else if (type === "float") {
                toSetValue = toSetValue === "" ? null : (isNaN(parseFloat(toSetValue)) ? null : parseFloat(toSetValue));
            }

            // Special handling to ensure 0 is kept as a valid value
            if (toSetValue === 0 || toSetValue === 0.0) {
                setObject((prev) => {
                    prev[value] = toSetValue;
                    return {
                        ...prev
                    };
                });
            } else {
                setObject((prev) => {
                    prev[value] = toSetValue;
                    return {
                        ...prev
                    };
                });
            }
        }
    };

    return (
        <div className={`relative group atna-select-options-container-${value}`} >
            <div data-title={dataTitle}
                className={cn("flex items-center w-[170px] rounded-[6px]  bg-wh h-[26px] shrink-0 grow-0 relative", (object[value] !== baseValue ? baseValue : object[value]) && 'bg-gray-700', className
                )}>
               <div
          className={cn(
            "absolute top-[calc(100%_+_5px)] left-0 min-w-[200px] max-w-[200px] z-[99] bg-error backdrop-blur-sm shadow-xl  rounded-[10px] min-h-[26px] px-[10px] font-semibold py-[3px] text-white opacity-0 transition-opacity duration-100 text-[11px] pointer-events-none flex items-center", 
            (isInvalid) && "opacity-100 pointer-events-auto"
          )}
        >
          {invalidMessage}
        </div>
                <input 
                    name={value} 
                    onKeyDown={(event) => {
                        if(enterToContinue) {
                            if (event.key === "Enter") {
                                event.target?.blur(); // Déclenche onBlur
                                event.target?.focus(); // Déclenche onBlur
                              }
                        }
                    }}
                    placeholder={placeholder} 
                    value={inpValue !== 0 && !inpValue ? "" : inpValue} 
                    autoComplete='off'
                    spellCheck="false"
                    maxLength={maxLength}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    type="text" 
                    className={cn('h-full w-full border-none outline-none bg-transparent px-[10px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide', inpClassName)}
                />
            </div>
        </div>
    );
}

export default Input;
