import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { cn } from "../../../utils/cn";
import { useTabs } from "../../../utils/hooks/useTabs";
import { useSidebar } from "../../../utils/hooks/useSideBar";
import { fetchUserEventsScheduled, fetchUserToDo } from "../../../utils/atna";
import moment from "moment-timezone";
import { upperFirst } from "lodash";
import { motion } from "framer-motion";

const Topbar = React.memo(({ typePage, topBarNameProps }) => {
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);
  const [historyStack, setHistoryStack] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { idTiers, pageView, pageState, pageSubView, pageSubState } =
    useParams();
  const [searchParams] = useSearchParams();
  const { currentView } = useTabs(idTiers);
  const { navLocked, setNavLocked, sidebarWidth } = useSidebar();
  const [toolsMenuDisplayed, setToolsMenuDisplayed] = useState(false);

  const limitStr = (str) => {
    if (str && str.length > 50) return `${str.slice(0, 50)}...`;
    else return str;
  };

  useEffect(() => {
    const updateNavigationAvailability = () => {
      setCanGoBack(
        window.history.state?.idx > 1 ||
          location.pathname.startsWith("/crm/stats/")
      );
      setCanGoForward(historyStack.length > 0);
    };

    updateNavigationAvailability();

    window.addEventListener("popstate", updateNavigationAvailability);

    return () => {
      window.removeEventListener("popstate", updateNavigationAvailability);
    };
  }, [location, historyStack]);

  const handleGoBack = () => {
    if (location.pathname.startsWith("/crm/stats/")) {
      navigate("/crm");
    } else if (canGoBack) {
      setHistoryStack((prevStack) => [location.pathname, ...prevStack]);
      navigate(-1);
    }
  };

  const handleGoForward = () => {
    if (canGoForward) {
      const [nextPath, ...remainingStack] = historyStack;
      setHistoryStack(remainingStack);
      navigate(nextPath);
    }
  };

  // ------ TOOLS MENU
  const [tasksCount, setTasksCount] = useState({
    rdv: 0,
    rel: 0,
    vp: 0,
    divers: 0,
    total: 0,
  });
  const [events, setEvents] = useState([]);
  const [eventsLimit, setEventsLimit] = useState(true);

  const getDataTasks = async () => {
    const resApi = await fetchUserToDo();
    if (resApi.status === 200) {
      setTasksCount((prev) => {
        const returned = {
          ...resApi.data,
          total:
            (resApi.data.rdv || 0) +
            (resApi.data.rel || 0) +
            (resApi.data.vp || 0) +
            (resApi.data.divers || 0),
        };
        return { ...returned };
      });
    }
  };

  const getDataEvents = async () => {
    const now = moment().toISOString();
    const end = moment().endOf("day").toISOString();
    const resApi = await fetchUserEventsScheduled({
      startTime: now,
      endTime: end,
    });
    if (resApi.status === 200) {
      setEvents(() => {
        return [...resApi.data];
      });
    }
  };

  useEffect(() => {
    getDataTasks();
    getDataEvents();
  }, []);

  const eventsMapped = useMemo(() => {
    if (eventsLimit) return events.slice(0, 3);
    else return events;
  }, [events, eventsLimit]);

  useEffect(() => {
    if (events.length < 3) setEventsLimit(() => false);
    else setEventsLimit(() => true);
  }, [events]);

  const [menuLabel, setMenuLabel] = useState(() => (
    <>
      {upperFirst(moment().format("ddd"))}{" "}
      <span className="font-bold">{moment().format("DD")}</span>{" "}
      {moment().format("MMM")}{" "}
      <span className="font-medium">{moment().format("HH:mm")}</span>
    </>
  ));

  useEffect(() => {
    let index = 0;

    const updateLabel = () => {
      const options = [
        <>
          {upperFirst(moment().format("ddd"))}{" "}
          <span className="font-bold">{moment().format("DD")}</span>{" "}
          {moment().format("MMM")}{" "}
          <span className="font-medium">{moment().format("HH:mm")}</span>
        </>,
      ]; // Ajout de la date en premier
      if (events.length > 0)
        options.push(
          <>
            <span className="font-bold">{events.length}</span> évènement
            {events.total > 1 ? "s" : ""} à venir
          </>
        );
      if (tasksCount.total > 0)
        options.push(
          <>
            <span className="font-bold">{tasksCount.total}</span> tâche
            {tasksCount.total > 1 ? "s" : ""} affectée
            {tasksCount.total > 1 ? "s" : ""}
          </>
        );

      if (options.length > 1) {
        index = (index + 1) % options.length; // Passage cyclique dans les options
        setMenuLabel(options[index]);
      }
    };

    // Mise à jour toutes les 20s
    const interval = setInterval(updateLabel, 15 * 1000);

    return () => clearInterval(interval); // Nettoyage de l'intervalle lors du démontage
  }, [events, tasksCount]);

  const textRef = useRef(null); // Référence pour mesurer la largeur du texte
  const [width, setWidth] = useState(80); // Largeur initiale minimale

  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.scrollWidth); // Met à jour la largeur avec celle du texte
    }
  }, [menuLabel]); // Se met à jour à chaque changement de texte

  const arrPath = useMemo(() => {
    let paths = [];

    function formatLongStr(str) {
      return str ? (str.length > 40 ? str.slice(0, 10) + "..." : str) : "";
    }

    if (typePage === "menu") paths.push({ link: `/crm`, title: "Menu" });

    if (typePage === "menu" && idTiers === "equipe")
      paths.push({ link: `/crm/equipe`, title: "Equipe" });

    if (typePage === "search")
      paths.push({ link: `/crm/recherche`, title: "Recherche" });

    // Dashboard
    if (typePage === "dashboard")
      paths.push({ link: `/crm/dashboard`, title: "Dashboard" });

    if (typePage === "dashboard" && pageView) {
      const dashboardLabels = {
        activites: "Activités",
        dossiers: "Dossiers commerciaux",
        opportunites: "Opportunités",
        tiers: "Tiers",
        taches: "Tâches",
        contrats: "Contrats",
      };
      if (dashboardLabels[pageView])
        paths.push({
          link: `/crm/dashboard/${pageView}`,
          title: dashboardLabels[pageView],
        });

      if (pageState) {
        const dashboardStatesLabels = {
          clients: "Clients",
          concurrents: "Concurrents",
          valides: "Dossiers validés",
        };
        if (dashboardStatesLabels[pageState])
          paths.push({
            link: `/crm/dashboard/${pageView}/${pageState}`,
            title: dashboardStatesLabels[pageState],
          });
      }
    }

    // Tiers
    if (typePage === "tiers") {
      paths.push({ link: `/crm/${idTiers || ""}`, title: "Tiers" });
      paths.push({
        link: `/crm/${idTiers || ""}`,
        title: `${formatLongStr(
          currentView?.state?.mainData?.raisonSociale || "..."
        )}`,
      });
    }

    if (typePage === "tiers" && pageView) {
      const tiersLabels = {
        map: "Map",
        activites: "Activités",
        taches: "Tâches",
        contacts: "Contacts",
        notes: "Notes",
        "parc-client": "Parc Client",
        interventions: "Interventions",
        comptabilite: "Comptabilité",
        resiliations: "Résiliations",
        "parc-conc": "Parc Concurrent",
        sites: "Tiers/sites liés",
        web: "Identité web",
        opportunites: "Opportunités",
      };
      if (tiersLabels[pageView])
        paths.push({
          link: `/crm/${idTiers}/${pageView}`,
          title: tiersLabels[pageView],
        });

      if (pageView === "opportunites") {
        if (pageState)
          paths.push({
            link: `/crm/${idTiers}/opportunite/${pageState}`,
            title: `Détails`,
          });

        if (pageSubView === "projet") {
          paths.push({
            link: `/crm/${idTiers}/opportunite/${pageState}/projet`,
            title: `Projet`,
          });
          paths.push({
            link: `/crm/${idTiers}/opportunite/${pageState}/projet/${pageSubState}`,
            title: `${pageSubState}`,
          });
        }

        if (pageSubView === "salesbook")
          paths.push({
            link: `/crm/${idTiers}/opportunite/${pageState}/salesbook/${
              pageSubState || ""
            }`,
            title: `SalesBook`,
          });
        if (pageSubView === "flv")
          paths.push({
            link: `/crm/${idTiers}/opportunite/${pageState}/flv/${
              pageSubState || ""
            }`,
            title: `FLV`,
          });
      }

      if (pageView === "resiliations") {
        if (pageState)
          paths.push({
            link: `/crm/${idTiers}/resiliations/${pageState}`,
            title: `Détails`,
          });
      }
    }

    return paths;
  }, [
    typePage,
    idTiers,
    pageView,
    pageSubView,
    pageState,
    searchParams,
    currentView,
  ]);

  return (
    <>
      <header
        style={{
          width: navLocked ? "100%" : `calc(100% - ${sidebarWidth}px)`,
          left: navLocked ? "0px" : `${sidebarWidth}px`,
        }}
        className="atna-bar z-[50] flex justify-between items-center px-[10px] max-w-[100vw] max-w-screen h-topBarHeight transition-all duration-200"
      >
        <div className="flex items-center flex-1 max-w-screen overflow-y-hidden">
          <div className="flex items-center gap-x-[5px]">
            <div className="block shrink-0 grow-0">
              <div
                onClick={() => {
                  setNavLocked((prev) => !prev);
                }}
                data-title="Afficher"
                className={cn(
                  "flex atna-tooltip-bottom-right hover:bg-[rgba(0,0,0,0.04)] h-[27px] w-[27px] rounded-[4px] items-center justify-center",
                  navLocked ? "flex" : "hidden"
                )}
              >
                <div
                  role="button"
                  className="w-[11px] h-[11px] rotate-180 grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.65)]"
                >
                  <svg
                    role="graphics-symbol"
                    viewBox="0 0 16 16"
                    width={"100%"}
                    height={"100%"}
                    className="doubleChevronLeft"
                  >
                    <path d="M7.07031 13.8887C7.2207 14.0391 7.40527 14.1211 7.62402 14.1211C8.06836 14.1211 8.41699 13.7725 8.41699 13.3281C8.41699 13.1094 8.32812 12.9043 8.17773 12.7539L3.37207 8.05762L8.17773 3.375C8.32812 3.21777 8.41699 3.0127 8.41699 2.80078C8.41699 2.35645 8.06836 2.00781 7.62402 2.00781C7.40527 2.00781 7.2207 2.08984 7.07031 2.24023L1.73828 7.44922C1.56055 7.62012 1.46484 7.8252 1.46484 8.06445C1.46484 8.29688 1.55371 8.49512 1.73828 8.67969L7.07031 13.8887ZM13.1748 13.8887C13.3252 14.0391 13.5098 14.1211 13.7354 14.1211C14.1797 14.1211 14.5283 13.7725 14.5283 13.3281C14.5283 13.1094 14.4395 12.9043 14.2891 12.7539L9.4834 8.05762L14.2891 3.375C14.4395 3.21777 14.5283 3.0127 14.5283 2.80078C14.5283 2.35645 14.1797 2.00781 13.7354 2.00781C13.5098 2.00781 13.3252 2.08984 13.1748 2.24023L7.84961 7.44922C7.66504 7.62012 7.57617 7.8252 7.56934 8.06445C7.56934 8.29688 7.66504 8.49512 7.84961 8.67969L13.1748 13.8887Z"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex w-max items-center gap-x-[2px]">
              <div
                onClick={() => {
                  if (canGoBack) handleGoBack();
                }}
                data-title="Revenir"
                className={cn(
                  "flex atna-tooltip-bottom-right hover:bg-[rgba(0,0,0,0.04)] h-[27px] w-[27px] rounded-[4px] items-center justify-center",
                  !canGoBack && ""
                )}
              >
                <div
                  role="button"
                  className={cn(
                    "w-[15px] h-[15px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.30)] pointer-events-none",
                    canGoBack && "text-[rgba(55,53,47,0.65)] "
                  )}
                >
                  <IoArrowBackOutline />
                </div>
              </div>
              <div
                onClick={() => {
                  if (canGoForward) handleGoForward();
                }}
                data-title="Avancer"
                className={cn(
                  "flex atna-tooltip-bottom-right hover:bg-[rgba(0,0,0,0.04)] h-[27px] w-[27px] rounded-[4px] items-center justify-center",
                  !canGoForward && ""
                )}
              >
                <div
                  role="button"
                  className={cn(
                    "w-[15px] h-[15px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.30)] pointer-events-none",
                    canGoForward && "text-[rgba(55,53,47,0.65)] "
                  )}
                >
                  <IoArrowForwardOutline />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              WebkitMaskImage:
                "linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 12px, rgba(0,0,0,1) calc(100% - 12px), rgba(0,0,0,0) 100%)",
              maskImage:
                "linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 12px, rgba(0,0,0,1) calc(100% - 12px), rgba(0,0,0,0) 100%)",
            }}
            className="flex flex-1 min-w-0 w-full mr-[0px] overflow-x-auto overflow-y-visible atna-invisible-scrollbar flex-shrink px-[4px]"
          >
            <div className="flex gap-x-[10px] items-center flex-nowrap w-max overflow-y-visible">
              {arrPath.map((path, index) => (
                <div className="flex items-center gap-x-[10px]" key={index}>
                  {!!index && (
                    <div className="text-[10px] font-bold text-black/20">/</div>
                  )}
                  <div
                    onClick={() => {
                      navigate(path.link);
                    }}
                    style={{ zIndex: 10, margin: "20px 0px" }}
                    className="w-max h-[24px] bg-black/[0] px-[12px] flex items-center gap-x-[10px] justify-center rounded-[8px] cursor-pointer atna-strong-shadow bg-white active:scale-[0.99] transition-all duration-75"
                  >
                    <span className="text-[9.5px] text-black font-medium">
                      {path.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center pr-[10px]">
            <div
              onClick={() => setToolsMenuDisplayed((prev) => !prev)}
              className="h-[24px] bg-black/[0] px-[12px] flex items-center gap-x-[10px] justify-center rounded-[8px] cursor-pointer atna-strong-shadow bg-white active:scale-[0.98] transition-all duration-75 "
            >
              <div className="size-[4px] rounded-full bg-primary"></div>

              {/* Conteneur animé qui ajuste la largeur */}
              <motion.div
                animate={{ width: width + 20 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="inline-flex justify-center overflow-hidden"
              >
                <span
                  ref={textRef}
                  className="text-[10px] text-nowrap whitespace-nowrap text-black/80 font-medium text-center"
                >
                  {menuLabel}
                </span>
              </motion.div>
            </div>
          </div>
        </div>
      </header>

      <div
        className={cn(
          "fixed flex flex-col gap-y-[15px] z-[90] px-[20px] py-[10px] h-max w-full max-w-[320px] top-[40px] right-0 opacity-50 pointer-events-none transition-all duration-[.5s] translate-x-[320px]",
          toolsMenuDisplayed && "opacity-100 pointer-events-auto translate-x-0"
        )}
      >
        <div className="h-max w-full rounded-[20px] shadow-2xl shadow-black/10 bg-white/80 atna-strong-shadow">
          <div className="w-full h-full flex flex-1 flex-col px-[20px] py-[15px]">
            <h1 className="text-[11px] font-bold text-black/30">À venir</h1>
            <div className="flex items-end gap-x-[5px] cursor-pointer">
              <h1 className="text-[13px] font-bold text-black/80 w-[15px] text-end">
                {tasksCount.rdv || 0}
              </h1>
              <Link
                onClick={() => setToolsMenuDisplayed(() => false)}
                to="/crm/stats/actions?type=rdv"
                className="text-[12px] font-semibold hover:underline text-black/80"
              >
                rendez-vous
              </Link>
            </div>
            <div className="flex items-end gap-x-[5px] cursor-pointer">
              <h1 className="text-[13px] font-bold text-black/80 w-[15px] text-end">
                {tasksCount.rel || 0}
              </h1>
              <Link
                onClick={() => setToolsMenuDisplayed(() => false)}
                to="/crm/stats/actions?type=rel"
                className="text-[12px] font-semibold hover:underline text-black/80"
              >
                relances
              </Link>
            </div>
            <div className="flex items-end gap-x-[5px] cursor-pointer">
              <h1 className="text-[13px] font-bold text-black/80 w-[15px] text-end">
                {tasksCount.vp || 0}
              </h1>
              <Link
                onClick={() => setToolsMenuDisplayed(() => false)}
                to="/crm/stats/actions?type=vp"
                className="text-[12px] font-semibold hover:underline text-black/80"
              >
                visites programmées
              </Link>
            </div>
            <div className="flex items-end gap-x-[5px] cursor-pointer">
              <h1 className="text-[13px] font-bold text-black/80 w-[15px] text-end">
                {tasksCount.divers || 0}
              </h1>
              <span className="text-[12px] font-semibold hover:underline text-black/80">
                tâches diverses
              </span>
            </div>
          </div>
        </div>

        <div
          className={cn(
            "min-h-[100px] w-full rounded-[20px] shadow-2xl shadow-black/10 bg-white/80 atna-strong-shadow transition-all duration-100 overflow-hidden",
            !eventsLimit && "h-max"
          )}
        >
          <div className="w-full h-full flex flex-1 flex-col px-[20px] py-[15px]">
            <h1 className="text-[11px] font-bold text-black/30"> Agenda</h1>

            <div className="flex flex-col gap-y-[5px] mt-[5px]">
              {eventsMapped.map((evt, evtIndex) => {
                return (
                  <div
                    key={`evtMenuTools_${evtIndex}`}
                    onClick={() => {
                      window.open(evt.webLink, "_blank");
                    }}
                    className="bg-black/[0.02] backdrop-blur rounded-[8px] h-[25px] flex shrink-0 items-center px-[10px] gap-x-[10px]"
                  >
                    <h1 className="text-[11px] hover:underline font-medium text-nowrap whitespace-nowrap text-ellipsis overflow-hidden text-black">
                      {evt.subject}
                    </h1>
                  </div>
                );
              })}
            </div>
            {events.length > 3 && (
              <div className="flex w-full justify-end items-center mt-[8px]">
                <h1
                  onClick={() => {
                    setEventsLimit((prev) => !prev);
                  }}
                  className="text-[11px] text-black/30 hover:underline font-semibold cursor-pointer"
                >
                  {eventsLimit ? "Voir plus" : "Voir moins"}
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        onClick={() => setToolsMenuDisplayed(() => false)}
        className={cn(
          "fixed w-[100vw] h-[100vh] bg-white/[0.5] backdrop-blur-[4px] z-[10] duration-300 transition-opacity opacity-0 pointer-events-none",
          toolsMenuDisplayed && "opacity-100 pointer-events-auto"
        )}
      ></div>
    </>
  );
});

export default Topbar;
