import React, { useEffect, useState, useCallback, useMemo } from "react";
import { cn } from "../../../utils/cn";
import {
  IoBookmarkOutline,
  IoClose,
  IoGlobeOutline,
  IoList,
  IoSearch,
  IoShareSocial,
} from "react-icons/io5";
import { IoMdPin } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import { HiOutlineViewGrid } from "react-icons/hi";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTabs } from "../../../utils/hooks/useTabs";
import { RiMapPin2Line } from "react-icons/ri";
import { useAuth } from "../../../utils/hooks/useAuth";
import {
  TbFolderShare,
  TbBookmark,
  TbCalendar,
  TbChartCandle,
  TbInfoSquareRounded,
  TbListDetails,
  TbManualGearbox,
  TbMapPin,
  TbNotebook,
  TbSitemap,
  TbSubtask,
  TbUsers,
  TbView360,
  TbWorldWww,
  TbSettings,
  TbCalculator,
  TbFolder,
  TbFolders,
  TbLayoutDashboard,
  TbLayoutDashboardFilled,
  TbInputSearch,
  TbTableColumn,
  TbLayoutCollage,
  TbBuildings,
  TbLocation,
  TbListCheck,
  TbUserScreen,
  TbUsersGroup,
  TbUserSquareRounded,
  TbBookmarkEdit,
  TbNetwork,
  TbDeviceDesktop,
  TbMapPinPin,
  TbBox,
  TbTableOptions,
  TbHeadset,
  TbReportMoney,
  TbGraph,
  TbChartArea,
  TbSettings2,
} from "react-icons/tb";
import { CgFileDocument, CgWebsite } from "react-icons/cg";
import { PiGear, PiGearBold, PiNotepadBold } from "react-icons/pi";
import { filter, upperFirst } from "lodash";
import { FaExchangeAlt } from "react-icons/fa";
import {
  BiArrowToBottom,
  BiChevronDown,
  BiChevronRight,
  BiLoaderAlt,
  BiX,
} from "react-icons/bi";
import moment from "moment";
import { MdOutlineAssignment } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { GrLink } from "react-icons/gr";
import { LuLayoutDashboard, LuUser } from "react-icons/lu";
import { FaChartLine } from "react-icons/fa6";
import { AiOutlineStop } from "react-icons/ai";
import { useSidebar } from "../../../utils/hooks/useSideBar";

const Sidebar = ({ windowWidthSize, typePage, viewKey, setViewKey }) => {
  const {
    sidebarWidth,
    setSidebarWidth,
    isResizing,
    setIsResizing,
    cursorTop,
    setCursorTop,
    navLocked,
    setNavLocked,
  } = useSidebar();
  const { idTiers, pageView, pageSubView, pageSubState, pageState } =
    useParams();
  const [viewsDropVisible, setViewsDropVisible] = useState(false);
  const [userDropVisible, setUserDropVisible] = useState(false);
  const { getViews, deleteView } = useTabs();
  const views = getViews();
  const { user, userPhoto, handleLogout, hasUserPhoto } = useAuth();
  const navigate = useNavigate();
  const [menusDisplayed, setMenusDisplayed] = useState({
    dashboard: false,
    actions: false,
    clients: false,
    tiers: false,
  });
  const [logoutLoading, setLogoutLoading] = useState(false);

  const logoutUser = async () => {
    setLogoutLoading(() => true);
    await handleLogout();
    setLogoutLoading(() => false);
  };

  useEffect(() => {
    console.log({ navLocked });
  }, [navLocked]);

  useEffect(() => {
    function hideViewsDropWhenClickOutside(e) {
      if (!e?.target.closest(".atna-views-drop"))
        setViewsDropVisible(() => false);
      if (!e?.target.closest(".atna-user-drop"))
        setUserDropVisible(() => false);
    }

    document.addEventListener("click", hideViewsDropWhenClickOutside);

    return () => {
      document.removeEventListener("click", hideViewsDropWhenClickOutside);
    };
  }, []);

  const location = useLocation();

  // Fermer la sidebar quand l'URL change (uniquement sur mobile)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setNavLocked(() => true);
      }
    };

    handleResize(); // Vérifie au premier rendu
  }, [location.pathname]); // Déclenché quand l'URL change

  const filteredViews = useMemo(() => {
    return views?.filter((v) => v.state?.mainData?.raisonSociale) || [];
  }, [views]);

  const filteredViewsLength = useMemo(() => {
    return filteredViews.length;
  }, [filteredViews]);

  const letters = useMemo(() => {
    if (user.personnel.nom)
      return `${user.personnel.prenom
        .charAt(0)
        .toUpperCase()}${user.personnel.nom.charAt(0).toUpperCase()}`;
    else return `${user.personnel.prenom.charAt(0).toUpperCase()}`;
  }, [user]);

  return (
    <nav
      className={cn(
        "atna-sidebar pointer-events-auto select-none",
        navLocked && "atna-sidebar-locked min-w-0 w-0"
      )}
      style={{
        width: sidebarWidth,
        transform: `translateX(-${navLocked ? sidebarWidth + 30 : 0}px)`,
      }}
    >
      <div className="flex flex-col h-full flex-1">
        <div className="flex items-center justify-between px-[16px] pt-[20px] pb-[5px]">
          <Link
            to="/"
            role="button"
            tabIndex={0}
            data-title="Accéder au menu"
            className=" h-[25px] shrink-0 grow-0 hover:bg-[rgba(0,0,0,0.04)] rounded-[4px] flex items-center justify-center gap-x-[10px]"
          >
            <img
              src={require("../../../cdn/images/atna-icone-noir.png")}
              alt="aTna"
              className="w-[25px] h-[25px]"
            />
          </Link>
          <div
            onClick={() => {
              setNavLocked((prev) => !prev);
            }}
            role="button"
            tabIndex={0}
            data-title="Fermer"
            className="flex bg-white atna-shadow h-[25px] w-[25px] rounded-[8px] items-center justify-center !absolute top-[12px] right-[12px]"
          >
            <div className="w-[10px] h-[10px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.45)]">
              <svg
                role="graphics-symbol"
                viewBox="0 0 16 16"
                width={"100%"}
                height={"100%"}
                className="doubleChevronLeft"
              >
                <path d="M7.07031 13.8887C7.2207 14.0391 7.40527 14.1211 7.62402 14.1211C8.06836 14.1211 8.41699 13.7725 8.41699 13.3281C8.41699 13.1094 8.32812 12.9043 8.17773 12.7539L3.37207 8.05762L8.17773 3.375C8.32812 3.21777 8.41699 3.0127 8.41699 2.80078C8.41699 2.35645 8.06836 2.00781 7.62402 2.00781C7.40527 2.00781 7.2207 2.08984 7.07031 2.24023L1.73828 7.44922C1.56055 7.62012 1.46484 7.8252 1.46484 8.06445C1.46484 8.29688 1.55371 8.49512 1.73828 8.67969L7.07031 13.8887ZM13.1748 13.8887C13.3252 14.0391 13.5098 14.1211 13.7354 14.1211C14.1797 14.1211 14.5283 13.7725 14.5283 13.3281C14.5283 13.1094 14.4395 12.9043 14.2891 12.7539L9.4834 8.05762L14.2891 3.375C14.4395 3.21777 14.5283 3.0127 14.5283 2.80078C14.5283 2.35645 14.1797 2.00781 13.7354 2.00781C13.5098 2.00781 13.3252 2.08984 13.1748 2.24023L7.84961 7.44922C7.66504 7.62012 7.57617 7.8252 7.56934 8.06445C7.56934 8.29688 7.66504 8.49512 7.84961 8.67969L13.1748 13.8887Z"></path>
              </svg>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col h-full flex-1 overflow-y-auto atna-invisible-scrollbar pt-[15px]"
          style={{
            height: "100%",
            overflowY: "auto",
            scrollBehavior: "smooth",
            WebkitMaskImage:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 1) 10px)",
            maskImage:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 1) 10px)",
          }}
        >
          <div className="flex flex-col mx-[12px] flex-1">
            <div className="relative pointer-events-auto">
              <div
                onClick={() => {
                  setViewsDropVisible((prev) => !prev);
                }}
                className="flex items-center justify-between h-[26px] rounded-[8px] pl-[4px] pr-[4px] pointer-events-auto cursor-pointer atna-views-drop"
              >
                <span className="text-[10px] font-semibold text-black/40">
                  Vues récentes
                </span>
                <div className="h-[18px] bg-black/[0.06] rounded-[100px] px-[6px] flex items-center justify-center min-w-[25px]">
                  <span className="text-[8px] font-semibold text-black/40">
                    {filteredViewsLength || 0}
                  </span>
                </div>
              </div>

              {viewsDropVisible && (
                <div className="w-full h-max absolute z-[90] top-[calc(100%_+_4px)] left-0 bg-white atna-shadow border-[0.8px] border-black/[0.08] rounded-[8px] px-[6px] py-[6px]">
                  {filteredViewsLength > 0 ? (
                    <ul className="flex flex-col gap-y-[2px] pointer-events-auto">
                      {filteredViews.map((v) => {
                        return (
                          <Link
                            key={v.id}
                            to={`/crm/${v.id}`}
                            className="pointer-events-auto w-full h-[22px] flex-none hover:bg-black/[0.02] active:bg-black/[0.04] rounded-[6px]  pl-[10px] flex items-center justify-between"
                          >
                            <span className="text-[10px]  text-nowrap whitespace-nowrap overflow-hidden text-ellipsis">
                              {v.id === "nouveau"
                                ? "Nouveau tiers"
                                : v.state?.mainData?.raisonSociale || v.id}
                            </span>
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (idTiers === v.id) navigate("/crm");
                                deleteView(v.id);
                                return setViewsDropVisible(() => false);
                              }}
                              className="pl-[4px] pr-[4px] text-[11px] text-black/40 cursor-default"
                            >
                              <BiX />
                            </span>
                          </Link>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="flex items-center justify-center min-h-[40px]">
                      <span className="text-[10px] font-semibold text-black/20">
                        Aucune vue
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-col mt-[20px] gap-y-[5px]">
              <Link
                to={"/crm"}
                className={cn(
                  "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                  "text-black text-[10.5px] font-medium ",
                  typePage === "menu" && "bg-black/[0.04] opacity-100"
                )}
              >
                <span className="w-[20px]">
                  <HiOutlineViewGrid className="text-[12px] text-black" />
                </span>{" "}
                Menu
              </Link>

              {user.niveau >= 7 && (
                <div className={cn("w-full gap-x-[10px] items-center flex")}>
                  <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                  <div className="flex w-full flex-col gap-y-[2px]">
                    <Link
                      to={"/crm/equipe"}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                        "text-black text-[10px] font-medium ",
                        idTiers === "equipe"
                          ? "bg-black/[0.04] opacity-100"
                          : "hover:bg-black/[0.02]"
                      )}
                    >
                      Mon équipe
                    </Link>
                  </div>
                </div>
              )}

              <Link
                to={"/crm/recherche"}
                className={cn(
                  "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                  "text-black text-[10.5px] font-medium ",
                  idTiers === "recherche" && "bg-black/[0.04] opacity-100"
                )}
              >
                <span className="w-[20px]">
                  <TbInputSearch className="text-[14px] text-black" />
                </span>{" "}
                Recherche
              </Link>
            </div>

            <div className="flex flex-col mt-[20px] gap-y-[5px]">
              <div
                onClick={() => {
                  setMenusDisplayed((prev) => ({
                    ...prev,
                    dashboard: !prev.dashboard,
                  }));
                }}
                className="flex items-center text-[10.5px] text-black justify-between cursor-pointer"
              >
                <div className="flex items-center uppercase font-bold text-black/40 text-[9px]">
                  <span className="w-[25px] text-[14px]">
                    <TbLayoutCollage />
                  </span>
                  <span className="font-bold">Dashboard</span>
                </div>
                <BiChevronDown
                  className={cn(
                    "text-[12px] text-black/40 -rotate-90 transition-all duration-150",
                    (menusDisplayed.dashboard || idTiers === "dashboard") &&
                      "-rotate-0"
                  )}
                />
              </div>

              <div
                className={cn(
                  "w-full gap-x-[10px] items-center hidden",
                  (menusDisplayed.dashboard || idTiers === "dashboard") &&
                    "flex"
                )}
              >
                <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                <div className="flex w-full flex-col gap-y-[2px]">
                  <Link
                    to={"/crm/dashboard/activites"}
                    className={cn(
                      "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                      "text-black text-[10px] font-medium ",
                      idTiers === "dashboard" && pageView === "activites"
                        ? "bg-black/[0.04] opacity-100"
                        : "hover:bg-black/[0.02]"
                    )}
                  >
                    Activités
                  </Link>

                  <Link
                    to={"/crm/dashboard/dossiers"}
                    className={cn(
                      "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                      "text-black text-[10px] font-medium ",
                      idTiers === "dashboard" &&
                        pageView === "dossiers" &&
                        !pageState
                        ? "bg-black/[0.04] opacity-100"
                        : "hover:bg-black/[0.02]"
                    )}
                  >
                    Dossiers commerciaux
                  </Link>

                  {user.niveau >= 7 && (
                    <div
                      className={cn(
                        "w-full gap-x-[10px] items-center flex relative",
                        "after:absolute after:w-[3px] after:rounded-full after:bg-black/[0.04] after:h-[calc(100%_-_5px)] after:left-[8px] after:top-1/2 after:-translate-y-1/2"
                      )}
                    >
                      <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>
                      <div className="flex w-full flex-col gap-y-[2px]">
                        {typePage === "dashboard" &&
                          pageView === "dossiers" &&
                          pageState && (
                            <Link
                              to={`/crm/dashboard/dossiers/valides`}
                              className={cn(
                                "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                                "text-black text-[10.5px] font-medium ",
                                typePage === "dashboard" &&
                                  pageView === "dossiers" &&
                                  pageState &&
                                  "bg-black/[0.04] opacity-100"
                              )}
                            >
                              Dossiers validés
                            </Link>
                          )}
                      </div>
                    </div>
                  )}

                  <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                  <Link
                    to={"/crm/dashboard/opportunites"}
                    className={cn(
                      "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                      "text-black text-[10px] font-medium ",
                      idTiers === "dashboard" && pageView === "opportunites"
                        ? "bg-black/[0.04] opacity-100"
                        : "hover:bg-black/[0.02]"
                    )}
                  >
                    Opportunités
                  </Link>

                  <Link
                    to={"/crm/dashboard/tiers"}
                    className={cn(
                      "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                      "text-black text-[10px] font-medium ",
                      idTiers === "dashboard" &&
                        pageView === "tiers" &&
                        !pageState
                        ? "bg-black/[0.04] opacity-100"
                        : "hover:bg-black/[0.02]"
                    )}
                  >
                    Tiers
                  </Link>

                  <Link
                    to={"/crm/dashboard/taches"}
                    className={cn(
                      "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                      "text-black text-[10px] font-medium ",
                      idTiers === "dashboard" &&
                        pageView === "taches" &&
                        !pageState
                        ? "bg-black/[0.04] opacity-100"
                        : "hover:bg-black/[0.02]"
                    )}
                  >
                    Tâches
                  </Link>

                  {/* <div className="flex flex-col">
           <div 
            onClick={() => { 
              setMenusDisplayed((prev) => ({...prev, tiers: !prev.tiers}))
            }}
            className="flex items-center text-[10.5px] text-black justify-between cursor-pointer mt-[5px] ml-[5px]">
                <div className='flex items-center uppercase font-bold text-black/40 text-[9px] h-[25px]'>
                <span className='w-[25px] text-[14px]'><TbLayoutCollage /></span>
                <span className='font-bold'>Tiers</span>
                </div>
                <BiChevronDown className={cn('text-[12px] text-black/40 -rotate-90 transition-all duration-150', (menusDisplayed.tiers||(idTiers === "dashboard" && (pageView === "tiers"&&pageState))) && '-rotate-0')}/>
            </div>
            <div className={cn("w-full gap-x-[10px] items-center hidden h-full relative pt-[5px]", (menusDisplayed.tiers||(idTiers === "dashboard" && (pageView === "tiers"&&pageState))) && "flex", 
              "after:absolute after:left-[10px] after:w-[3px] after:h-[calc(90%_-_10px)] after:bg-black/[0.04] after:rounded-full"
            )}>
              <div className='w-[3px] min-w-[3px] shrink-0 flex-none h-[50px] bg-black/[0.0] rounded-full ml-[5px]'></div>
              <div className="flex w-full flex-col gap-y-[2px]">
                  <Link to={"/crm/dashboard/tiers/inexploitables"}
                   className={cn('w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]',
                                             'text-black text-[10px] font-medium ', 
                                             (idTiers === "dashboard" && pageView === "tiers" && pageState === "inexploitables") ? "bg-black/[0.04] opacity-100" : "hover:bg-black/[0.02]"
                                             )}>
                     Inexploitables
                  </Link>


                  <Link to={"/crm/dashboard/tiers/inactifs"}
                   className={cn('w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]',
                                             'text-black text-[10px] font-medium ', 
                                             (idTiers === "dashboard" && pageView === "tiers" && pageState === "inactifs") ? "bg-black/[0.04] opacity-100" : "hover:bg-black/[0.02]"
                                             )}>
                      Inactifs
                  </Link>

                  <Link to={"/crm/dashboard/tiers/vierge"}
                   className={cn('w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]',
                                             'text-black text-[10px] font-medium ', 
                                             (idTiers === "dashboard" && pageView === "actions" && pageState === "vierge") ? "bg-black/[0.04] opacity-100" : "hover:bg-black/[0.02]"
                                             )}>
                      Vierge
                  </Link>

                  </div>
              </div>
           </div> */}

                  {/* <div className="flex flex-col">
           <div 
            onClick={() => { 
              setMenusDisplayed((prev) => ({...prev, actions: !prev.actions}))
            }}
            className="flex items-center text-[10.5px] text-black justify-between cursor-pointer mt-[5px] ml-[5px]">
                <div className='flex items-center uppercase font-bold text-black/40 text-[9px] h-[25px]'>
                <span className='w-[25px] text-[14px]'><TbLayoutCollage /></span>
                <span className='font-bold'>Tâches</span>
                </div>
                <BiChevronDown className={cn('text-[12px] text-black/40 -rotate-90 transition-all duration-150', (menusDisplayed.actions||(idTiers === "dashboard" && pageView === "actions")) && '-rotate-0')}/>
            </div>
            <div className={cn("w-full gap-x-[10px] items-center hidden h-full relative pt-[5px]", (menusDisplayed.actions||(idTiers === "dashboard" && pageView === "actions")) && "flex", 
              "after:absolute after:left-[10px] after:w-[3px] after:h-[calc(90%_-_10px)] after:bg-black/[0.04] after:rounded-full"
            )}>
              <div className='w-[3px] min-w-[3px] shrink-0 flex-none h-[50px] bg-black/[0.0] rounded-full ml-[5px]'></div>
              <div className="flex w-full flex-col gap-y-[2px]">
                  <Link to={"/crm/dashboard/actions/rdv"}
                   className={cn('w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]',
                                             'text-black text-[10px] font-medium ', 
                                             (idTiers === "dashboard" && pageView === "actions" && pageState === "rdv") ? "bg-black/[0.04] opacity-100" : "hover:bg-black/[0.02]"
                                             )}>
                     Rendez-Vous
                  </Link>


                  <Link to={"/crm/dashboard/actions/rel"}
                   className={cn('w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]',
                                             'text-black text-[10px] font-medium ', 
                                             (idTiers === "dashboard" && pageView === "actions" && pageState === "rel") ? "bg-black/[0.04] opacity-100" : "hover:bg-black/[0.02]"
                                             )}>
                      Relance
                  </Link>

                  <Link to={"/crm/dashboard/actions/vp"}
                   className={cn('w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]',
                                             'text-black text-[10px] font-medium ', 
                                             (idTiers === "dashboard" && pageView === "actions" && pageState === "vp") ? "bg-black/[0.04] opacity-100" : "hover:bg-black/[0.02]"
                                             )}>
                      Visite Prog.
                  </Link>

                  </div>
              </div>
           </div> */}

                  <div className="flex flex-col">
                    <div
                      onClick={() => {
                        setMenusDisplayed((prev) => ({
                          ...prev,
                          clients: !prev.clients,
                        }));
                      }}
                      className="flex items-center text-[10.5px] text-black justify-between cursor-pointer mt-[5px] ml-[5px]"
                    >
                      <div className="flex items-center uppercase font-bold text-black/40 text-[9px] h-[25px]">
                        <span className="w-[25px] text-[14px]">
                          <TbLayoutCollage />
                        </span>
                        <span className="font-bold">Contrats</span>
                      </div>
                      <BiChevronDown
                        className={cn(
                          "text-[12px] text-black/40 -rotate-90 transition-all duration-150",
                          (menusDisplayed.clients ||
                            (idTiers === "dashboard" &&
                              pageView === "contrats")) &&
                            "-rotate-0"
                        )}
                      />
                    </div>
                    <div
                      className={cn(
                        "w-full gap-x-[10px] items-center hidden h-full relative pt-[5px]",
                        (menusDisplayed.clients ||
                          (idTiers === "dashboard" &&
                            pageView === "contrats")) &&
                          "flex",
                        "after:absolute after:left-[10px] after:w-[3px] after:h-[calc(90%_-_10px)] after:bg-black/[0.04] after:rounded-full"
                      )}
                    >
                      <div className="w-[3px] min-w-[3px] shrink-0 flex-none h-[50px] bg-black/[0.0] rounded-full ml-[5px]"></div>
                      <div className="flex w-full flex-col gap-y-[2px]">
                        <Link
                          to={"/crm/dashboard/contrats/clients"}
                          className={cn(
                            "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                            "text-black text-[10px] font-medium ",
                            idTiers === "dashboard" &&
                              pageView === "contrats" &&
                              pageState === "clients"
                              ? "bg-black/[0.04] opacity-100"
                              : "hover:bg-black/[0.02]"
                          )}
                        >
                          Clients
                        </Link>

                        <Link
                          to={"/crm/dashboard/contrats/concurrents"}
                          className={cn(
                            "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px]  active:bg-black/[0.04]",
                            "text-black text-[10px] font-medium ",
                            idTiers === "dashboard" &&
                              pageView === "contrats" &&
                              pageState === "concurrents"
                              ? "bg-black/[0.04] opacity-100"
                              : "hover:bg-black/[0.02]"
                          )}
                        >
                          Concurrents
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {typePage === "tiers" && (
              <div className="flex flex-col mt-[20px] gap-y-[5px]">
                <div className="flex items-center text-[10.5px] text-black justify-between cursor-pointer">
                  <div className="flex items-center uppercase font-bold text-black/40 text-[9px]">
                    <span className="w-[25px] text-[14px]">
                      <TbBuildings />
                    </span>
                    <span className="font-bold">Tiers</span>
                  </div>
                </div>

                <div className={cn("w-full gap-x-[10px] items-center flex")}>
                  <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                  <div className="flex w-full flex-col gap-y-[2px]">
                    <Link
                      to={`/crm/${idTiers || ""}`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          !pageView &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbInfoSquareRounded className="text-[14px] text-black" />
                      </span>{" "}
                      Informations
                    </Link>

                    <Link
                      to={`/crm/${idTiers || ""}/map`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "map" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbMapPinPin className="text-[14px] text-black" />
                      </span>{" "}
                      Map
                    </Link>

                    <div className="flex justify-center w-full">
                      <div className="flex flex-none h-[2px] w-[80%] my-[4px] bg-black/[0.02] rounded-full"></div>
                    </div>

                    <Link
                      to={`/crm/${idTiers || ""}/activites`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "activites" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbLocation className="text-[14px] text-black" />
                      </span>{" "}
                      Activités
                    </Link>

                    <Link
                      to={`/crm/${idTiers || ""}/taches`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "taches" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbListCheck className="text-[14px] text-black" />
                      </span>{" "}
                      Tâches
                    </Link>

                    <Link
                      to={`/crm/${idTiers || ""}/contacts`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "contacts" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbUserSquareRounded className="text-[14px] text-black" />
                      </span>{" "}
                      Contacts
                    </Link>

                    <Link
                      to={`/crm/${idTiers || ""}/notes`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "notes" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbBookmarkEdit className="text-[14px] text-black" />
                      </span>{" "}
                      Notes
                    </Link>

                    <div className="flex justify-center w-full">
                      <div className="flex flex-none h-[2px] w-[80%] my-[4px] bg-black/[0.02] rounded-full"></div>
                    </div>

                    <Link
                      to={`/crm/${idTiers || ""}/parc-client`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "parc-client" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbTableOptions className="text-[14px] text-black" />
                      </span>{" "}
                      Parc Client
                    </Link>

                    <Link
                      to={`/crm/${idTiers || ""}/interventions`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "interventions" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbListDetails className="text-[14px] text-black" />
                      </span>{" "}
                      Interventions
                    </Link>

                    <Link
                      to={`/crm/${idTiers || ""}/comptabilite`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "comptabilite" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbCalculator className="text-[14px] text-black" />
                      </span>{" "}
                      Comptabilité
                    </Link>

                    <div className="flex justify-center w-full">
                      <div className="flex flex-none h-[2px] w-[80%] my-[4px] bg-black/[0.02] rounded-full"></div>
                    </div>

                    <Link
                      to={`/crm/${idTiers || ""}/resiliations`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "resiliations" &&
                          !pageState &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <AiOutlineStop className="text-[14px] text-black" />
                      </span>{" "}
                      Résiliations
                    </Link>

                    <div
                      className={cn(
                        "w-full gap-x-[10px] items-center flex relative",
                        "after:absolute after:w-[3px] after:rounded-full after:bg-black/[0.04] after:h-[calc(100%_-_5px)] after:left-[8px] after:top-1/2 after:-translate-y-1/2"
                      )}
                    >
                      <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                      <div className="flex w-full flex-col gap-y-[2px]">
                        {typePage === "tiers" &&
                          pageView === "resiliations" &&
                          pageState && (
                            <Link
                              to={`/crm/${
                                idTiers || ""
                              }/resiliations/${pageState}`}
                              className={cn(
                                "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                                "text-black text-[10.5px] font-medium ",
                                typePage === "tiers" &&
                                  pageView === "resiliations" &&
                                  pageState &&
                                  !pageSubView &&
                                  "bg-black/[0.04] opacity-100"
                              )}
                            >
                              Détails
                            </Link>
                          )}
                      </div>
                    </div>

                    <div className="flex justify-center w-full">
                      <div className="flex flex-none h-[2px] w-[80%] my-[4px] bg-black/[0.02] rounded-full"></div>
                    </div>

                    <Link
                      to={`/crm/${idTiers || ""}/parc-conc`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "parc-conc" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbTableOptions className="text-[14px] text-black" />
                      </span>{" "}
                      Parc Concurrents
                    </Link>

                    <div className="flex justify-center w-full">
                      <div className="flex flex-none h-[2px] w-[80%] my-[4px] bg-black/[0.02] rounded-full"></div>
                    </div>

                    <Link
                      to={`/crm/${idTiers || ""}/sites`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "sites" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbSitemap className="text-[14px] text-black" />
                      </span>{" "}
                      Tiers liés
                    </Link>

                    <Link
                      to={`/crm/${idTiers || ""}/web`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "web" &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbDeviceDesktop className="text-[14px] text-black" />
                      </span>{" "}
                      Web
                    </Link>

                    <div className="flex justify-center w-full">
                      <div className="flex flex-none h-[2px] w-[80%] my-[4px] bg-black/[0.02] rounded-full"></div>
                    </div>

                    <Link
                      to={`/crm/${idTiers || ""}/opportunites`}
                      className={cn(
                        "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                        "text-black text-[10.5px] font-medium ",
                        typePage === "tiers" &&
                          pageView === "opportunites" &&
                          ((!pageState && !pageSubState) || pageSubState) &&
                          "bg-black/[0.04] opacity-100"
                      )}
                    >
                      <span className="w-[20px]">
                        <TbChartArea className="text-[14px] text-black" />
                      </span>{" "}
                      Opportunités
                    </Link>

                    <div
                      className={cn(
                        "w-full gap-x-[10px] items-center flex relative",
                        "after:absolute after:w-[3px] after:rounded-full after:bg-black/[0.04] after:h-[calc(100%_-_5px)] after:left-[8px] after:top-1/2 after:-translate-y-1/2"
                      )}
                    >
                      <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                      <div className="flex w-full flex-col gap-y-[2px]">
                        {typePage === "tiers" &&
                          pageView === "opportunites" &&
                          pageState && (
                            <Link
                              to={`/crm/${
                                idTiers || ""
                              }/opportunites/${pageState}`}
                              className={cn(
                                "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                                "text-black text-[10.5px] font-medium ",
                                typePage === "tiers" &&
                                  pageView === "opportunites" &&
                                  pageState &&
                                  !pageSubView &&
                                  "bg-black/[0.04] opacity-100"
                              )}
                            >
                              Détails
                            </Link>
                          )}

                        {typePage === "tiers" &&
                          pageView === "opportunites" &&
                          pageSubView === "projet" && (
                            <div
                              className={cn(
                                "w-full gap-x-[10px] items-center flex relative",
                                "after:absolute after:w-[3px] after:rounded-full after:bg-black/[0.04] after:h-[calc(100%_-_5px)] after:left-[6px] after:top-1/2 after:-translate-y-1/2"
                              )}
                            >
                              <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                              <div className="flex w-full flex-col gap-y-[2px]">
                                <Link
                                  to={`/crm/${
                                    idTiers || ""
                                  }/opportunites/${pageState}`}
                                  className={cn(
                                    "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                                    "text-black text-[10.5px] font-medium ",
                                    typePage === "tiers" &&
                                      pageView === "opportunites" &&
                                      pageSubView === "projet" &&
                                      "bg-black/[0.04] opacity-100"
                                  )}
                                >
                                  Projet
                                </Link>
                              </div>
                            </div>
                          )}

                        {typePage === "tiers" &&
                          pageView === "opportunites" &&
                          pageSubView === "salesbook" && (
                            <div
                              className={cn(
                                "w-full gap-x-[10px] items-center flex relative",
                                "after:absolute after:w-[3px] after:rounded-full after:bg-black/[0.04] after:h-[calc(100%_-_5px)] after:left-[6px] after:top-1/2 after:-translate-y-1/2"
                              )}
                            >
                              <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                              <div className="flex w-full flex-col gap-y-[2px]">
                                <Link
                                  to={`/crm/${
                                    idTiers || ""
                                  }/opportunites/${pageState}/salesbook/${
                                    pageSubState || ""
                                  }`}
                                  className={cn(
                                    "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                                    "text-black text-[10.5px] font-medium ",
                                    typePage === "tiers" &&
                                      pageView === "opportunites" &&
                                      pageSubView === "salesbook" &&
                                      "bg-black/[0.04] opacity-100"
                                  )}
                                >
                                  SalesBook
                                </Link>
                              </div>
                            </div>
                          )}

                        {typePage === "tiers" &&
                          pageView === "opportunites" &&
                          pageSubView === "flv" && (
                            <div
                              className={cn(
                                "w-full gap-x-[10px] items-center flex relative",
                                "after:absolute after:w-[3px] after:rounded-full after:bg-black/[0.04] after:h-[calc(100%_-_5px)] after:left-[6px] after:top-1/2 after:-translate-y-1/2"
                              )}
                            >
                              <div className="w-[3px] h-full bg-black/[0.04] rounded-full ml-[5px]"></div>

                              <div className="flex w-full flex-col gap-y-[2px]">
                                <Link
                                  to={`/crm/${
                                    idTiers || ""
                                  }/opportunites/${pageState}/flv/${
                                    pageSubState || ""
                                  }`}
                                  className={cn(
                                    "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                                    "text-black text-[10.5px] font-medium ",
                                    typePage === "tiers" &&
                                      pageView === "opportunites" &&
                                      pageSubView === "flv" &&
                                      "bg-black/[0.04] opacity-100"
                                  )}
                                >
                                  FLV
                                </Link>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-1 h-full justify-end flex-col mt-[20px] gap-y-[5px]">
              <Link
                to={"/crm/calendrier"}
                className={cn(
                  "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                  "text-black text-[10.5px] font-medium ",
                  idTiers === "calendrier" && "bg-black/[0.04] opacity-100"
                )}
              >
                <span className="w-[20px]">
                  <TbCalendar className="text-[12px] text-black" />
                </span>{" "}
                Calendrier
              </Link>
              {user.niveau >= 7 && (
                <Link
                  to={"/crm/parametres"}
                  className={cn(
                    "w-full h-[25px] px-[12px] rounded-[8px] flex items-center transition-transform duration-150 relative gap-x-[10px] hover:bg-black/[0.02] active:bg-black/[0.04]",
                    "text-black text-[10.5px] font-medium ",
                    idTiers === "parametres" && "bg-black/[0.04] opacity-100"
                  )}
                >
                  <span className="w-[20px]">
                    <TbSettings2 className="text-[12px] text-black" />
                  </span>{" "}
                  Paramètres
                </Link>
              )}
            </div>
          </div>

          <div className="flex items-end py-[10px] max-sm:py-[20px] px-[10px] pointer-events-auto">
            <div className="relative z-50 w-full group atna-user-menu">
              <div
                onClick={() => {
                  setUserDropVisible((prev) => !prev);
                }}
                className="atna-user-drop h-[50px] w-full rounded-[18px] max-sm:rounded-[20px] atna-shadow bg-white active:bg-[rgba(0,0,0,0.0)]  px-[10px] py-[0px] max-sm:h-[60px] flex items-center gap-x-[15px] cursor-pointer"
              >
                <div className="size-[34px] rounded-full bg-black/[0.02] shadow-2xl overflow-hidden bg-gradient-to-br from-black/[0.02] to-black/[0.08]">
                  {hasUserPhoto ? (
                    <img src={userPhoto} alt="" className="w-full h-full" />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center font-sans font-medium text-black text-[10px]">
                      {letters}
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-y-0">
                  <h1 className="text-[11px] leading-[18px] font-medium tracking-wide max-w-[200px] text-nowrap whitespace-nowrap overflow-hidden text-ellipsis text-black">
                    {upperFirst(
                      (user.personnel || user)?.prenom.toLowerCase() || ""
                    )}{" "}
                    {upperFirst(
                      (user.personnel || user)?.nom.toLowerCase() || ""
                    )}
                  </h1>
                  <h2 className="text-[9px] font-semibold text-black/40 uppercase">
                    {user.personnel?.nomPoste || user.personnel?.poste || "..."}
                  </h2>
                </div>
              </div>

              <div
                className={cn(
                  "w-full h-max absolute z-[90] bottom-[calc(100%_+_10px)] left-0 bg-white atna-shadow border-black/[0.08] rounded-[12px] px-[6px] py-[6px] opacity-0 pointer-events-none transition-all duration-75",
                  userDropVisible && "opacity-100 pointer-events-auto"
                )}
              >
                <div className="flex flex-col gap-y-[2px] ">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={logoutUser}
                    className={cn(
                      "group h-[25px] max-h-[25px] grow-0 shrink-0 px-[10px] rounded-[8px] hover:bg-black/[0.02] active:bg-black/[0.04] flex w-full items-center justify-between relative"
                    )}
                  >
                    <div className="flex items-center gap-x-[8px] grow-0 shrink-0 px-[5px] ">
                      <h1 className="text-[11px] font-medium text-black">
                        Se déconnecter
                      </h1>
                      {logoutLoading && (
                        <span
                          className={cn(
                            "absolute right-[10px] top-1/2 -translate-y-1/2"
                          )}
                        >
                          <BiLoaderAlt className="animate-spin text-[10px] text-black/40" />
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (setViewKey) setViewKey(() => Date.now());
                    }}
                    className={cn(
                      "group h-[25px] max-h-[25px] grow-0 shrink-0 px-[10px] rounded-[8px] hover:bg-black/[0.02] active:bg-black/[0.04] flex w-full items-center justify-between relative"
                    )}
                  >
                    <div className="flex items-center gap-x-[8px] grow-0 shrink-0 px-[5px] ">
                      <h1 className="text-[11px] font-medium text-black">
                        Recharger
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
