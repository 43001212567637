import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///

// Stats des points d'activités des tiers
export const fetchStatisticsOpportunitiesWins = async (data = {}) => {
    try {
      const defaultData = {
        view: "month",
        typeView: "ic"
      };
    
      const requestData = { ...defaultData, ...data };


      const resApi = await axios(`${apiBaseUrl}/api/dashboard/opportunities/wins`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  export const fetchStatisticsOpportunitiesBenchmark = async (data = {}) => {
    try {
      const defaultData = {
        view: "week",
        typeView: "ic",
        categoryView: "ca"
      };
    
      const requestData = { ...defaultData, ...data };


      const resApi = await axios(`${apiBaseUrl}/api/dashboard/opportunities/benchmark`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };