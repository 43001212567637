// -- IMPORTATIONS DE BASE REACT ET ROUTER -- //
// Importation des hooks de React et des fonctionnalités de React Router pour la navigation et la gestion des URL
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

// -- HOOKS PERSONNALISÉS -- //
// Gestion des alertes globales
import { useAlerts } from '../../../../utils/hooks/useAlert';

// -- UTILITAIRES -- //
// Construction dynamique des classes CSS
import { cn } from '../../../../utils/cn';

// -- COMMUNICATION API -- //
// Utilitaires pour les interactions avec l'API et manipulation des utilisateurs
import { fetchUsers } from '../../../../utils/atna';

// Fonction "raccourcie" de fetchAllUsers
import User from './views/user/page';

// limiter la fréquence à laquelle une fonction est exécutée
import { debounce } from 'lodash';

// -- ICÔNES -- //
import { IoMdAdd } from "react-icons/io";

const AdminUsersView = () => {
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const { addAlert } = useAlerts();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get("q") || "");
    const { pageState } = useParams();
    const tableRef = useRef();
    const [filter, setFilter] = useState("Tous"); // État pour le filtre sélectionné
  
    const fetchAllUsers = useCallback(
      async (term) => {
        const resApi = await fetchUsers({ query: term });
        if (resApi.status === 200) {
          setAllUsers(resApi.data);
        } else {
          addAlert(resApi.data);
        }
      },
      [addAlert]
    );
  
    // Requête initiale au montage du composant, si searchTerm est vide
    useEffect(() => {
      if (!searchTerm) {
        fetchAllUsers("");
      }
    }, [fetchAllUsers, searchTerm]);
  
    // Déclenchement du debounce sur la recherche
    const debouncedFetch = useMemo(
      () =>
        debounce((term) => {
          fetchAllUsers(term);
        }, 200),
      [fetchAllUsers]
    );
  
    // Gère les changements dans la barre de recherche
    const handleSearchChange = (e) => {
      const value = e.target.value;
      setSearchParams({ q: value });
      setSearchTerm(value);
      debouncedFetch(value);
    };
  
    // Calculer le nombre d'utilisateurs par catégorie
    const activeUsersCount = allUsers.filter(user => user.actif === 1).length;
    const blockedUsersCount = allUsers.filter(user => user.actif === 0).length;
    const totalUsersCount = allUsers.length;
  
    // Filtrer les utilisateurs en fonction du filtre sélectionné
    const filteredUsers = useMemo(() => {
      if (filter === "Actifs") {
        return allUsers.filter(user => user.actif === 1);
      } else if (filter === "Bloqués") {
        return allUsers.filter(user => user.actif === 0);
      }
      return allUsers;
    }, [allUsers, filter]);
  
    return !pageState ? (
      <div className="w-full h-full flex flex-col items-center px-[50px] max-lg:px-[20px] max-sm:px-[10px]">
        <div className="atna-search-bar flex items-center w-[90%] max-w-[500px] rounded-[5px] bg-[rgba(0,0,0,0.5)] h-[26px] shrink-0 grow-0">
          <input
            defaultValue={searchParams.get("q") || ""}
            type="text"
            className="h-full w-full border-none outline-none bg-transparent px-[20px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide"
            placeholder="Rechercher par nom, prénom"
            onChange={handleSearchChange}
          />
        </div>
  
        <div className="flex mb-[0px] mt-[20px] w-full transition-all ease-in-out justify-between">
          <Link
            to="/admin/utilisateurs/nouveau"
            className={cn(
              "h-[28px] rounded-[10px] atna-border !bg-black text-wh font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer w-max"
            )}
          >
            <IoMdAdd className="mr-2 ml-[-6px]" />
            Nouvel utilisateur
          </Link>
  
          <div className="flex items-center space-x-4">
            <label className="flex items-center relative group">
              <input
                type="radio"
                name="filter"
                value="Tous"
                checked={filter === "Tous"}
                onChange={() => setFilter("Tous")}
                className="mr-1"
              />
              <span className="text-[12px] font-medium">Tous</span>
              <span
                className="pointer-events-none absolute top-full left-1/2 transform -translate-x-1/2 mt-1 
                bg-black text-white text-xs py-1 px-2 rounded opacity-0 
                group-hover:opacity-100 transition-opacity z-50 whitespace-nowrap"
              >
                {totalUsersCount} utilisateurs
              </span>
            </label>
            <label className="flex items-center relative group">
              <input
                type="radio"
                name="filter"
                value="Actifs"
                checked={filter === "Actifs"}
                onChange={() => setFilter("Actifs")}
                className="mr-1"
              />
              <span className="text-[12px] font-medium">Actifs</span>
              <span
                className="pointer-events-none absolute top-full left-1/2 transform -translate-x-1/2 mt-1 
                bg-black text-white text-xs py-1 px-2 rounded opacity-0 
                group-hover:opacity-100 transition-opacity z-50 whitespace-nowrap"
              >
                {activeUsersCount} utilisateurs
              </span>
            </label>
            <label className="flex items-center relative group">
              <input
                type="radio"
                name="filter"
                value="Bloqués"
                checked={filter === "Bloqués"}
                onChange={() => setFilter("Bloqués")}
                className="mr-1"
              />
              <span className="text-[12px] font-medium">Bloqués</span>
              <span
                className="pointer-events-none absolute top-full left-1/2 transform -translate-x-1/2 mt-1 
                bg-black text-white text-xs py-1 px-2 rounded opacity-0 
                group-hover:opacity-100 transition-opacity z-50 whitespace-nowrap"
              >
                {blockedUsersCount} utilisateurs
              </span>
            </label>
          </div>
        </div>
  
        <div className="flex w-full mt-[20px]">
          {filteredUsers.length > 0 ? (
            <div className="w-full h-full flex justify-center">
              <div
                className="atna-search-inner  !w-full !max-w-full"
                ref={tableRef}
              >
                <table className="atna-search-table">
                  <thead>
                    <tr className="atna-hd-table">
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>Tel. Perso</th>
                      <th>Tel. Pro</th>
                      <th>Mail</th>
                      <th>Id</th>
                      <th className="w-[20px] min-w-0 border-none"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((userMp, userMpInd) => (
                      <UserRowInTable
                        key={userMpInd}
                        userMp={userMp}
                        userMpInd={userMpInd}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p>Aucun utilisateur trouvé.</p>
            </div>
          )}
        </div>
      </div>
    ) : (
      <User fetchAllUsers={fetchAllUsers} />
    );
  };

const UserRowInTable = ({ userMp, userMpInd }) => {
  const navigate = useNavigate();

  return (
    <tr
      key={"tbA_" + userMpInd}
      className="cursor-pointer "
      onClick={() => navigate(`/admin/utilisateurs/${userMp.id}`)}
      role="button"
      tabIndex={0}
    >
      <th>{userMp.nom}</th>
      <th>{userMp.prenom}</th>
      <th>{userMp.telPerso}</th>
      <th>{userMp.telPro}</th>
      <th>{userMp.mailPro}</th>
      <th>{userMp.id}</th>
    </tr>
  );
};

export default AdminUsersView;