import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from '../../../../../../../components/inputs/input';
import moment from 'moment';
import 'moment/locale/fr'
import DateInput from '../../../../../../../components/inputs/inputDate';
import { cn } from '../../../../../../../utils/cn';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../../../../../utils/hooks/useAlert';
import { createTeam, createUser, editUser } from '../../../../../../../utils/atna';
import { useGlobalState } from '../../../../../../../utils/hooks/useGlobalState';
import InputSelect from '../../../../../../../components/inputs/inputSelect';
import InputBoolean from '../../../../../../../components/inputs/inputBoolean';
import TextArea from '../../../../../../../components/inputs/textArea';



// Constantes pour les classes réutilisées
const h1ClassName = `text-[11px] text-[rgba(55,53,47,0.5)] font-medium leading-[18px] tracking-wide`;
const fieldClassName = `flex flex-col min-w-[200px] w-full max-w-[250px]`;

const NewEquipe = ({ fetchAllTeams, fetchData }) => {

    const [isCreating, setIsCreating] = useState(false);
    const navigate = useNavigate()
    const { addAlert } = useAlerts()
    const [equipeData, setEquipeData] = useState({
        bareme: 80,
        active: 1,
    })
    const {listPersonnel} = useGlobalState()

    const personnelOptions = useMemo(() => listPersonnel.map(vl => ({
      value: vl.id, label: vl.nomComplet
  })), [listPersonnel]);



    const handleCreateData = useCallback(async () => {
        setIsCreating(true);
        const resEditData = await createTeam({ data: { ...equipeData } });
        // console.log(resEditData)
        setIsCreating(false);
        fetchAllTeams()
        if (resEditData.status === 200) {
            const newId = equipeData.id
            fetchData(newId)
            navigate('/admin/equipes/' + newId)
        }
        else return addAlert(resEditData.data);


    }, [equipeData]);






    return (
        <div className="flex flex-col w-full">



<div className="flex gap-x-[10px] w-full flex-wrap">
                  <div className={fieldClassName}>
                                <h1 className={h1ClassName}>Id</h1>
                                <Input object={equipeData} setObject={setEquipeData} value={"id"} dataTitle={null} placeholder={""}
                                    className={'w-full'}
                                    type="text"
                                    maxLength={10}
                                    required={true}
                                    validation={(str) => str.length > 10 ? { valid: false, data: `L'identifiant ne peut dépasser 10 caractères` } : { valid: true, data: null }} />
                            </div>
                            <div className={fieldClassName}>
                                <h1 className={h1ClassName}>Nom</h1>
                                <Input object={equipeData} setObject={setEquipeData} value={"nom"} dataTitle={null} placeholder={""}
                                    className={'w-full'}
                                    type="text"
                                    required={true}
                                    validation={(str) => str.length > 255 ? { valid: false, data: `Le nom de l'équipe ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                            </div>

                            <div className={fieldClassName}>
                                <h1 className={h1ClassName}>Manager</h1>
                                <InputSelect
                        object={equipeData}
                        inpClassName="atna-search-inp-listener"
                        setObject={setEquipeData}
                        value="idManager"
                        dataTitle={"Filtrer par commercial"}
                        placeholder={"Commercial"}
                        options={personnelOptions}
                    />

                            </div>
                        </div>


                        <div className="flex gap-x-[10px] w-full flex-wrap">
                  <div className={fieldClassName}>
                                <h1 className={h1ClassName}>Barême</h1>
                                <Input object={equipeData} setObject={setEquipeData} value={"bareme"} dataTitle={null} placeholder={""}
                                    className={'w-full'}
                                    type="number"
                                    maxLength={10}
                                    required={true}
                                    validation={(str) => parseInt(str) < 1 ? { valid: false, data: `Le barême minimum est de 1.` } : { valid: true, data: null }} />
                            </div>
                            <div className={fieldClassName}>
                                <h1 className={h1ClassName}>Niveau</h1>
                                <Input object={equipeData} setObject={setEquipeData} value={"niveau"} dataTitle={null} placeholder={""}
                                    className={'w-full'}
                                    type="number"
                                    maxLength={10}
                                    required={true}
                                    validation={(str) =>({ valid: true, data: null })} />
                            </div>
                        </div>

                        <div className="flex gap-x-[10px] w-full flex-wrap">
                          </div>



            <div className={cn("flex mt-[20px] gap-x-[20px]")}>

                <div className={cn("h-[28px] rounded-[10px] atna-border !bg-black text-wh font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer", isCreating && "opacity-75 pointer-events-none")} onClick={() => {
                    handleCreateData()
                }}>
                    {isCreating ? <BiLoaderAlt className='text-[13px] animate-spin' /> : 'Créer'}
                </div>



            </div>


        </div>
    );
}

export default NewEquipe;
