import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///

// Stats des points d'activités des tiers
export const fetchStatisticsActivitiesPoints = async (data = {}) => {
    try {
      const defaultData = {
        perso: true,
        teamId: "",
        id_ic: "",
        year: 2024,
        week: 1,
        view: "week",
        typeView: "last_15",
      };
    
      const requestData = { ...defaultData, ...data };


      const resApi = await axios(`${apiBaseUrl}/api/dashboard/activities/points`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


  export const fetchStatisticsActivitiesBenchmark = async (data = {}) => {
    try {
   
      const defaultData = {
        view: "week",
        typeView: "ic",
        categoryView: "points"
      };
    
      const requestData = { ...defaultData, ...data };


      const resApi = await axios(`${apiBaseUrl}/api/dashboard/activities/benchmark`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };







  export const fetchStatisticsActivitiesPointsDetails = async (data = {}, signal) => {
    try {
      const defaultData = {
        perso: true,
        teamId: "",
        id_ic: "",
        year: 2024,
        week: 1,
        view: "week",
        typeView: "last_15",
      };
  
      const requestData = { ...defaultData, ...data };
  
      const resApi = await axios(`${apiBaseUrl}/api/dashboard/activities/details`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: { ...requestData },
        signal, // Ajout du signal pour l'annulation
      })
  
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'API.",
        };
      }
      return resApi.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return { status: "canceled", data: "Requête annulée" };
      }
      return { status: 400, data: "Erreur lors de la communication vers l'API." };
    }
  };