import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// SalesBook -> Opportunites -> fiche tiers

// Créer un salesbook pour une opportunité
export const createTiersSalesbook = async ({ id, idOpportunite, data }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/salesbook/create`,
        withCredentials: true,
        data: data // Envoyer directement les données sans les imbriquer
      });
  
      return response.data;
    } catch (error) {
      console.error('[API ERROR] : Creating salesbook', error);
      console.error('Detailed error:', error.response?.data); // Ajout pour le débogage
      
      // Retourner le message d'erreur du serveur s'il existe
      if (error.response) {
        return {
          status: error.response.status,
          data: error.response.data.data || "Erreur lors de la création du salesbook."
        };
      }
      return {
        status: 500,
        data: "Erreur de communication avec le serveur."
      };
    }
  };

  // Récupérer le salesbook d'une opportunité
export const fetchTiersSalesBook = async ({ id, idOpportunite }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/salesbook`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching opportunities ${id} salesbook ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération du salesbook pour cette opportunité.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

  // Récupérer un salesbook par son idSalesBook
  export const fetchTiersOneSalesbook = async ({ id, idOpportunite, idSalesBook }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/salesbook/details/${idSalesBook}`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        if (error?.response?.status !== 404) {
          console.error(`[API ERROR] : Fetching opportunities ${id} salesbook ${error}`);
        }
        return {
          status: 400,
          data: "Erreur lors de la récupération du salesbook pour cet identifiant.",
        };
      });
  
      if (!resApi || resApi.status !== 200) {
        return { status: 400, data: "Erreur lors de la communication vers l'api." };
      }
  
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error);
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  // Modifier un salesbook
export const updateTiersSalesbook = async ({ id, idOpportunite, idSalesBook, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/salesbook/update/${idSalesBook}`,
      {
        method: "PUT",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Editing salesbook for opportunity ${id}/${idOpportunite} ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la modification du salesbook .",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};




// Supprimer un salesbook
export const deleteTiersSalesbook = async ({ id, idOpportunite, idSalesBook }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/salesbook/delete/${idSalesBook}`,
      {
        method: "DELETE",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Deleting project for opportunity ${id}/${idOpportunite} ${error}`);
      return { status: 400, data: "Erreur lors de la suppression du salesbook." };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};