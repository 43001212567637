import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font, pdf, BlobProvider } from '@react-pdf/renderer';
import moment from 'moment-timezone'
import { fetchSalesBookStatuts, formatNumberWithSpaces } from '../../../../../utils/atna';




const PdfWithAutoPagination = ({data}) => {

  Font.register({
    family: 'Century Gothic',
    fonts: [
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanRegular.ttf', fontWeight: 'normal' },
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanBold.ttf', fontWeight: 'bold' },
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanItalic.ttf', fontStyle: 'italic' },
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanBoldItalic.ttf', fontWeight: 'bold', fontStyle: 'italic' },
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanLight.ttf', fontWeight: '300' },
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanLightItalic.ttf', fontWeight: '300', fontStyle: 'italic' },
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanSemiBold.ttf', fontWeight: '600' },
      { src: '/fonts/century-gothic/CenturyGothicPaneuropeanSemiBoldItalic.ttf', fontWeight: '600', fontStyle: 'italic' },
    ],
  });

  const upperFirst = useCallback((text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }, [])
  const date = useMemo(() => (
    `${upperFirst(moment().format('ddd DD MMMM YYYY HH:mm'))}`
  ), [])
  


  // 📌 Styles avec `outline` pour éviter les coupures de bordures
  const styles = useMemo(() => (StyleSheet.create({
    page: { padding: 20, paddingBottom: 50, fontFamily: "Century Gothic", position: "relative" },
    title: { fontSize: 20, marginBottom: 10, textAlign: 'center' },
    table: { display: 'table', width: '100%' }, // 🟢 Ajout d'un contour général
    tableRow: { flexDirection: 'row' }, // 🟢 Ajout d'un contour sur chaque ligne
    tableCol: { width: '25%', padding: 5 }, // 🟢 Contour sur chaque cellule
    tableHeader: { fontSize: 12, fontWeight: 'bold', fontStyle: "italic" },
    tableCell: { fontSize: 8 },
    mainTitle: { fontSize: 16, fontWeight: 800, textAlign: "left" },
    subTitle: { fontSize: 12, fontWeight: 800, textAlign: "left" },
    breakPage: { height: 1, width: "100%" },
    dashedLineContainer: {
      flexDirection: 'row',
      alignSelf: 'center',
      marginVertical: 10,
    },
    dash: {
      width: 10, // Longueur du tiret
      height: 1, // Épaisseur du tiret
      backgroundColor: '#9add0f', // Couleur noire (000)
      marginRight: 5, // Espacement entre les tirets
    },
    footer: {
      position: 'absolute',
      bottom: 10,
      right: 20,
      textAlign: 'center',
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      fontSize: 7
    },
    buView: { display: "flex", justifyContent: "center", alignItems: "center", minHeight: 40, flexShrink: 0, marginVertical: 10, backgroundColor: "rgba(0,0,0,0.03)" },
    buViewTitle: { fontSize: 16, fontWeight: 800 },
    tableContent: { gap: "0px 2px" },
    bodyRow: {
      borderWidth: 0.5, paddingHorizontal: "2px", borderColor: "#b3b3b3", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end",
      height: 12
    },
    bodyCell: { fontSize: 8, marginTop: 1 },
    case: { width: 8, height: 8, borderWidth: 0.8, borderColor: "#000" },
    separator: { minHeight: 0.5, width: 140,  backgroundColor: "#000"},
    container: {display: "flex", flexDirection: "column"}
  })), []);


  const formattedData = useMemo(() => {

    const structures = ["reproIT", "dataIT", "identIT", "phoneIT"];
    const statuts = {
      aValiderADV: "validation_adv",
      aPlanifier: "a_planifier",
      livraisonAvenir: "livr_avenir",
      aFacturer: "a_facturer",
      dossierIncomplet: "dossier_incomplet",
      incoherents: "incoherent"
    };
    const dateToSorts = {
      aValiderADV: "dateContrat",
      aPlanifier: "dateADV",
      livraisonAvenir: "dateLivraison",
      aFacturer: "dateLivraison",
      dossierIncomplet: "dateLivraison",
      incoherents: "dateContrat"
    };
    
    const result = {};
    
    Object.entries(statuts).forEach(([key, statut]) => {
      result[key] = {};
    
      structures.forEach(structure => {
        const filteredData = data.filter(item => item.structure === structure && item.statut === statut);
    
        result[key][structure] = {
          data: filteredData.sort((a, b) => moment(b[dateToSorts[key]]).valueOf() - moment(a[dateToSorts[key]]).valueOf()),
          totals: {
            totalMargeCo: filteredData.reduce((sum, item) => sum + (item.margeCommerciale || 0), 0),
            totalMargeBrute: filteredData.reduce((sum, item) => sum + (item.margeBrute || 0), 0),
            totalCAReel: filteredData.reduce((sum, item) => sum + (item.CAReel || 0), 0),
            totalCACommercial: filteredData.reduce((sum, item) => sum + (item.CACommercial || 0), 0)
          }
        };
      });
    });
    
    return result;

  }, [data])


  return (
      <Document>
      
        <PagesByCategory 
        category={'Attente de validation ADV'}
        categoryId={'aValiderADV'}
        styles={styles} data={data} formattedData={formattedData} date={date} />

        <PagesByCategory 
        category={'Livraison à planifier'}
        categoryId={'aPlanifier'}
        styles={styles} data={data} formattedData={formattedData} date={date} />

        <PagesByCategory 
        category={'Attente de livraison'}
        categoryId={'livraisonAvenir'}
        styles={styles} data={data} formattedData={formattedData} date={date} />

        <PagesByCategory 
        category={'À facturer'}
        categoryId={'aFacturer'}
        styles={styles} data={data} formattedData={formattedData} date={date} />

      <PagesByCategory 
        category={'Incohérents'}
        categoryId={'incoherents'}
        styles={styles} data={data} formattedData={formattedData} date={date} />


      </Document>
  );
};



const PagesByCategory = ({styles, formattedData, date, category, categoryId}) => {
  return (
    <Page size="A4" style={styles.page}>

    <View
      style={{
        display: "flex", flexDirection: "row", gap: "0px 50px", alignItems: "center"
      }}>
      <Image
        style={{ height: "40px", objectFit: "contain" }}
        source={require('../../../../../cdn/images/it-and-you.jpg')} />
      <View style={{
        display: "flex", flexDirection: "column", gap: "0px 0px", alignItems: "center"
      }}>
        <Text style={styles.mainTitle}>Statut des Dossiers Commerciaux</Text>
        <View style={styles.dashedLineContainer}>
          {Array.from({ length: 20 }).map((_, i) => (
            <View key={i} style={styles.dash} />
          ))}
        </View>

      </View>
    </View>

    <View
      style={{
        display: "flex", marginVertical: "15px", flexDirection: "row", gap: "0px 50px", alignItems: "center"
      }}>
      <Text style={styles.subTitle}>{category}</Text>
    </View>

    {/* Table Header */}
    <View style={[styles.table, { flexWrap: 'wrap' }]}>
      <View style={[styles.tableRow, styles.tableHeader]}>
        <View style={{ ...styles.tableCol, width: 40 }}>
          <Text style={styles.tableCell}>ID</Text>
        </View>
        <View style={{ ...styles.tableCol, width: 60 }}>
          <Text style={styles.tableCell}>Date Contrat</Text>
        </View>
        <View style={{ ...styles.tableCol, width: 120 }}>
          <Text style={styles.tableCell}>Raison Sociale</Text>
        </View>
        <View style={{ ...styles.tableCol, width: 75, flexDirection: "column", gap: "10px 0px" }}>
          <Text style={{ ...styles.tableCell, color: "#253f78" }}>CA Commercial</Text>
          <Text style={{ ...styles.tableCell, color: "#4173e0" }}>Marge Co</Text>
        </View>
        <View style={{ ...styles.tableCol, width: 75, flexDirection: "column", gap: "10px 0px" }}>
          <Text style={{ ...styles.tableCell, color: "#bf3434" }}>CA Négoce</Text>
          <Text style={{ ...styles.tableCell, color: "#e89446" }}>Marge Brute</Text>
        </View>
        <View style={{ ...styles.tableCol, width: 58 }}>
          <Text style={styles.tableCell}>ADV</Text>
        </View>
        <View style={{ ...styles.tableCol, width: 58 }}>
          <Text style={styles.tableCell}>Livraison</Text>
        </View>
        <View style={{ ...styles.tableCol, width: 58 }}>
          <Text style={styles.tableCell}>Facture</Text>
        </View>
      </View>
      </View>


      {
        // A Valider REPRO
      }
      <View style={styles.container}>
      <View style={styles.buView} wrap={false}>
        <Text style={styles.buViewTitle}>Repro-IT</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "column", gap: "5px 0px" }}>
        {formattedData[categoryId]['reproIT'].data?.length > 0 ?
        formattedData[categoryId]['reproIT'].data.map((item) => (
          <View style={{ display: "flex", flexDirection: "column", gap: "2px 0px", alignItems: "center" }} wrap={false}>
            <View style={{ ...styles.tableRow, ...styles.tableContent }}>
              <View style={{ ...styles.bodyRow, width: 40 }}>
                <Text style={styles.bodyCell}>{item.idSalesBook}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 60 }}>
                <Text style={styles.bodyCell}>{moment(item.dateContrat).format('DD/MM/YYYY')}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 110, justifyContent: "flex-start" }}>
                <Text style={{ ...styles.bodyCell }}>{item.nomClient}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CACommercial||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CAReel||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
                <Text style={{ ...styles.bodyCell }}>{item.dateADV ? moment(item.dateADV).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateLivraison ? moment(item.dateLivraison).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateFacture ? moment(item.dateFacture).format('DD/MM/YYYY') : ""}</Text>
              </View>

              <View style={{ ...styles.case }}>
              </View>
            </View>
            <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26 }}>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeCommerciale||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeBrute||0).toFixed(2))} €</Text>
              </View>
            </View>
          </View>
        ))
        :
        <View style={{minHeight: 0}}></View>
       }
      </View>

      <View style={{ display: "flex", flexDirection: "column", marginTop: 8, gap: "2px 0px", alignItems: "center" }} wrap={false}>
         <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26, display: "flex", flexDirection: "column", gap: "2px 0px" }}>
             <View style={styles.separator}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#253f78" }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['reproIT'].totals.totalCACommercial.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#4173e0"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['reproIT'].totals.totalCAReel.toFixed(2))} €</Text>
              </View>
             </View>

              <View style={{...styles.separator, marginTop: "5px"}}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#bf3434"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['reproIT'].totals.totalMargeCo.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#e89446"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['reproIT'].totals.totalMargeBrute.toFixed(2))} €</Text>
              </View>
             </View>
      </View>
      </View>
      </View>


      {
        // A Valider DATA
      }
      <View style={styles.container}>
      <View style={styles.buView} wrap={false}>
        <Text style={styles.buViewTitle}>Data-IT</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "column", gap: "5px 0px" }}>
        {formattedData[categoryId]['dataIT'].data?.length > 0 ?
        formattedData[categoryId]['dataIT'].data.map((item) => (
          <View style={{ display: "flex", flexDirection: "column", gap: "2px 0px", alignItems: "center" }} wrap={false}>
            <View style={{ ...styles.tableRow, ...styles.tableContent }}>
              <View style={{ ...styles.bodyRow, width: 40 }}>
                <Text style={styles.bodyCell}>{item.idSalesBook}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 60 }}>
                <Text style={styles.bodyCell}>{moment(item.dateContrat).format('DD/MM/YYYY')}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 110, justifyContent: "flex-start" }}>
                <Text style={{ ...styles.bodyCell }}>{item.nomClient}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CACommercial||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CAReel||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
                <Text style={{ ...styles.bodyCell }}>{item.dateADV ? moment(item.dateADV).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateLivraison ? moment(item.dateLivraison).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateFacture ? moment(item.dateFacture).format('DD/MM/YYYY') : ""}</Text>
              </View>

              <View style={{ ...styles.case }}>
              </View>
            </View>
            <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26 }}>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeCommerciale||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeBrute||0).toFixed(2))} €</Text>
              </View>
            </View>
          </View>
        ))
        :
        <View style={{minHeight: 0}}></View>
       }
      </View>

      <View style={{ display: "flex", flexDirection: "column", marginTop: 8, gap: "2px 0px", alignItems: "center" }} wrap={false}>
         <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26, display: "flex", flexDirection: "column", gap: "2px 0px" }}>
             <View style={styles.separator}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#253f78" }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['dataIT'].totals.totalCACommercial.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#4173e0"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['dataIT'].totals.totalCAReel.toFixed(2))} €</Text>
              </View>
             </View>

              <View style={{...styles.separator, marginTop: "5px"}}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#bf3434"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['dataIT'].totals.totalMargeCo.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#e89446"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['dataIT'].totals.totalMargeBrute.toFixed(2))} €</Text>
              </View>
             </View>
      </View>
      </View>
      </View>



      {
        // A Valider IDENT
      }
      <View style={styles.container}>
      <View style={styles.buView} wrap={false}>
        <Text style={styles.buViewTitle}>Ident-IT</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "column", gap: "5px 0px" }}>
        {formattedData[categoryId]['identIT'].data?.length > 0 ?
        formattedData[categoryId]['identIT'].data.map((item) => (
          <View style={{ display: "flex", flexDirection: "column", gap: "2px 0px", alignItems: "center" }} wrap={false}>
            <View style={{ ...styles.tableRow, ...styles.tableContent }}>
              <View style={{ ...styles.bodyRow, width: 40 }}>
                <Text style={styles.bodyCell}>{item.idSalesBook}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 60 }}>
                <Text style={styles.bodyCell}>{moment(item.dateContrat).format('DD/MM/YYYY')}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 110, justifyContent: "flex-start" }}>
                <Text style={{ ...styles.bodyCell }}>{item.nomClient}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CACommercial||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CAReel||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
                <Text style={{ ...styles.bodyCell }}>{item.dateADV ? moment(item.dateADV).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateLivraison ? moment(item.dateLivraison).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateFacture ? moment(item.dateFacture).format('DD/MM/YYYY') : ""}</Text>
              </View>

              <View style={{ ...styles.case }}>
              </View>
            </View>
            <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26 }}>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeCommerciale||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeBrute||0).toFixed(2))} €</Text>
              </View>
            </View>
          </View>
        ))
        :
        <View style={{minHeight: 0}}></View>
       }
      </View>

      <View style={{ display: "flex", flexDirection: "column", marginTop: 8, gap: "2px 0px", alignItems: "center" }} wrap={false}>
         <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26, display: "flex", flexDirection: "column", gap: "2px 0px" }}>
             <View style={styles.separator}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#253f78" }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['identIT'].totals.totalCACommercial.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#4173e0"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['identIT'].totals.totalCAReel.toFixed(2))} €</Text>
              </View>
             </View>

              <View style={{...styles.separator, marginTop: "5px"}}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#bf3434"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['identIT'].totals.totalMargeCo.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#e89446"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['identIT'].totals.totalMargeBrute.toFixed(2))} €</Text>
              </View>
             </View>
      </View>
      </View>
      </View>


      {
        // A Valider PHONE
      }
      <View style={styles.container}>
      <View style={styles.buView} wrap={false}>
        <Text style={styles.buViewTitle}>Phone-IT</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "column", gap: "5px 0px" }}>
        {formattedData[categoryId]['phoneIT'].data?.length > 0 ?
        formattedData[categoryId]['phoneIT'].data.map((item) => (
          <View style={{ display: "flex", flexDirection: "column", gap: "2px 0px", alignItems: "center" }} wrap={false}>
            <View style={{ ...styles.tableRow, ...styles.tableContent }}>
              <View style={{ ...styles.bodyRow, width: 40 }}>
                <Text style={styles.bodyCell}>{item.idSalesBook}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 60 }}>
                <Text style={styles.bodyCell}>{moment(item.dateContrat).format('DD/MM/YYYY')}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 110, justifyContent: "flex-start" }}>
                <Text style={{ ...styles.bodyCell }}>{item.nomClient}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CACommercial||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
                <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.CAReel||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
                <Text style={{ ...styles.bodyCell }}>{item.dateADV ? moment(item.dateADV).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateLivraison ? moment(item.dateLivraison).format('DD/MM/YYYY') : ""}</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 58 }}>
              <Text style={{ ...styles.bodyCell }}>{item.dateFacture ? moment(item.dateFacture).format('DD/MM/YYYY') : ""}</Text>
              </View>

              <View style={{ ...styles.case }}>
              </View>
            </View>
            <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26 }}>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeCommerciale||0).toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70 }}>
              <Text style={{ ...styles.bodyCell }}>{formatNumberWithSpaces((item.margeBrute||0).toFixed(2))} €</Text>
              </View>
            </View>
          </View>
        ))
        :
        <View style={{minHeight: 0}}></View>
       }
      </View>

      <View style={{ display: "flex", flexDirection: "column", marginTop: 8, gap: "2px 0px", alignItems: "center" }} wrap={false}>
         <View style={{ ...styles.tableRow, ...styles.tableContent, marginLeft: 26, display: "flex", flexDirection: "column", gap: "2px 0px" }}>
             <View style={styles.separator}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#253f78" }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['phoneIT'].totals.totalCACommercial.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#4173e0"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['phoneIT'].totals.totalCAReel.toFixed(2))} €</Text>
              </View>
             </View>

              <View style={{...styles.separator, marginTop: "5px"}}>
             </View>
             <View style={{ ...styles.tableRow, ...styles.tableContent }}>
             <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#bf3434"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['phoneIT'].totals.totalMargeCo.toFixed(2))} €</Text>
              </View>
              <View style={{ ...styles.bodyRow, width: 70, border: 0, fontWeight: 500, color: "#e89446"  }}>
                <Text style={styles.bodyCell}>{formatNumberWithSpaces(formattedData[categoryId]['phoneIT'].totals.totalMargeBrute.toFixed(2))} €</Text>
              </View>
             </View>
      </View>
      </View>
      </View>


    <View style={styles.footer} fixed>
      <Text style={styles.footerText} fixed render={({ pageNumber, totalPages }) =>
       (<Text>Page <Text style={{fontWeight: 600}}>{pageNumber}</Text> sur <Text style={{fontWeight: 600}}>{totalPages}</Text></Text>)
      } />
      <Text style={styles.footerText}>
        {date}
      </Text>
    </View>

  </Page>
  )
}


const SalesStatutsPdfViewer = () => {
  const [data, setData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const pdfBlobUrl = useRef(null); // ✅ Stocker l'URL une seule fois

  useEffect(() => {
    const fetchData = async () => {
      const resApi = await fetchSalesBookStatuts();
      if (resApi.status === 200) {
        setData(resApi.data);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0 && !pdfBlobUrl.current) {
      // ✅ Générer le PDF UNE SEULE FOIS
      const generatePdf = async () => {
        const blob = await pdf(<PdfWithAutoPagination data={data} />).toBlob();
        pdfBlobUrl.current = URL.createObjectURL(blob);
        setPdfUrl(pdfBlobUrl.current);
      };
      generatePdf();
    }
  }, [data]); // ✅ Se déclenche uniquement une fois quand `data` est disponible

  return pdfUrl ? (
    <iframe 
    src={pdfUrl} width="100%" height="100%" title="Statuts Commerciaux"/>
  ) : (
    <p>Chargement du PDF...</p>
  );
};

export default SalesStatutsPdfViewer;