import React, { useEffect, useState, Suspense, useRef } from 'react';
import { cn } from '../../utils/cn';
import useScreenWidth from '../../utils/hooks/useScreenWidth';
import Sidebar from './components/sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import Topbar from './components/topbar';
import AdminMenuView from './views/menu/page';
import AdminUsersView from './views/users/page';
import { AlertsProvider } from '../../utils/hooks/useAlert';
import Alerts from '../crm/components/alerts';
import { useAuth } from '../../utils/hooks/useAuth';
import AdminTeamsView from './views/equipes/page';
import AdminOrganigrammeView from './views/organigramme/page';

const AdminView = () => {
  const [sidebarWidth, setSidebarWidth] = useState(248); // Initial width of the sidebar
  const [isResizing, setIsResizing] = useState(false);
  const [cursorTop, setCursorTop] = useState(0);
  const windowWidthSize = useScreenWidth();
  const [navLocked, setNavLocked] = useState(true);
  const { pageView } = useParams();
  const navigate = useNavigate()
  const {user} = useAuth()
  const topBarNameProps = useRef();

  const pageType = !pageView ? "menu" : pageView === "utilisateurs" ? "utilisateurs" : pageView === "equipes" ? "equipes" : "tiers";

  useEffect(() => {
    if (windowWidthSize > 800) setNavLocked(false);
  }, [windowWidthSize, setNavLocked]);


  useEffect(() => {
    if(!user.estAdmin) return navigate('/')
  }, [window.location.href])

  return (
    <div className='atna-crm-inner'>
      <div className="flex-[1_1_0%] flex relative flex-row h-full">
        <Sidebar 
          pageType={pageType} 
          sidebarWidth={sidebarWidth} 
          setSidebarWidth={setSidebarWidth} 
          isResizing={isResizing} 
          setIsResizing={setIsResizing} 
          cursorTop={cursorTop} 
          setCursorTop={setCursorTop} 
          windowWidthSize={windowWidthSize} 
          navLocked={navLocked} 
          setNavLocked={setNavLocked} 
        />
        <div 
          className={cn("flex flex-col flex-[1_1_0%] fixed h-full atna-main-content")} 
          style={{
            width: navLocked ? '100vw' : `calc(100vw - ${sidebarWidth}px)`,
            left: navLocked ? '0px' : `${sidebarWidth}px`,
          }}
        >

            <Topbar 
            topBarNameProps={topBarNameProps} 
            navLocked={navLocked} 
            setNavLocked={setNavLocked} 
            pageType={pageType} 
          />

          <main className="atna-view-inner w-full px-[20px] py-[10px]">
            <Suspense fallback={<></>}>
                <AlertsProvider>
                  <Alerts />
              {
                !pageView ?
                <AdminMenuView/>
                :
                pageView === "utilisateurs" ?
                <AdminUsersView/>
                :
                pageView === "equipes" ?
                <AdminTeamsView/>
                :
                pageView === "organigramme" ?
                <AdminOrganigrammeView/>
                :
                ""
              }
              </AlertsProvider>
            </Suspense>
          </main>
        </div>
      </div>
    </div>
  );
}

export default AdminView;
