import React, {useEffect, useState} from 'react';
import { editAdminImportsTables, fetchAdminImportsTables } from '../../../../utils/atna/admin/import';
import Input from "../../../../components/inputs/input"
import InputBoolean from "../../../../components/inputs/inputBoolean"
import { useAlerts } from '../../../../utils/hooks/useAlert';

const AdminViewImportsODBC = () => {

    const [tables, setTables] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchData = async() => {
        const resApi = await fetchAdminImportsTables()
        if(resApi.status === 200) {
            setTables(() => [...resApi.data])
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='w-full h-full flex flex-col items-center px-[20px] max-sm:px-[0px]'>

        <div className="flex items-center gap-x-[10px] w-full px-[0px] mt-[0px] py-[10px] rounded-[4px]">
            <div className="w-[4px] h-[25px] rounded-full bg-black/5"></div>
            <h1 className='max-sm:font-semibold font-semibold text-[16px] leading-[15px] text-black/90'>
                Tables d'imports
            </h1>
        </div>


        <table className="atna-search-table">
                  <thead>
                    <tr className="atna-hd-table">
                      <th>Nom sur Access</th>
                      <th>Nom Cloud</th>
                      <th>Base Client ?</th>
                      <th>Actif ?</th>
                      <th className="w-[20px] min-w-0 border-none"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        tables.map((table, tableIndex) => {
                            return <TableRow key={"tbA_" + tableIndex} tableData={table} tableIndex={tableIndex} fetchAllData={fetchData}/>
                        })
                    }

        </tbody>
        </table>



        </div>
    );
}

const TableRow = ({ tableData, tableIndex, fetchAllData }) => {

    const {addAlert} = useAlerts()
    const [lineData, setLineData] = useState(() => {
        return {...tableData}
    })



    useEffect(() => {
        const diff = JSON.stringify(lineData) !== JSON.stringify(tableData)
        const editData = async() => {
            const resApi = await editAdminImportsTables({id: tableData.id, data: lineData})
            if(resApi.status === 200) {
                fetchAllData()
            } else {
                addAlert('Impossible de sauvegarder les tables.')
            }
        }
        if(diff) {
           editData()
        }
    }, [lineData])

  return (
    <tr
      key={"tbA_" + tableData.id}
      className="cursor-pointer "
      role="button"
      tabIndex={0}
    >
      <th>
        <Input
            object={lineData}
            setObject={setLineData}
            value={"name"}
            validation={() => ({valid: true, err: false})}
            className={"bg-transparent w-full"}
        />
      </th>
      <th>
        <Input
            object={lineData}
            setObject={setLineData}
            value={"newName"}
            validation={() => ({valid: true, err: false})}
            className={"bg-transparent w-full"}
        /></th>
      <th>
         <InputBoolean
            object={lineData}
            setObject={setLineData}
            value={"client"}
         />
      </th>
      <th>

      <InputBoolean
            object={lineData}
            setObject={setLineData}
            value={"actif"}
         />
      </th>
    </tr>
  );
};

export default AdminViewImportsODBC;
