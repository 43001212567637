import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewUser from './views/newUser';
import { cn } from '../../../../../../utils/cn';
import moment from 'moment';
import 'moment/locale/fr'
import FicheUtilisateur from './views/fiche';
import { fetchUser } from '../../../../../../utils/atna';
import { useAlerts } from '../../../../../../utils/hooks/useAlert';
import { BiLoaderAlt } from 'react-icons/bi';
import FicheAtnaUser from './views/userAtna';



const User = ({fetchAllUsers}) => {

    const { pageState } = useParams()
    const [viewType, setViewType] = useState("perso")
    const [userData, setUserData] = useState(null)
    const [baseUserData, setBaseUserData] = useState({})
    const [loading, setLoading] = useState(true)
    const {addAlert} = useAlerts()


    const fetchData = async({id = pageState} = {}) => {
        if(id === "nouveau") return;
        const resApi = await fetchUser({id: id })
        setLoading(false)
        if(resApi.status === 200) {
            setBaseUserData(() => {
                return {...resApi.data}
            })
            setUserData(() => {
                return {...resApi.data}
            })
        } else {
            addAlert('Une erreur s\'est produite lors de la récupération de l\'utilisateur.')
        }
    }


    useEffect(() => {
        fetchData()
    }, [pageState])

    if (pageState === "nouveau") return (
        <div className='flex flex-1 w-full pt-[20px]'>
            <NewUser fetchAllUsers={fetchAllUsers} fetchData={fetchData} />
        </div>
    )
    else return (
        <div className='flex flex-1 w-full flex-col'>

<div className="flex items-center gap-x-[0px] border-b-[1px] border-b-black/5 mt-[30px]">

{
    [{
        label: "Fiche personnel",
        id: "perso",
        onClick: () => { },
    }, {
        label: "Accès",
        id: "user",
        onClick: () => { },
    }, 
    ].map(tp => {
        return (
            <div
                key={tp.id}
                onClick={() => {
                    setViewType(() => tp.id)
                }}
                className={cn("text-[12px] font-medium px-[20px] h-[30px]  flex items-center justify-center cursor-pointer",
                    viewType === tp.id ? "bg-black/5" : " hover:bg-black/[0.02]"
                )}>
                {tp.label}
            </div>
        )
    })
}

</div>




              <div className="flex flex-col pt-[20px]">


                {
                    loading ?
                    <div className="absolute w-full h-full z-[105] bg-white">
                    <div className="w-full mt-[20px] flex justify-center items-center h-2/3">
                        <BiLoaderAlt className='animate-spin text-[15px]' />
                    </div>
                    </div>
                    :
                    !userData.id ?
                    <div className="flex-[1_1_auto]">
                    <div className="w-full mt-[80px] flex justify-center items-center h-1/3">
                        <h1 className="text-[13px] px-[5px] text-[rgba(55,53,47,0.5)] font-medium">
                            Aucun utilisateur trouvé.
                        </h1>
                    </div>
                </div>
                    :
                    viewType === "perso" ?
                    <FicheUtilisateur userData={userData} setUserData={setUserData} baseUserData={baseUserData} setBaseUserData={setBaseUserData} fetchUserData={fetchData} fetchAllUsers={fetchAllUsers}/>
                    :
                    <FicheAtnaUser  userData={userData} setUserData={setUserData} baseUserData={baseUserData} setBaseUserData={setBaseUserData} fetchUserData={fetchData} fetchAllUsers={fetchAllUsers}/>
                }
                  
              </div>




        </div>
    )
}

export default User;
