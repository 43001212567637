import React, { createContext, useContext, useState } from "react";

// 1️⃣ Création du contexte pour gérer le blur
const BlurContext = createContext();

// 2️⃣ Hook personnalisé pour utiliser le blur partout
export const useBlur = () => useContext(BlurContext);

// 3️⃣ Composant `BlurProvider` qui englobe toute l'application
export const BlurProvider = ({ children }) => {
  const [isBlurred, setIsBlurred] = useState(false);

  return (
    <BlurContext.Provider value={{ isBlurred, setIsBlurred }}>
      <>
        {children} {/* Contenu de l'application */}
      </>
    </BlurContext.Provider>
  );
};

