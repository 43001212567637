import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;



export const fetchSalesBookStatuts = async (data = {}, signal) => {
    try {
      const defaultData = {};
    
      const requestData = { ...defaultData, ...data };


      const resApi = await axios(`${apiBaseUrl}/api/salesbook/statuts${data?.id ? `/${data.id}` : ''}`, {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
         ...requestData
        },
        signal
      })
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      if (axios.isCancel(error)) {
        return { status: "canceled", data: "Requête annulée" };
      }
      return { status: 400, data: "Erreur lors de la communication vers l'API." };
    }
  };

  export const fetchSalesBookSuiviDossiersValides = async (data = {}, signal) => {
    try {
      const requestData = { ...data };
  
      const resApi = await axios(`${apiBaseUrl}/api/salesbook/suivi/valides`, {
        method: "GET",
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
        params: requestData,
        signal,
      });
  
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
  
      // Pas besoin de "await" ici, resApi.data est déjà disponible
      const resData = resApi.data;
      return resData;
  
    } catch (error) {
      if (axios.isCancel(error)) {
        return { status: "canceled", data: "Requête annulée" };
      }
      return { status: 400, data: "Erreur lors de la communication vers l'API." };
    }
  };
  

  export const fetchStatsSalesbookPilotage = async (data = {}, signal) => {
    try {
      // Vérifier si dateDebut et dateFin sont fournies, sinon retourner une erreur
      if (!data.dateDebut || !data.dateFin) {
        return {
          status: 400,
          data: [],
          err: "Les dates dateDebut et dateFin sont obligatoires."
        };
      }
  
      const resApi = await axios.get(`${apiBaseUrl}/api/salesbook/stats_pilotage`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: data, // Envoi direct sans fusion avec un objet par défaut
        signal
      });
  
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: [],
          err: "Erreur lors de la communication avec l'API."
        };
      }
  
      return resApi.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return { status: "canceled", data: [], err: "Requête annulée" };
      }
      return { 
        status: 500, 
        data: [], 
        err: "Erreur lors de la communication avec l'API." 
      };
    }
  };
  

export const fetchSalesBookStatutsSuivis = async (data = {}, signal) => {
  try {
    const defaultData = {};
  
    const requestData = { ...defaultData, ...data };

    const resApi = await axios(`${apiBaseUrl}/api/salesbook/statuts/${data.id}/suivi`, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
       ...requestData
      },
      signal
    })
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { status: "canceled", data: "Requête annulée" };
    }
    return { status: 400, data: "Erreur lors de la communication vers l'API." };
  }
};




export const fetchSalesBookEtapes = async (data = {}, signal) => {
  try {
    const defaultData = {};
  
    const requestData = { ...defaultData, ...data };


    const resApi = await axios(`${apiBaseUrl}/api/salesbook/etapes`, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
       ...requestData
      },
      signal
    })
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { status: "canceled", data: "Requête annulée" };
    }
    return { status: 400, data: "Erreur lors de la communication vers l'API." };
  }
};






export const editSalesBookSuivi = async (data = {}, signal) => {
  try {
    const defaultData = {};
  
    const requestData = { ...defaultData, ...data };


    const resApi = await axios(`${apiBaseUrl}/api/salesbook/${data.idSalesBook}/suivi/edit`, {
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
       ...requestData
      },
      signal
    })
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
        err: resApi.data
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { status: "canceled", data: "Requête annulée" };
    }
    return { status: 400, data: "Erreur lors de la communication vers l'API." , error};
  }
};



export const editSalesBookSuiviEtapeProchaine = async (data = {}, signal) => {
  try {
    const defaultData = {};
  
    const requestData = { ...defaultData, ...data };


    const resApi = await axios(`${apiBaseUrl}/api/salesbook/${data.idSalesBook}/etapes/prochaine`, {
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
       ...requestData
      },
      signal
    })
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
        err: resApi.data
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { status: "canceled", data: "Requête annulée" };
    }
    return { status: 400, data: "Erreur lors de la communication vers l'API." , error};
  }
};

export const editSalesBookSuiviEtapeRetour = async (data = {}, signal) => {
  try {
    const defaultData = {};
  
    const requestData = { ...defaultData, ...data };


    const resApi = await axios(`${apiBaseUrl}/api/salesbook/${data.idSalesBook}/etapes/retour`, {
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
       ...requestData
      },
      signal
    })
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
        err: resApi.data
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { status: "canceled", data: "Requête annulée" };
    }
    return { status: 400, data: "Erreur lors de la communication vers l'API." , error};
  }
};



export const fetchSalesBookEtapesHistorique = async (data = {}, signal) => {
  try {
    const defaultData = {};
  
    const requestData = { ...defaultData, ...data };


    const resApi = await axios(`${apiBaseUrl}/api/salesbook/${data.idSalesBook}/etapes`, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
       ...requestData
      },
      signal
    })
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
        err: resApi.data
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { status: "canceled", data: "Requête annulée" };
    }
    return { status: 400, data: "Erreur lors de la communication vers l'API." , error};
  }
};