// import React, { useEffect, useState } from 'react';
// import { fetchUserPhoto } from '../../../../utils/atna';
// import { Background, Handle, Position, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow, useStoreApi } from '@xyflow/react';
// import { useAuth } from '../../../../utils/hooks/useAuth';
// import { useGlobalState } from '../../../../utils/hooks/useGlobalState';


// const UserCard = ({data}) => {
//     const [userPhoto, setUserPhoto] = useState("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
//     const getUserPhoto = async() => {
//         const userPhoto = await fetchUserPhoto({email: data.email, size: "48x48"})
//         console.log(userPhoto, data)
//         if(userPhoto.status === 200 && userPhoto.data) {
//           setUserPhoto(userPhoto.data)
//         }
//     }
  
  
//     useEffect(() => {
//       getUserPhoto()
//     }, [])
  
  
//       return (
//           <div className="min-w-[250px] px-[10px] h-[50px] gap-x-[15px] rounded-xl border-[1px] border-black/5 bg-white flex items-center">
//     <div className="size-[25px] rounded-full bg-black/[0.02] overflow-hidden">
//                     <img src={userPhoto} alt="" className="w-full h-full" />
//                 </div>
//               <div className="flex flex-col h-max gap-y-[2px]">
//                   <h1 className='text-[10px] font-bold max-sm:font-semibold text-black/80  leading-[1]'>
//                       {data.prenom} {data.nom}
//                   </h1>
//                   <h2 className='text-[9px] font-bold max-sm:font-semibold uppercase text-black/20 leading-[1]'>
//                   {data.nomPoste || data.poste || "NON DEFINI"}
//                   </h2>
//                   <h2 className='text-[9px] font-bold max-sm:font-semibold uppercase text-black/20 leading-[1]'>
//                   ( {data.niveau || 0} )
//                   </h2>
//               </div>
  
//               <Handle
//           type="target"
//           position={Position.Top}
//           className="!size-[7px] !rounded-full !bg-black/0"
//         />
//         <Handle
//           type="source"
//           position={Position.Bottom}
//           className="!size-[7px] !rounded-full !bg-black/0"
//         />
//           </div>
//       )
//   }
//   const nodeTypes = {
//       custom: UserCard,
//     };
  
    
  
//   const initialNodes = [];
  
//   const initialEdges = [];

// const AdminOrganigrammeView = () => {
//     const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
//     const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
//     const {listPersonnel} = useGlobalState()
   
  
//     const [teamMembers, setTeamMembers] = useState([])
  
//     const fetchData = async() => {
//           setTeamMembers(listPersonnel)
//       }
//     }
//     useEffect(() => {
//      fetchData()
//     }, [])
  
  
//     useEffect(() => {
  
//       const groupedByNiveau = teamMembers.reduce((acc, utilisateur) => {
//           // Trouver un sous-array qui correspond au niveau d'équipe de l'utilisateur
//           let group = acc.find(g => g[0].niveau === utilisateur.niveau);
          
//           if (group) {
//             // Ajouter l'utilisateur au groupe existant
//             group.push(utilisateur);
//           } else {
//             // Créer un nouveau groupe si aucun n'existe pour ce niveau
//             acc.push([utilisateur]);
//           }
        
//           return acc;
//         }, []);
  
  
//   // Calcul des coordonnées
//   const coordonnees = [];
//   let startY = 0;
//   const spacing = 280;
  
//   groupedByNiveau.forEach((groupe, index) => {
//     // Calcul du point de départ en x pour que le groupe soit centré
//     const startX = -(groupe.length - 1) * spacing / 2;
  
//     groupe.forEach((utilisateur, i) => {
//       const x = startX + i * spacing; // Position horizontale
//       const y = startY; // Position verticale (chaque groupe sur une nouvelle ligne)
      
//       coordonnees.push({
//         id: utilisateur.id,
//         nom: utilisateur.nom || "",
//         prenom: utilisateur.prenom || "",
//         user: {...utilisateur},
//         niveau: utilisateur.niveau,
//         email: utilisateur.email,
//         x: x,
//         y: y
//       });
//     });
    
//     // Espacement entre les lignes
//     startY += 100; // Ajuster la valeur selon l'espacement souhaité entre les niveaux
//   });
  
  
  
//   const resNodes = coordonnees.map((e, eInd) => {
//       return {id: `${eInd}`, type: "custom", position: {x: e.x, y: e.y}, data: {...e.user}}
//   })
  
//   setNodes(resNodes)
  
  
  
  
  
  
//   // Edges 
//   const edgeDefault = {type: 'default', style: {strokeWidth: 2,stroke: 'rgba(0,0,0,1)'} }
//   const allEdges = [];
//   teamMembers.forEach((user, userInd) => {
//       const group = groupedByNiveau.findIndex(grp => grp.find(e => e.id === user.id))
//       const nextGroup = groupedByNiveau[group+1]
  
//       if(!nextGroup) return;
      
//       nextGroup.forEach((nxtGrpUser, nxtGrpUserInd) => {
//           const nextIndex = teamMembers.findIndex(us => us.id === nxtGrpUser.id)
//           allEdges.push({
//               id: `edge-${userInd}`,
//               source: `${userInd}`,
//               target: `${nextIndex}`,
//               ...edgeDefault
//           })
//       })
//   })
  
  
//   setEdges(allEdges)
  
  
  
//     }, [teamMembers])
    
  
//     return (
//       <div className="w-full h-full flex flex-col items-center">
//         <div className="flex flex-1 w-full h-full mt-[20px] ">
//         <ReactFlowProvider>
//           <CenterFlow/>
//         <ReactFlow 
//     nodeTypes={nodeTypes}
//     nodes={nodes} 
//     edges={edges} 
//     onNodesChange={null} 
//     onEdgesChange={null} 
//     proOptions={{hideAttribution: true}}
//     defaultViewport={{ x: 50, y: 50, zoom: 1.3 }} // Centrage manuel
//   >
//             <Background />
//           </ReactFlow>
//           </ReactFlowProvider>
//         </div>
//       </div>
//     );

    
//   };


  
// const CenterFlow = () => {
//     const store = useStoreApi();
//     const { zoomIn, zoomOut, setCenter } = useReactFlow();
//    const {user} = useAuth()
  
//     const focusNode = () => {
//       const { nodeLookup } = store.getState();
//       const nodes = Array.from(nodeLookup).map(([, node]) => node);
  
//       if (nodes.length > 0) {
//         const userNodeIndex = nodes.findIndex(nd => nd.data.id === `${user.id}`)
//         const node = nodes[userNodeIndex];
  
//         const x = node.position.x + node.measured.width / 2;
//         const y = node.position.y + node.measured.height / 2;
//         const zoom = 1.3;
  
//         setCenter(x, y, { zoom, duration: 200 });
//       }
//     };

//     useEffect(() => {
//         focusNode()
//     })
  
// }

// export default AdminOrganigrammeView;


import React from 'react';

const Page = () => {
    return (
        <div>
            organigramme
        </div>
    );
}

export default Page;
