import React, { useEffect, useState } from 'react';
import { cn } from '../../utils/cn';

const InputReadOnly = ({value, dataTitle, inpClassName, className}) => {

    useEffect(() => {
        //console.log(value)
    }, [value])
    return (
        <div className={`relative group atna-select-options-container-${value} `} >
                            <div data-title={dataTitle}
                            className={cn("flex items-center w-[170px] rounded-[6px] bg-wh h-[26px] shrink-0 grow-0 relative atna-tooltip-bottom-right",  className
                            )}>
                            <div key={"rdInp_"+value}  
                            readOnly={true}
                            onChange={() => null}
                            autoComplete='off'
                            spellCheck="false"
                            type="text" className={cn('h-full w-full  whitespace-nowrap text-nowrap text-ellipsis overflow-hidden  select-none cursor-default border-none outline-none bg-transparent px-[10px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide', inpClassName)}
                            >{value}</div>
                
                            </div>
                        </div>
    );
}

export default InputReadOnly;
