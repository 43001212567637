import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Opportunites -> fiche tiers

// Récupérer la liste des oppportunités d'un tiers
export const fetchTiersOpportunites = async ({ id }) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/opportunites/${id}`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(
        `[API ERROR] : Fetching tiers ${id} opportunities ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des opportunités du tiers.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Récupérer la liste des oppportunités d'un user
export const fetchOpportunites = async ({
  perso = true,
  details = false,
  teamId = "",
  id_ic = "",
  date = "",
  evaluationCompare,
  evaluation,
  datePrevSignTypeCompare,
  datePrevSignature,
  structure,
  periode,
  prevSignatureYear,
  prevSignatureMonth,
  page = 1,
  limit = Number.MAX_SAFE_INTEGER,
} = {}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/opportunites`, {
      method: "GET",
      withCredentials: true,
      params: {
        perso,
        teamId,
        id_ic,
        date,
        details,
        evaluationCompare,
        evaluation,
        datePrevSignTypeCompare,
        datePrevSignature,
        structure,
        periode,
        prevSignatureYear,
        prevSignatureMonth,
        page,
        limit,
      },
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching opportunities ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération de vos opportunités.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

export const fetchTiersOpportuniteDetails = async ({ id, idOpportunite }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/${id}/details/${idOpportunite}`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching tiers ${id} opportunitie details ${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération de l'opportunité.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Modifier une opportunité
export const editTiersOpportunite = async ({
  id,
  idOpportunite,
  data = {},
}) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/${id}/edit/${idOpportunite}`,
      {
        method: "POST",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Editing opportunite for tiers ${id}/${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la modification de l'oportunité.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Supprimer une opportunité
export const deleteTiersOpportunite = async ({ id, idOpportunite }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/${id}/delete/${idOpportunite}`,
      {
        method: "DELETE",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Deleting opportunities for tiers ${id}/${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la suppression de l'opportunité.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Créer une opportunité "free" (sans vérification stricte pour id_ic, evaluation et explication)
export const createTiersOpportuniteFree = async ({ id, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/${id}/createfree`,
      {
        method: "POST",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Creating free opportunite for tiers ${id} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la création de l'opportunité.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'API.",
      };
    }
    const resData = resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'API." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///

// Récupérer la liste des status d'oportuniés ( 1 = en cours, 2 = gagnée , 3 = perdu etc..)
export const fetchTiersOpportunitesStatus = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/opportunites/status`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(
        `[API ERROR] : Fetching tiers opportunities status ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des status d'opportunités.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Récupéraiton des etats possible de l'opportunité -> qualif, eval, proposition, nego, cloture etc

export const fetchTiersOpportunitesEtats = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/opportunites/etats`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(
        `[API ERROR] : Fetching tiers opportunities states ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des états d'opportunités.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// récuperer histoire des etats de lopportnite
export const fetchTiersOpportuniteEtats = async ({ id, idOpportunite }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/etats/${id}/${idOpportunite}`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching tiers ${id} opportunitie states ${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des états",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Modifier l'étatt - ajouter histoire des etats de lopportnite
export const editTiersOpportuniteEtat = async ({ id, idOpportunite, etat }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/etats/${id}/${idOpportunite}/edit`,
      {
        method: "POST",
        withCredentials: true,
        data: { etat },
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Editing tiers ${id} opportunitie states ${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la modification des états",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Observations -> Opportunites -> fiche tiers

// Récupérer une observation par son idOpportunite
export const fetchOpportunitesObservations = async ({ id, idOpportunite }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/observations/`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching opportunities ${id} observation ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération des observations pour cet opportunité.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Récupérer une observation par son idObservation
export const fetchOpportunitesOneObservation = async ({
  id,
  idOpportunite,
  idObservation,
}) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/observations/details/${idObservation}`,
      {
        method: "GET",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching opportunities ${id} observation ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération de l'observation pour cet identifiant.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Créer une observation pour une opportunité
export const createOpportunitesObservations = async ({
  id,
  idOpportunite,
  data,
}) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/observations/create`,
      {
        method: "POST",
        withCredentials: true,
        data: { ...data },
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Creating opportunitie ${id} observation ${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la création du observations.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Modifier une Observation
export const editOpportunitesObservations = async ({
  id,
  idOpportunite,
  idObservation,
  data = {},
}) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/observations/update/${idObservation}`,
      {
        method: "PUT",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Editing observation for opportunitie ${id}/${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la modification du observations .",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Supprimer une Observation
export const deleteOpportunitesObservations = async ({
  id,
  idOpportunite,
  idObservation,
}) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/observations/delete/${idObservation}`,
      {
        method: "DELETE",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(
        `[API ERROR] : Deleting observation for opportunitie ${id}/${idOpportunite} ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la suppression de l'observations.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};
