import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAlerts } from '../../../../utils/hooks/useAlert';
import { debounce } from 'lodash';
import { fetchTeams } from '../../../../utils/atna';
import { cn } from '../../../../utils/cn';
import { useGlobalState } from '../../../../utils/hooks/useGlobalState';
import AdminTeamView from './views/equipe/page';

const AdminTeamsView = () => {

    const [allUsers, setAllUsers] = useState([]);
    const { addAlert } = useAlerts();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get('q') || "");
    const {pageState} = useParams();
    const tableRef = useRef();
    const {listPersonnel} = useGlobalState()
  


    const fetchAllTeams = useCallback(async (term) => {
        const resApi = await fetchTeams({ query: term });
        if (resApi.status === 200) {
            setAllUsers(resApi.data);
        } else {
            addAlert(resApi.data);
        }
    }, [addAlert]);

    // Requête initiale au montage du composant, si searchTerm est vide
    useEffect(() => {
        if (!searchTerm) {
            fetchAllTeams("");
        }
    }, [fetchAllTeams, searchTerm]);

    // Déclenchement du debounce sur la recherche
    const debouncedFetch = useMemo(() => debounce((term) => {
        fetchAllTeams(term);
    }, 200), [fetchAllTeams]);

    useEffect(() => {
        if(searchParams.get('q') !== searchTerm) {
            setSearchTerm(searchParams.get('q'))
        } 
        if(!searchParams.get('q')) {
            fetchAllTeams('')
        } 
    }, [])

    // Gère les changements dans la barre de recherche
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchParams({ q: value });
        setSearchTerm(value);
        debouncedFetch(value);
    };



    return (
        !pageState ?
        <div className='w-full h-full flex flex-col items-center px-[50px] max-lg:px-[20px] max-sm:px-[10px]'>
            
            <div className="atna-search-bar flex items-center w-[90%] max-w-[500px] rounded-[5px] bg-[rgba(0,0,0,0.5)] h-[26px] shrink-0 grow-0">
                <input
                    defaultValue={searchParams.get('q') || ""}
                    type="text"
                    className='h-full w-full border-none outline-none bg-transparent px-[20px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide'
                    placeholder='Rechercher par nom'
                    onChange={handleSearchChange}
                />
            </div>

            <div className={`flex mb-[0px] mt-[20px] w-full transition-all ease-in-out `} style={{
                //maxWidth:  `${tableRef.current?.offsetWidth}px`
            }}>
                <Link to="/admin/equipes/nouveau" 
                    className={cn("h-[28px] rounded-[10px] atna-border !bg-black text-wh font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer w-max")}
                >
                    Nouvelle équipe
                </Link>
            </div>

            <div className="flex w-full mt-[20px]">
                {allUsers.length > 0 ? (
                    <div className='w-full h-full flex justify-center'>
                        <div className="atna-search-inner  !w-full !max-w-full" ref={tableRef}>
                            <table className='atna-search-table'>
                                <thead>
                                    <tr className='atna-hd-table'>
                                        <th>Nom</th>
                                        <th>Manager</th>
                                        <th>Id</th>
                                        <th className='w-[20px] min-w-0 border-none'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUsers.slice(0, 20).map((teamMp, teamMpInd) => (
                                        <EquipeRowInTable
                                        listPersonnel={listPersonnel} 
                                            key={teamMpInd}
                                            teamMp={teamMp}
                                            teamMpInd={teamMpInd}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div className='w-full flex justify-center text-[13px] text-[rgb(133,132,128)] font-normal mt-[50px]'>
                        Aucun résultat.
                    </div>
                )}
            </div>

       
        </div>
        :
        <AdminTeamView fetchAllTeams={fetchAllTeams}setSearchTerm={setSearchTerm}/>
    );
}

const EquipeRowInTable = ({ teamMp, teamMpInd, listPersonnel }) => {
    const navigate = useNavigate();


    return (
        <tr key={"tbA_" + teamMpInd} className='cursor-pointer '
            onClick={() => navigate(`/admin/equipes/${teamMp.id}`)}
            role='button' tabIndex={0}>
            <th>{teamMp.nom}</th>
            <th>{listPersonnel ? (listPersonnel?.find(e => e.id === teamMp.idManager)?.nomComplet || teamMp.idManager) : teamMp.idManager}</th>
            <th>{teamMp.id}</th>
        </tr>
    );
}

export default AdminTeamsView;
