// -----------------------------
// Bibliothèques React & externes
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

// -----------------------------
// Icônes tierces
import { BiLoaderAlt, BiSupport } from "react-icons/bi";

// -----------------------------
// Hooks personnalisés
import { useAuth } from "./utils/hooks/useAuth";
import { useSocket } from "./utils/hooks/useSocket";
import { TabsProvider } from "./utils/hooks/useTabs";
import { AlertsProvider } from "./utils/hooks/useAlert";
import { GlobalStateProvider } from "./utils/hooks/useGlobalState";
import { RefreshProvider } from "./views/crm/views/tiers/views/opportunites/views/projets/utils/refreshContext";
import { SidebarProvider } from "./utils/hooks/useSideBar";
import { BlurProvider } from "./utils/hooks/useBlurBackground";

// -----------------------------
// Pages et composants
import CrmView from "./views/crm/page";
import AdminView from "./views/admin/page";
import Home from "./views/home/page";
import Login from "./views/auth/login/page";
import PdfSalesStatuts from "./views/crm/views/pdfs/sales/statuts";

// -----------------------------
// Utilitaires et fonctions d'aide
import { fetchCurrentUser } from "./utils/atna";

// -----------------------------
// Composant principal de l'application
const App = () => {
  const { user, setUser, loading, fetchUser } = useAuth();
  const { clientId, socket } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on("forceLogout", () => {
        fetchUser();
      });
      return () => {
        socket.off("forceLogout");
      };
    }
  }, [socket]);

  return (
    <div className="atna-app-inner">
      <SidebarProvider>
        <GlobalStateProvider>
          <TabsProvider>
            <RefreshProvider>
              <BlurProvider>
                <BrowserRouter
                  future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                  }}
                >
                  <CheckUserAuth fetchUser={fetchUser} />
                  {loading ? (
                    <div className="w-full h-full fixed z-[150] flex justify-center items-center bg-white">
                      <BiLoaderAlt className="text-[20px] animate-spin" />
                    </div>
                  ) : user.isAuth ? (
                    <>
                      <a
                        className="fixed bottom-[100px] right-[20px] size-[40px] rounded-full bg-[#9cdb2f]
                        flex items-center justify-center text-[20px] z-[99]"
                        href="https://feedback.userreport.com/883b9f88-9dcc-444b-99fa-915d786d932c/#submit/bug"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BiSupport />
                      </a>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                          path="/crm/:idTiers?/:pageView?/:pageState?/:pageSubView?/:pageSubState?"
                          element={<CrmView />}
                        />
                        <Route
                          path="/admin/:pageView?/:pageState?"
                          element={<AdminView />}
                        />
                        <Route path="/clientId" element={<>{clientId}</>} />
                        <Route path="/pdf" element={<PdfSalesStatuts />} />
                      </Routes>
                    </>
                  ) : (
                    <Login
                      user={user}
                      setUser={setUser}
                      fetchUser={fetchUser}
                    />
                  )}
                </BrowserRouter>
              </BlurProvider>
            </RefreshProvider>
          </TabsProvider>
        </GlobalStateProvider>
      </SidebarProvider>
    </div>
  );
};

// -----------------------------
// Vérification d'authentification
const CheckUserAuth = ({ fetchUser }) => {
  const location = useLocation();

  useEffect(() => {
    fetchUser();
  }, [location.pathname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchUser();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export default App;
