import React, { useEffect, useState } from 'react';
import { cn } from '../../utils/cn';
import { FaCheck } from "react-icons/fa6";

const InputBoolean = ({ object, setObject, value, dataTitle, inpClassName, className, inpActiveClassName="", checkIconClassName="" }) => {
    const [inpValue, setInpValue] = useState(object[value] ? 1 : 0);

    useEffect(() => {
        if (object[value] !== inpValue) {
            setInpValue(object[value] ? 1 : 0);
        }
    }, [object[value]]);

    const handleClick = () => {
        const newValue = inpValue === 0 ? 1 : 0;
        setInpValue(newValue);
        setObject(prev => {
            prev[value] = newValue
            return {
            ...prev
            }
        });
    };


    return (
        <div className="relative group">
            <div
                data-title={dataTitle}
                className={cn(
                    "flex items-center justify-center rounded-[6px] h-[26px] w-[26px] shrink-0 grow-0 relative cursor-pointer",
                    className
                )}
                onClick={handleClick}
            >
                <div
                    className={cn(
                        "w-[22px] h-[22px] rounded-[8px] flex items-center justify-center bg-wh",
                        inpClassName,
                        inpValue && "bg-black",
                        inpValue && inpActiveClassName
                    )}
                >
                    {inpValue ? <FaCheck className={cn("text-wh text-[11px]", checkIconClassName)} /> : null}
                </div>
            </div>
        </div>
    );
};

export default InputBoolean;
